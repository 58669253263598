import React from "react";

import {
  Box,
  Flex,
  Heading,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
} from "@chakra-ui/react";
import _ from "lodash";

import JSONDisplay from "../JSONDisplay";
import LineItem from "./LineItem";

function QuoteInvoice({ details }) {
  return (
    <Box
      bg="gray.100"
      borderWidth="1px"
      borderRadius="lg"
      color="gray.800"
      my={4}
      p={6}
    >
      <Heading as="h4" mb={4} size="md">
        {details.title ? details.title : "Invoice"}
      </Heading>
      <Tabs isFitted variant="enclosed-colored">
        <TabList>
          <Tab>Details</Tab>
          <Tab>JSON</Tab>
        </TabList>
        <TabPanels>
          <TabPanel bg="white">
            {!_.isEmpty(details.displayPrice) ? (
              <Flex align="flex-start" justify="space-between" mb={8}>
                <Text>Display Price</Text>
                <Text>
                  {details.displayPrice ?
                    details.displayPrice.amount.toLocaleString("en-au", {
                      style: "currency",
                      currency: details.displayPrice.currency,
                      currencyDisplay: "code"
                    }) : null}
                </Text>
              </Flex>
            ) : null}
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Title</Th>
                  <Th isNumeric>Qty</Th>
                  <Th isNumeric>netPerUnit</Th>
                  <Th isNumeric>netTotal</Th>
                  <Th isNumeric>commissionTotal</Th>
                  <Th isNumeric>grossPerUnit</Th>
                  <Th isNumeric>grossTotal</Th>
                </Tr>
              </Thead>
              <Tbody>
                {details.lineItems.map((item, itemIndex) => (
                  <LineItem key={itemIndex} item={item} />
                ))}
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th>Totals</Th>
                  <Th></Th>
                  <Th></Th>
                  <Th isNumeric>
                    {details.netTotal ?
                      details.netTotal.amount.toLocaleString("en-au", {
                        style: "currency",
                        currency: details.netTotal.currency,
                        currencyDisplay: "code"
                      }) : null}
                  </Th>
                  <Th></Th>
                  <Th></Th>
                  <Th isNumeric>
                    {details.grossTotal ?
                      details.grossTotal.amount.toLocaleString("en-au", {
                        style: "currency",
                        currency: details.grossTotal.currency,
                        currencyDisplay: "code"
                      }) : null}
                  </Th>
                </Tr>
              </Tfoot>
            </Table>
            {details.localFees ? (
              <Box p={6}>
                <Text>Local Fees</Text>
                <Text>{details.localFees}</Text>
              </Box>
            ) : null}
          </TabPanel>
          <TabPanel>
            <JSONDisplay jsonToDisplay={details} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default QuoteInvoice;
