import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { InfoOutlineIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Tooltip,
} from "@chakra-ui/react";
import copy from "copy-to-clipboard";
import currencies from "currency-codes";
import _ from "lodash";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";

import { postCheckoutFlow } from "../checkoutFlowSlice";

import "react-datepicker/dist/react-datepicker.css";
import "../date-picker.css";

function PostFlow({ handleClearFaresAddOns, loading, handleChangeUIForm }) {
  let { departureDate, productId } = useParams<any>();

  const [form, setForm] = useState({
    date: departureDate ? new Date(departureDate) : new Date(),
    productId: productId ? productId : 1,
    clientReference: "",
    currency: "",
  });

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setForm({
      ...form,
      [name]: value,
    });
  };

  useEffect(() => {
    handleChangeUIForm(form);
  }, [form, handleChangeUIForm]);

  const dispatch = useDispatch();
  const prepareFlowForm = () => {
    return {
      productId: form.productId ? +form.productId : undefined,
      currency: form.currency === "" ? undefined : form.currency,
      clientReference:
        form.clientReference === "" ? undefined : form.clientReference,
      date: form.date
        ? DateTime.fromJSDate(form.date).toFormat("yyyy-MM-dd")
        : undefined,
    };
  };
  const handlePostCheckoutFlow = () => {
    handleClearFaresAddOns();
    dispatch(postCheckoutFlow(prepareFlowForm()));
  };
  const handleCopyPostFlowJSON = () =>
    copy(JSON.stringify(prepareFlowForm(), null, 2));

  return (
    <Grid alignItems="top" gap={4} mb={8} templateColumns="repeat(6, 1fr)">
      <GridItem colSpan={3}>
        <FormControl id="date" isRequired={true}>
          <FormLabel>
            date
            <Tooltip label="Date for the booking. This is one of the initial inputs needed to kick-off the booking flow.">
              <InfoOutlineIcon color="gray.800" ml={2} />
            </Tooltip>
          </FormLabel>
          <DatePicker
            dateFormat="yyyy-MM-dd"
            isClearable
            onChange={(date) =>
              handleInputChange({
                target: { name: "date", type: "date", value: date },
              })
            }
            selected={form.date}
            minDate={new Date()}
          />
        </FormControl>
      </GridItem>
      <GridItem colSpan={3}>
        <FormControl id="productId" isRequired={true}>
          <FormLabel>
            productId
            <Tooltip label="ID of the product to be purchased in the flow. This is one of the initial inputs needed to kick-off the booking flow.">
              <InfoOutlineIcon color="gray.800" ml={2} />
            </Tooltip>
          </FormLabel>
          <Input
            name="productId"
            onChange={handleInputChange}
            value={form.productId}
            w="100%"
          />
        </FormControl>
      </GridItem>
      <GridItem colSpan={3}>
        <FormControl id="clientReference">
          <FormLabel>
            clientReference
            <Tooltip label="An optional external reference used for this flow and resulting booking supplied by the API client. For Direct Connect partners we will attempt to pass this reference on to the upstream reservation system wherever possible. For resellers booking through an intermediary wholesale Distributor this reference will not appear on the upstream, in favour of the livnReference.">
              <InfoOutlineIcon color="gray.800" ml={2} />
            </Tooltip>
          </FormLabel>
          <Input
            name="clientReference"
            onChange={handleInputChange}
            value={form.clientReference}
            w="100%"
          />
        </FormControl>
      </GridItem>
      <GridItem colSpan={3}>
        <FormControl id="currency">
          <FormLabel>
            currency
            <Tooltip label="For products that can be booked in more than one currency, clients can specify the desired currency when initiating a flow. If not specified the product's primary currency will be used by default.">
              <InfoOutlineIcon color="gray.800" ml={2} />
            </Tooltip>
          </FormLabel>
          {!_.isEmpty(currencies.data) ? (
            <Select
              name="currency"
              onChange={handleInputChange}
              placeholder="Select currency"
              value={form.currency}
              w="100%"
            >
              {currencies.data.map((currency) => (
                <option key={currency.code} value={currency.code}>
                  {currency.code} {currency.currency}
                </option>
              ))}
            </Select>
          ) : null}
        </FormControl>
      </GridItem>
      <GridItem colSpan={3}>
        <Button
          mt={4}
          onClick={() => handleCopyPostFlowJSON()}
          variant="outline"
          w="100%"
        >
          Copy request payload
        </Button>
      </GridItem>
      <GridItem colSpan={3}>
        <Button
          leftIcon={<TriangleUpIcon />}
          colorScheme="green"
          isLoading={loading === "pending"}
          loadingText="Posting"
          mt={4}
          onClick={() => handlePostCheckoutFlow()}
          w="100%"
        >
          Post Flow
        </Button>
      </GridItem>
    </Grid>
  );
}

export default PostFlow;
