

import { SimpleGrid } from "@chakra-ui/react";
import { motion } from "framer-motion";

import SupplierForm from "./Details/SupplierForm";

function Supplier() {

  return (
    <motion.div initial="exit" animate="enter" exit="exit" w="100%">
      <SimpleGrid gap={12} minW={{ xl: "1200px" }} w="100%">
        <SupplierForm />
      </SimpleGrid>
    </motion.div>
  );
}

export default Supplier;
