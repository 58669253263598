import React, { useState, useEffect, memo } from "react";
import { useDispatch } from "react-redux";

import { CopyIcon } from "@chakra-ui/icons";
import { Box, Button, SimpleGrid, Text, Textarea } from "@chakra-ui/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { DateTime } from "luxon";
import jsonFormat from "format-json";
import { postProductSearch } from "../../productSlice";

function SearchJSON({ form, loading }) {

  const [formJSON, setFormJSON] = useState(jsonFormat.plain(form));

  const handleInputChange = (event) => {
    setFormJSON(event.target.value);
  };

  //use the forms data to determine JSON
  useEffect(() => {
    //get fields with values
    let temp = {}
    for (const key in form) {
      if (form[key] !== "" && form[key] !== null) {
        if ((key === "operatesFrom" || key === "operatesTo") && (form[key] !== undefined)) {
          temp[key] = DateTime.fromJSDate(form[key]).toFormat("yyyy-MM-dd");
        } else {
          temp[key] = form[key];
        }
      }
    }
    temp.paging = {
      resultsPerPage: parseInt(form.resultsPerPage, 10) || 100,
      currentPage: parseInt(form.currentPage, 10) || 1
    };
    temp.resultsPerPage = undefined;
    temp.currentPage = undefined;
    temp.supplierIds = form.supplierIds
      ? form.supplierIds.replaceAll(" ", "").split(",")
      : undefined;
    temp.productIds = form.productIds
      ? form.productIds.replaceAll(" ", "").split(",")
      : undefined;
    temp.categories = form.categories
      ? form.categories.replaceAll(" ", "").split(",")
      : undefined;
    setFormJSON(jsonFormat.plain(temp));
  }, [form]);

  const dispatch = useDispatch();
  const handlePostProductSearch = () => {
    try {
      JSON.parse(formJSON);
    } catch {
      alert("Malformed JSON. Please check the input and try again");
      return;
    }

    dispatch(postProductSearch(JSON.parse(formJSON)));
  };

  return (
    <Box>
      <Text mb={4}>
        If you'd rather not fill out the form and use JSON instead, you may
        enter it here.
      </Text>
      <Textarea
        h="405px"
        name="JSON"
        onChange={handleInputChange}
        resize="vertical"
        w="100%"
        value={formJSON}
      />
      <SimpleGrid columns={2} spacing={2}>
        <CopyToClipboard text={formJSON}>
          <Button mt={4} variant="outline" w="100%">
            <CopyIcon color="gray" />
          </Button>
        </CopyToClipboard>
        <Button
          colorScheme="green"
          isLoading={loading === "pending"}
          loadingText="Posting"
          mt={4}
          onClick={() => handlePostProductSearch()}
          w="100%"
        >
          Post
        </Button>
      </SimpleGrid>
    </Box>
  );
}

export default memo(SearchJSON);
