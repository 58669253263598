import { Button, Text } from "@chakra-ui/react";
import { DateTime } from "luxon";

export const KibanaLink = (props) => {
  const { processId, timestamp } = props;
  let date = DateTime.fromISO(timestamp).setLocale("en-au")
  const from = date.minus({ minutes: 5 }).toUTC().toISO();
  const to = date.plus({ minutes: 5 }).toUTC().toISO();

  const KibanaLink = () => {
    const kibanaUrl = `https://kibanav2.livnapi.com/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'${from}',to:'${to}'))&_a=(columns:!(),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,key:mdc.process.id,negate:!f,params:(query:${processId}),type:phrase),query:(match_phrase:(mdc.process.id:${processId})))),interval:auto,query:(language:kuery,query:''),sort:!(!('@timestamp',desc)))`
    let a = document.createElement("a");
    a.setAttribute("target", "_blank");
    a.setAttribute("rel", "noopener noreferrer");
    a.setAttribute("href", kibanaUrl);
    a.click();
    a.remove();
  };
  return (
    <Button
      size="xs"
      onClick={KibanaLink}
      colorScheme='gray'
      variant='outline'
    >
      <Text>{"Kibana"}</Text>
    </Button>
  );
};
