import React from "react";

import { Grid } from "@chakra-ui/react";
import { motion } from "framer-motion";

import ProductForm from "./Details/ProductForm";
import DeparturesForm from "./Departures/DeparturesForm";

function Product() {
  return (
    <motion.div initial="exit" animate="enter" exit="exit" w="100%">
      <Grid
        templateColumns="repeat(6, 1fr)"
        gap={12}
        minW={{ xl: "1200px" }}
        w="100%"
      >
        <ProductForm />
        <DeparturesForm />
      </Grid>
    </motion.div>
  );
}

export default Product;
