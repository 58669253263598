import React from "react";

import QuoteInvoice from "../../../components/QuoteInvoice/QuoteInvoice";

function PreviewPrice({ previewPrice }) {
  return (
    <QuoteInvoice
      details={{
        ...previewPrice.quote,
        title: "Preview Price",
        displayPrice: previewPrice.displayPrice,
      }}
    />
  );
}

export default PreviewPrice;
