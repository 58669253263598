import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { animateScroll as scroll } from "react-scroll";

import axios from "../../configs/axios-config";
import { ITicketState } from "../../types/TicketTypes";
import { handleError } from "../Error/errorSlice";
import { DateTime } from "luxon";
import { addLog } from "../LogConsole/logConsoleSlice";

const initialState: ITicketState = {
  loading: "idle",
  searchResults: undefined,
};

export const ticketSlice = createSlice({
  name: "ticket",
  initialState: initialState,
  reducers: {
    ticketLoading(state, action: PayloadAction<undefined | string>) {
      state.loading =
        state.loading === "idle"
          ? action.payload
            ? action.payload
            : "pending"
          : "idle";
    },
    updateTickets(state, action) {
      state.loading = "idle";
      state.searchResults = action.payload;
    },
    ticketSearchClear(state) {
      state.loading = "idle";
      state.searchResults = undefined;
    },
  },
});

const { actions, reducer } = ticketSlice;
export const { ticketLoading, updateTickets, ticketSearchClear } = actions;
export default reducer;

export const getTickets = (formData) => (dispatch) => {
  dispatch(ticketLoading());
  axios
    .get("/tickets", {
      params: {
        ...formData        
      },
    })
    .then((response) => {
      dispatch(addLog({ timestamp: DateTime.utc().toISO(), description: 'Retrieved Tickets', url: `/tickets`, processId: `${response.headers['x-process-id']}` }));
      scroll.scrollTo(350);
      dispatch(updateTickets(response.data));
    })
    .catch((err) => {
      dispatch(ticketLoading());
      dispatch(handleError(err));
    });
};
