import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Box, Button, Text, Textarea } from "@chakra-ui/react";
import _ from "lodash";

import { postCheckoutFlow } from "../../checkoutFlowSlice";

function QuestionsJSON({
  checkoutFlow: { flow, loading, step },
  fareSelections,
}) {
  const answers = [];
  if (!_.isEmpty(step.questions) && !_.isEmpty(step.questions.questionGroups)) {
    step.questions.questionGroups.forEach((group) => {
      if (!_.isEmpty(group.questions)) {
        group.questions.forEach((question) => {
          if (!_.isEmpty(question) && question.uuid) {
            answers.push({
              questionUuid: question.uuid,
              value: question.example,
            });
          }
        });
      }
    });
  }
  const [form, setForm] = useState(
    `{ "answers": [ ${JSON.stringify(answers)} ] }`
  );
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    setForm(value);
  };

  const dispatch = useDispatch();
  const handlePostCheckoutFlow = () => {
    const steps = [];
    flow.steps.forEach((flowStep) => {
      if (flowStep.id === step.id) {
        steps.push({
          ...step,
          answers: JSON.parse(form),
          fareDetails: undefined,
          fareSelections:
            step.milestone === "FARE_SELECTION" && !_.isEmpty(fareSelections)
              ? fareSelections
              : undefined,
          questions: undefined,
        });
      }
    });
    dispatch(postCheckoutFlow({ id: flow.id, steps }));
  };

  return (
    <Box>
      <Text mb={4}>
        If you'd rather not fill out the form and use JSON instead, you may
        enter it here. The contents here will be added to the currently active
        step under "answers".
      </Text>
      <Textarea
        h="25vh"
        name="JSON"
        onChange={handleInputChange}
        resize="vertical"
        w="100%"
        value={form}
      />
      <Box>
        <Button
          colorScheme="green"
          isLoading={loading === "pending"}
          loadingText="Posting"
          mt={4}
          onClick={() => handlePostCheckoutFlow()}
          w="100%"
        >
          Post
        </Button>
      </Box>
    </Box>
  );
}

export default QuestionsJSON;
