
import { useDispatch } from "react-redux";

import { InfoOutlineIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  Grid,
  GridItem,
  Checkbox,
  FormControl,
  Tooltip,
  Select,
  FormLabel
} from "@chakra-ui/react";
import copy from "copy-to-clipboard";
import { DateTime } from "luxon";
import { SearchInput } from "./Components/SearchInput";
import { SearchDatePicker } from "./Components/SearchDatePicker";
import { getBooking } from "../bookingSlice";

function SearchForm({
  form,
  handleClearSearchForm,
  handleInputChange,
  loading,
}) {
  const dispatch = useDispatch();
  const prepareSearchForm = () => {
    let temp = {}
    for (const key in form) {
      if (form[key] !== "" && form[key] !== null) {
        if ((key === "confirmedFrom" || key === "confirmedTo" || key === "travelFrom" || key === "travelTo") && (form[key] !== undefined)) {
          temp[key] = DateTime.fromJSDate(form[key]).toFormat("yyyy-MM-dd");
        } else if (form[key] !== undefined) {
          temp[key] = form[key];
        }
      }
    }
    return {
      ...temp
    };
  };
  const handleGetBooking = () => {
    dispatch(getBooking(prepareSearchForm()))
  };
  const handleCopyQueryString = () => copy(Object.keys(prepareSearchForm()).map(key => key + '=' + prepareSearchForm()[key]).join('&'));


  return (
    <>
      <Grid
        alignItems="center"
        gap={4}
        templateColumns="repeat(2, 1fr)"
        w="100%"
      >
        <GridItem>
          <SearchInput
            id="id"
            value={form.id}
            tooltipLabel={
              "Booking.id to retrieve."
            }
            handleChange={handleInputChange}
          />
        </GridItem>
        <GridItem>
          <SearchInput
            id="anyReference"
            value={form.anyReference}
            tooltipLabel={
              "Booking reference to retrieve. Could be any of the id, livnReference, etc."
            }
            handleChange={handleInputChange}
          />
        </GridItem>
        <GridItem>
          <SearchInput
            id="clientReference"
            value={form.clientReference}
            tooltipLabel={
              "Booking.clientReference to retrieve."
            }
            handleChange={handleInputChange}
          />
        </GridItem>
        <GridItem>
          <SearchInput
            id="livnReference"
            value={form.livnReference}
            tooltipLabel={
              "Booking.livnReference to retrieve."
            }
            handleChange={handleInputChange}
          />
        </GridItem>
        <GridItem>
          <SearchInput
            id="supplierReference"
            value={form.supplierReference}
            tooltipLabel={
              "Booking.supplierReference to retrieve."
            }
            handleChange={handleInputChange}
          />
        </GridItem>
        <GridItem>
          <SearchDatePicker
            id="confirmedFrom"
            tooltipLabel="If specified, limit search results to bookings confirmed on or after this date. Can be used combination with confirmedTo. Format yyyy-MM-dd."
            selected={form.confirmedFrom}
            handleChange={handleInputChange}
            dateFormat="yyyy-MM-dd"
          />
        </GridItem>
        <GridItem>
          <SearchDatePicker
            id="confirmedTo"
            tooltipLabel="Used in conjunction with confirmedFrom to limit search results to bookings confirmed in the specified period. Format yyyy-MM-dd."
            selected={form.confirmedTo}
            handleChange={handleInputChange}
            dateFormat="yyyy-MM-dd"
          />
        </GridItem>
        <GridItem>
          <SearchDatePicker
            id="travelFrom"
            tooltipLabel="If specified, limit search results to bookings with travel dates falling on or after this date. Can be used in combination with travelTo. Format yyyy-MM-dd."
            selected={form.travelFrom}
            handleChange={handleInputChange}
            dateFormat="yyyy-MM-dd"
          />
        </GridItem>
        <GridItem>
          <SearchDatePicker
            id="travelTo"
            tooltipLabel="If specified, limit search results to bookings with travel dates falling on or before this date. Can be used in combination with travelFrom. Format yyyy-MM-dd."
            selected={form.travelTo}
            handleChange={handleInputChange}
            dateFormat="yyyy-MM-dd"
          />
        </GridItem>
        <GridItem>
          <SearchInput
            id="partyEmailAddress"
            value={form.partyEmailAddress}
            tooltipLabel="Booking.partyEmailAddress to retrieve."
            handleChange={handleInputChange}
          />
        </GridItem>
        <GridItem>
          <SearchInput
            id="partyName"
            value={form.partyName}
            tooltipLabel="Booking.partyName to retrieve."
            handleChange={handleInputChange}
          />
        </GridItem>
        <GridItem>
          <SearchInput
            id="productIds"
            value={form.productIds}
            tooltipLabel="Comma-separated list of Booking.flow.productId values to narrow down on."
            handleChange={handleInputChange}
            placeholder="IDs - Comma Separated"
          />
        </GridItem>
        <GridItem>
          <SearchInput
            id="supplierIds"
            value={form.supplierIds}
            tooltipLabel="Comma separated list of Booking.flow.supplierId values to narrow down on."
            handleChange={handleInputChange}
            placeholder="IDs - Comma Separated"
          />
        </GridItem>
        <GridItem>
          <SearchInput
            id="resultsPerPage"
            value={form.resultsPerPage}
            tooltipLabel="The number of results on each page of the requested search."
            handleChange={handleInputChange}
          />
        </GridItem>
        <GridItem>
          <SearchInput
            id="currentPage"
            value={form.currentPage}
            tooltipLabel="The current page to view from the requested search."
            handleChange={handleInputChange}
          />
        </GridItem>
        <GridItem>
          <FormControl id="order">
            <FormLabel>order
              <Tooltip label="Specify the sort order of the returned results.">
                <InfoOutlineIcon color="gray.800" ml={2} />
              </Tooltip>
            </FormLabel>
            <Select
              name="order"
              onChange={handleInputChange}
              placeholder="Select order"
              value={form.order}
              w="100%"
            >
              <option value="ID">ID</option>
              <option value="LIVN_REFERENCE">LIVN_REFERENCE</option>
              <option value="CLIENT_REFERENCE">CLIENT_REFERENCE</option>
              <option value="SUPPLIER_REFERENCE">SUPPLIER_REFERENCE</option>
              <option value="PARTY_NAME">PARTY_NAME</option>
              <option value="PARTY_EMAIL">PARTY_EMAIL</option>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl id="orderDescending">
            <Checkbox
              name="orderDescending" mb={2} mt={3} onChange={handleInputChange} isChecked={form.orderDescending} value={form.orderDescending}>
              orderDescending
              <Tooltip label="Reverse the default ascending order direction. Default value : false">
                <InfoOutlineIcon color="gray.800" ml={2} />
              </Tooltip>
            </Checkbox>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl id="includeChannel">
            <Checkbox
              name="includeChannel" mb={2} mt={3} onChange={handleInputChange} isChecked={form.includeChannel} value={form.includeChannel}>
              includeChannel
              <Tooltip label="Include the Booking.channel. This option only makes sense for Hybrid API clients that trade both as a Livn Direct Connect Distributor as well as purchase product from Livn Wholesale and thus operate through multiple Channels. Default value : false">
                <InfoOutlineIcon color="gray.800" ml={2} />
              </Tooltip>
            </Checkbox>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl id="includeDistributor">
            <Checkbox
              name="includeDistributor" mb={2} mt={3} onChange={handleInputChange} isChecked={form.includeDistributor} value={form.includeDistributor}>
              includeDistributor
              <Tooltip label="Include the Booking.distributor. This option only makes sense for Hybrid API clients that trade both as a Livn Direct Connect Distributor as well as purchase product from Livn Wholesale and thus deal with multiple Distributors. Default value : false">
                <InfoOutlineIcon color="gray.800" ml={2} />
              </Tooltip>
            </Checkbox>
          </FormControl>
        </GridItem>
      </Grid>
      <Grid
        alignItems="top"
        gap={4}
        mt={4}
        templateColumns="repeat(3, 1fr)"
        w="100%"
      >
        <GridItem>
          <Button
            mt={4}
            onClick={() => handleClearSearchForm()}
            variant="outline"
            w="100%"
          >
            Clear form
          </Button>
        </GridItem>
        <GridItem>
          <Button
            mt={4}
            onClick={() => handleCopyQueryString()}
            variant="outline"
            w="100%"
          >
            Copy query string
          </Button>
        </GridItem>
        <GridItem>
          <Button
            leftIcon={<SearchIcon />}
            colorScheme="green"
            isLoading={loading === "searching"}
            loadingText="Searching"
            mt={4}
            onClick={() => handleGetBooking()}
            w="100%"
          >
            Search
          </Button>
        </GridItem>
      </Grid>
    </>
  );
}

export default SearchForm;
