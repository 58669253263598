import { Flex } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { Outlet } from "react-router-dom";

import Header from "./Header";

import Error from "../features/Error/Error";

export default function Layout({ login }) {
  return (
    <>
      <Header login={login} />
      <Flex
        direction="column"
        align="center"
        m="0 auto"
        maxW={{ xl: "1200px" }}
        minW={{ xl: "1200px" }}
        pt={40}
        pb={12}
        w="100%"
      >
        <AnimatePresence exitBeforeEnter>
          <Error key="error" />
          <Outlet />
        </AnimatePresence>
      </Flex>
    </>
  );
}
