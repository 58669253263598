import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { DateTime } from "luxon";

import axios from "../../configs/axios-config";
import { ICheckoutFlow, ICheckoutFlowState } from "../../types/FlowTypes";
import { clearError, handleError } from "../Error/errorSlice";
import { addLog } from "../LogConsole/logConsoleSlice";

const initialState: ICheckoutFlowState = {
  loading: "idle",
  answers: {},
  bookings: [],
  errors: [],
  flow: undefined,
  preview: undefined,
  step: undefined,
};

export const checkoutFlowSlice = createSlice({
  name: "checkoutFlow",
  initialState,
  reducers: {
    checkoutFlowClear(state) {
      state.loading = "idle";
      state.answers = {};
      state.bookings = [];
      state.errors = [];
      state.flow = undefined;
      state.preview = undefined;
      state.step = undefined;
    },
    checkoutFlowLoading(state) {
      state.loading = state.loading === "idle" ? "pending" : "idle";
    },
    checkoutFlowUpdate(state, action) {
      state.answers = action.payload.answers ? action.payload.answers : {};
      state.bookings = action.payload.flow.bookings ? action.payload.flow.bookings : [];
      state.errors = action.payload.errors ? action.payload.errors : [];
      state.flow = action.payload.flow;
      state.loading = "idle";
      state.preview = undefined;
      state.step = action.payload.step ? action.payload.step : {};
    },
    checkoutPreviewPrice(state, action) {
      state.loading = "idle";
      state.preview = action.payload;
    },
  },
});

const { actions, reducer } = checkoutFlowSlice;
export const {
  checkoutFlowClear,
  checkoutFlowLoading,
  checkoutFlowUpdate,
  checkoutPreviewPrice,
} = actions;
export default reducer;

const handleResponse = (data: ICheckoutFlow) => (dispatch) => {
  dispatch(checkoutFlowClear());
  let step = data.steps?.find(({ status }) => status === "ACTIVE" || status === "FAILED");
  const answers = {};
  if (
    !_.isEmpty(step?.questions) &&
    !_.isEmpty(step?.questions.questionGroups)
  ) {
    step!.questions.questionGroups.forEach((group) => {
      if (!_.isEmpty(group) && !_.isEmpty(group.questions)) {
        group.questions.forEach((question) => {
          if (question.uuid) {
            answers[question.uuid] = "";
          }
        });
      }
    });
  }
  const errors: ICheckoutFlowState["errors"] = [];
  if (!_.isEmpty(data.steps)) {
    data!.steps!.forEach((step) => {
      if (step.status === "FAILED") {
        errors.push(step.error);
      }
    });
  }
  dispatch(
    checkoutFlowUpdate({
      flow: data,
      answers,
      errors,
      step,
    })
  );
};

export const getCheckoutFlow = (flowId) => (dispatch) => {
  dispatch(checkoutFlowLoading());
  axios
    .get(`/flows/${flowId}`)
    .then((response) => {
      dispatch(addLog({ timestamp: DateTime.utc().toISO(), description: 'Get Checkout flow', url: `/flows/${flowId}`, processId: `${response.headers['x-process-id']}` }));
      dispatch(handleResponse(response.data));
      dispatch(clearError());
    })
    .catch((err) => {
      dispatch(checkoutFlowLoading());
      dispatch(handleError(err));
    });
};

export const postCheckoutFlow = (formValues, back?) => (dispatch) => {
  dispatch(checkoutFlowClear());
  dispatch(checkoutFlowLoading());
  axios
    .post("/flows", formValues, {
      params: {
        back: back && back === "BACK" ? true : false,
      },
    })
    .then((response) => {
      if (!formValues.id) {
        dispatch(addLog({ timestamp: DateTime.utc().toISO(), description: 'Initiated Checkout flow', url: `/flows`, processId: `${response.headers['x-process-id']}` }));
      } else if (response.data.steps[1] !== undefined) {
        const caption = response.data.steps[1].caption;
        dispatch(addLog({ timestamp: DateTime.utc().toISO(), description: `POST flow for: ${caption}`, url: `/flows`, processId: `${response.headers['x-process-id']}` }));
      } else if (back === 'BACK') {
        dispatch(addLog({ timestamp: DateTime.utc().toISO(), description: `Went back to the previous flow step`, url: `/flows?back=true`, processId: `${response.headers['x-process-id']}` }));
      }
      dispatch(handleResponse(response.data));
      dispatch(clearError());
    })
    .catch((err) => {
      dispatch(checkoutFlowLoading());
      dispatch(handleError(err));
    });
};

export const postFareSelection = (farePayload) => (dispatch) => {
  dispatch(checkoutFlowLoading());
  axios
    .post("/flows", farePayload)
    .then((response) => {
      dispatch(addLog({ timestamp: DateTime.utc().toISO(), description: 'Fare selection', url: `/flows`, processId: `${response.headers['x-process-id']}` }));
      dispatch(handleResponse(response.data));
      dispatch(clearError());
    })
    .catch((err) => {
      dispatch(checkoutFlowLoading());
      dispatch(handleError(err));
    });
};

export const postPreviewPrice = (farePayload) => (dispatch) => {
  dispatch(checkoutFlowLoading());
  axios
    .post("/flows/previewPrice", farePayload)
    .then((response) => {
      dispatch(addLog({ timestamp: DateTime.utc().toISO(), description: 'Preview price', url: `/flows/previewPrice`, processId: `${response.headers['x-process-id']}` }));
      dispatch(checkoutPreviewPrice(response.data));
      dispatch(clearError());
    })
    .catch((err) => {
      dispatch(checkoutFlowLoading());
      dispatch(handleError(err));
    });
};
