export const transition = {
  duration: 0.5,
  ease: [0.43, 0.13, 0.23, 0.96],
};

export const divVariants = {
  exit: { opacity: 0, transition },
  enter: { opacity: 1, transition },
};

export const staggeredChildren = {
  enter: {
    opacity: 1,
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  exit: {
    opacity: 0,
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export const staggeredChild = {
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  exit: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};
