import React from "react";

import { Box, Heading } from "@chakra-ui/react";
import DetailsLayout from "./DetailsLayout";

function PassengerDetails({ passengerDetails }) {
  //format the 'otherDetails' field so we can display it properly
  const formattedPassengerDetails = passengerDetails.map(
    ({ otherDetails = [], ...passenger }) => ({
      ...passenger,
      ...otherDetails.reduce(
        (acc, curr) => ({ ...acc, [curr.title]: curr.body }),
        {}
      ),
    })
  );

  return (
    <Box mt={4}>
      <Heading as="h5" mb={4} size="md">
        Passenger Details
      </Heading>
      {formattedPassengerDetails.map((passengerDetail, index) => (
        <DetailsLayout key={index} details={passengerDetail} />
      ))}
    </Box>
  );
}

export default PassengerDetails;
