import React, { memo } from "react";

import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Tooltip,
} from "@chakra-ui/react";

export const SearchInput = memo(
  ({ id, value, placeholder, tooltipLabel, helperText, handleChange }) => (
    <FormControl id={id}>
      <FormLabel>
        {id}
        {tooltipLabel && (
          <Tooltip label={tooltipLabel}>
            <InfoOutlineIcon color="gray.800" ml={2} />
          </Tooltip>
        )}
      </FormLabel>
      <Input
        name={id}
        onChange={handleChange}
        placeholder={placeholder}
        value={value}
        w="100%"
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
);
