import React from "react";

import DetailsLayout from "./DetailsLayout";
import { formatDateTime } from "../../features/dateUtils";

function TicketDetails({ ticket }) {
  //ticket is readonly, so clone it
  ({ ...ticket }.created = ticket.created && formatDateTime(ticket.created));
  return <DetailsLayout details={ticket} />;
}

export default TicketDetails;
