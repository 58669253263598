import { useDispatch } from "react-redux";

import {
  Box,
  Button,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import _ from "lodash";

import { getBooking } from "../bookingSlice";

import JSONDisplay from "../../../components/JSONDisplay";
import Pagination from "../../../components/Pagination/Pagination";
import PaginationButtons from "../../../components/Pagination/PaginationButtons";
import Booking from "../../../components/Booking/Booking";

function SearchResults({ loading, searchResults }) {
  const { paging } = searchResults;

  const dispatch = useDispatch();
  const handlePageChange = (page) => {
    dispatch(
      getBooking({
        id: searchResults.id,
        resultsPerPage: searchResults.paging
          ? searchResults.paging.resultsPerPage
          : 10,
        currentPage: page,
      })
    );
  };

  const pageButtons = [];
  if (searchResults.paging) {
    for (let i = 1; i <= searchResults.paging.pagesTotal; i++) {
      pageButtons.push(
        <Button
          colorScheme="blue"
          disabled={paging.currentPage === i}
          key={i}
          mr="-px"
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Button>
      );
    }
  }

  return (
    <Box>
      {searchResults.paging ? (
        <Heading as="h3" mb={4} size="lg">
          Results: {searchResults.paging.resultsTotal} total
        </Heading>
      ) : null}
      <Tabs isFitted variant="enclosed-colored">
        <TabList mb="1em">
          {searchResults.paging ? (
            <Tab>
              {searchResults.paging.resultsPerPage} results per page /{" "}
              {searchResults.paging.pagesTotal} page(s)
            </Tab>
          ) : (
            <Tab>Bookings</Tab>
          )}
          <Tab>JSON</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {searchResults.paging ? (
              <Pagination
                currentPage={paging.currentPage}
                handlePageChange={handlePageChange}
                pagesTotal={paging.pagesTotal}
              >
                <PaginationButtons
                  currentPage={paging.currentPage}
                  handlePageChange={handlePageChange}
                  pagesTotal={paging.pagesTotal}
                />
              </Pagination>
            ) : null}
            {!_.isEmpty(searchResults.booking) ? (
              <Booking
                booking={searchResults.booking}
                key={searchResults.booking.id}
              />
            ) : null}
            {!_.isEmpty(searchResults.bookings)
              ? searchResults.bookings.map((result) => (
                <Booking booking={result} key={result.id} />
              ))
              : null}
            {searchResults.paging ? (
              <Pagination
                currentPage={paging.currentPage}
                handlePageChange={handlePageChange}
                pagesTotal={paging.pagesTotal}
              >
                <PaginationButtons
                  currentPage={paging.currentPage}
                  handlePageChange={handlePageChange}
                  pagesTotal={paging.pagesTotal}
                />
              </Pagination>
            ) : null}
          </TabPanel>
          <TabPanel>
            <JSONDisplay
              jsonToDisplay={
                !_.isEmpty(searchResults.bookings)
                  ? searchResults.bookings
                  : !_.isEmpty(searchResults.booking)
                    ? searchResults.booking
                    : {}
              }
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default SearchResults;
