import React from "react";

import { Box, Flex, Text, Heading } from "@chakra-ui/react";

function DetailsLayout({ details }) {
  return (
    <>
      <Flex align="center" justify="start" flexWrap="wrap">
        {Object.entries(details).map(
          ([key, value]) => typeof value !== "object" && key !== 'generalTerms' && value !== undefined && (<Box key={key} mx="15px" mb="16px">
            <Text color="gray.800">{key}</Text>
            {/* in backticks to convert to string in case of bools */}
            <Text>{`${value}`}</Text>
          </Box>
          )
        )}
      </Flex>
      {details.otherDetails
        ? (
          <Box mx="15px" mb={4}>
            <Heading as="h5" mb={4} size="sm">
              Other Details
            </Heading>
            {details.otherDetails.map((otherDetails, i) => (
              <Box mb={4} key={i}>
                <Text color="gray.800">{otherDetails.title}</Text>
                <Text>{otherDetails.body}</Text>
              </Box>
            ))}
          </Box>
        )
        : null}
    </>
  );
}

export default DetailsLayout;
