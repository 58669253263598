import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { animateScroll as scroll } from "react-scroll";

import axios from "../../configs/axios-config";
import { IBookingState } from "../../types/BookingTypes";
import { handleError } from "../Error/errorSlice";
import { DateTime } from "luxon";
import { addLog } from "../LogConsole/logConsoleSlice";

const initialState: IBookingState = {
  loading: "idle",
  cancellation: undefined,
  searchResults: undefined,
};

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    bookingLoading(state, action: PayloadAction<undefined | string>) {
      state.loading =
        state.loading === "idle"
          ? action.payload
            ? action.payload
            : "pending"
          : "idle";
    },
    updateBookingResults(state, action) {
      state.cancellation = undefined;
      state.loading = "idle";
      state.searchResults = action.payload;
    },
    updateBookingCancellation(state, action) {
      state.cancellation = action.payload;
      state.loading = "idle";
      state.searchResults = undefined;
    },
    bookingSearchClear(state) {
      state.loading = "idle";
      state.searchResults = undefined;
      state.cancellation = undefined;
    },
  },
});

const { actions, reducer } = bookingSlice;
export const {
  bookingLoading,
  updateBookingResults,
  updateBookingCancellation,
  bookingSearchClear
} = actions;
export default reducer;

export const getBooking = (formData) => (dispatch) => {
  dispatch(bookingLoading());
  axios.get("/bookings", {
    params: {
      id: formData.id ? formData.id : undefined,
      anyReference: formData.anyReference ? formData.anyReference : undefined,
      clientReference: formData.clientReference ? formData.clientReference : undefined,
      livnReference: formData.livnReference ? formData.livnReference : undefined,
      supplierReference: formData.supplierReference ? formData.supplierReference : undefined,
      confirmedFrom: formData.confirmedFrom ? formData.confirmedFrom : undefined,
      confirmedTo: formData.confirmedTo ? formData.confirmedTo : undefined,
      travelFrom: formData.travelFrom ? formData.travelFrom : undefined,
      travelTo: formData.travelTo ? formData.travelTo : undefined,
      partyEmailAddress: formData.partyEmailAddress ? formData.partyEmailAddress : undefined,
      partyName: formData.partyName ? formData.partyName : undefined,
      resultsPerPage: formData.resultsPerPage ? formData.resultsPerPage : undefined,
      currentPage: formData.currentPage ? formData.currentPage : undefined,
      supplierIds: formData.supplierIds ? formData.supplierIds : undefined,
      productIds: formData.productIds ? formData.productIds : undefined,
      includeChannel:formData.includeChannel ? formData.includeChannel : undefined,
      includeDistributor:formData.includeDistributor ? formData.includeDistributor : undefined,
      order: formData.order ? formData.order : "ID",
      orderDescending: formData.orderDescending ? formData.orderDescending : false
    }
  })
    .then((response) => {
      dispatch(addLog({ timestamp: DateTime.utc().toISO(), description: 'Get Booking/s', url: `/bookings`, processId: `${response.headers['x-process-id']}` }));
      scroll.scrollTo(800);
      dispatch(updateBookingResults(response.data));
    })
    .catch((err) => {
      dispatch(bookingLoading());
      dispatch(handleError(err));
    });
};

export const deleteBooking = (formData) => (dispatch) => {
  dispatch(bookingLoading("cancelling"));
  axios
    .delete(`/bookings/${formData.cancelBookingId}`, {
      params: {
        reason: formData.reason ? formData.reason : undefined,
      },
    })
    .then((response) => {
      dispatch(addLog({ timestamp: DateTime.utc().toISO(), description: 'Cancel Booking', url: `/bookings/${formData.cancelBookingId}`, processId: `${response.headers['x-process-id']}` }));
      dispatch(updateBookingCancellation(response.data));
    })
    .catch((err) => {
      dispatch(bookingLoading());
      dispatch(handleError(err));
    });
};
