import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import axios from "../../configs/axios-config";
import axiosVanilla from "axios";
import { DateTime } from "luxon";

import { handleError, clearError } from "../Error/errorSlice";
import { clearLogs } from "../LogConsole/logConsoleSlice";

export interface ILoginState {
  expiry: string;
  loading: string;
  satellite: string;
  status: string;
  username: string;
  ip: string;
  customHeaders: string[];
}

const initialState: ILoginState = {
  expiry: "",
  loading: "idle",
  satellite: "",
  status: "LOGGED_OUT",
  username: "",
  ip: "",
  customHeaders: [],
};

export const loginSlice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    expiryUpdate(state, { payload }: PayloadAction<string>) {
      state.expiry = payload;
    },
    loginLoading(state) {
      state.loading = state.loading === "idle" ? "pending" : "idle";
    },
    loginUpdate(state, { payload }: PayloadAction<string>) {
      state.loading = "idle";
      state.status = "LOGGED_IN";
      state.username = payload;
    },
    logoutUpdate(state) {
      state.expiry = "";
      state.loading = "idle";
      state.status = "LOGGED_OUT";
      state.username = "";
      state.ip = "";
      state.customHeaders = [];
    },
    satelliteUpdate(state, { payload }: PayloadAction<string>) {
      state.satellite = payload;
    },
    ipUpdate(state, { payload }: PayloadAction<string>) {
      state.ip = payload;
    },
  },
});

const { actions, reducer } = loginSlice;
export const {
  expiryUpdate,
  loginLoading,
  loginUpdate,
  logoutUpdate,
  satelliteUpdate,
  ipUpdate,
} = actions;
export default reducer;

export const checkSession = () => (dispatch) => {
  const localExpiry = localStorage.getItem("expiry");
  const localToken = localStorage.getItem("jwt_token");
  const localUsername = localStorage.getItem("username");
  const localSatellite = localStorage.getItem("satellite");
  const localHeaders = localStorage.getItem("customHeaders");
  const ip = localStorage.getItem("ip");
  let completeSatellite = localSatellite;
  if (completeSatellite) {
    if (completeSatellite.charAt(completeSatellite.length - 1) !== "/") {
      completeSatellite += "/";
    }
    completeSatellite += "api";
  }
  let validJwt = true;
  if (localToken) {
    let pattern = /(^[A-Za-z0-9-_]*\.[A-Za-z0-9-_]*\.[A-Za-z0-9-_]*$)/;
    validJwt = pattern.test(localToken);
  }
  if (
    localToken &&
    validJwt &&
    localExpiry &&
    localUsername &&
    ip &&
    localUsername !== "undefined" &&
    completeSatellite
  ) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + localToken;
    if (localHeaders) {
      let splitted = localHeaders.split(",");
      for (let header of splitted) {
        axios.defaults.headers.common[header.split(":")[0]] =
          header.split(":")[1];
      }
    }
    dispatch(loginUpdate(localUsername));
    dispatch(expiryUpdate(localExpiry));
    dispatch(setSatellite(completeSatellite));
    dispatch(ipUpdate(ip));
  } else {
    dispatch(logoutUpdate());
    dispatch(setSession());
  }
};

export const postLogin = (formData, toast) => (dispatch) => {
  dispatch(loginLoading());
  let validHeaders: string[] = [];
  const customHeaders = formData.customHeaders.split(/\n/);
  if (customHeaders.length !== 0) {
    for (let header of customHeaders) {
      const index = header.indexOf(":");
      if (index !== -1) {
        const parts = header.split(":");
        if (parts.length === 2) {
          const name = parts[0].trim();
          const value = parts[1].trim();
          axios.defaults.headers.common[name] = value;
          validHeaders.push(`${name}:${value}`);
        }
      }
    }
  }
  let completeSatellite = formData.url ? formData.url : "";
  if (completeSatellite.charAt(completeSatellite.length - 1) !== "/") {
    completeSatellite += "/";
  }
  completeSatellite += "api";
  axios
    .post(
      `${completeSatellite}/login`,
      {
        ...formData,
        url: undefined,
      },
      {
        timeout: 5000,
      },
    )
    .then((response) => {
      // check if the response is a valid JWT
      let token = response.data;
      let pattern = /(^[A-Za-z0-9-_]*\.[A-Za-z0-9-_]*\.[A-Za-z0-9-_]*$)/;
      let validJwt = pattern.test(token);
      if (validJwt) {
        dispatch(clearLogs());
        dispatch(setSatellite(completeSatellite));
        dispatch(
          setSession(
            response.data,
            formData.username,
            formData.url,
            validHeaders,
          ),
        );
        dispatch(getIp());
        dispatch(clearError());
      } else {
        throw new Error();
      }
    })
    .catch((err) => {
      dispatch(loginLoading());
      toast({
        description:
          err && err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "Login timed out. This is likely due to an incorrect satellite URL.",
        duration: 6000,
        isClosable: true,
        position: "top",
        status: "error",
      });
    });
};

export const postLogout = () => (dispatch) => {
  axios
    .post("/logout")
    .then((response) => {
      dispatch(logoutUpdate());
      dispatch(setSession());
      dispatch(clearLogs());
    })
    .catch((err) => {
      dispatch(logoutUpdate());
      dispatch(setSession());
      dispatch(clearLogs());
      dispatch(handleError(err));
    });
};

export const setSatellite = (satellite: string) => (dispatch) => {
  axios.defaults.baseURL = satellite;
  dispatch(satelliteUpdate(satellite));
};

export const setSession =
  (token?, username?, satellite?, validHeaders?: string[]) => (dispatch) => {
    if (token) {
      localStorage.setItem("jwt_token", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      if (validHeaders && validHeaders.length !== 0) {
        localStorage.setItem("customHeaders", validHeaders.join());
      }
      localStorage.setItem("username", username);
      dispatch(loginUpdate(username));
      localStorage.setItem("satellite", satellite);
      const expiry = DateTime.now().plus({ hours: 3 }).toISO();
      localStorage.setItem("expiry", expiry || "");
      dispatch(expiryUpdate(expiry || ""));
    } else {
      localStorage.removeItem("jwt_token");
      delete axios.defaults.headers.common["Authorization"];
      const localHeaders = localStorage.getItem("customHeaders");
      if (localHeaders) {
        let splitted = localHeaders.split(",");
        for (let header of splitted) {
          delete axios.defaults.headers.common[header.split(":")[0]];
        }
      }
      localStorage.removeItem("customHeaders");
      localStorage.removeItem("username");
      localStorage.removeItem("satellite");
      localStorage.removeItem("expiry");
      localStorage.removeItem("ip");
    }
  };

export const getIp = () => (dispatch) => {
  axiosVanilla
    .get("https://api.ipify.org?format=json")
    .then((response) => {
      localStorage.setItem("ip", response.data.ip);
      dispatch(ipUpdate(response.data.ip));
    })
    .catch((err) => {
      dispatch(ipUpdate("0.0.0.0"));
    });
};
