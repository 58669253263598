import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Grid,
  GridItem,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import _ from "lodash";

import { getProductCategories, productSearchClear } from "../productSlice";

import { divVariants } from "../../../configs/pageMotions";

import Loading from "../../../components/Loading/Loading";
import Categories from "../Components/Categories";
import SearchJSON from "./Components/SearchJSON";
import GetSearch from "./GetSearch";
import SearchForm from "./SearchForm";
import SearchResults from "./SearchResults";
import { useSelector } from "../../../app/store";

const initialFormState: {
  resultsPerPage: number;
  currentPage: number;
  latitude: string;
  longitude: string;
  airport: string;
  radius: string;
  countries: string[];
  fts: string;
  categoriesMatchingMode: string;
  operatesFrom?: string;
  operatesTo?: string;
  modifiedSince?: string;
  durationMin: string;
  durationMax: string;
  includeDisabled: boolean;
  order: string;
  orderDescending: boolean;
  categories: string;
  productIds: string;
  supplierIds: string;
} = {
  resultsPerPage: 100,
  currentPage: 1,
  latitude: "",
  longitude: "",
  airport: "",
  radius: "",
  countries: [],
  fts: "",
  categoriesMatchingMode: "",
  operatesFrom: undefined,
  operatesTo: undefined,
  modifiedSince: undefined,
  durationMin: "",
  durationMax: "",
  includeDisabled: false,
  order: "",
  orderDescending: false,
  categories: "",
  productIds: "",
  supplierIds: "",
};

function Search() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(productSearchClear());
    dispatch(getProductCategories());
  }, [dispatch]);

  const product = useSelector(({ product }) => product);

  const [searchForm, setSearchForm] = useState(initialFormState);
  const handleSearchInputChange = useCallback((event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setSearchForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);
  const handleAddCategory = useCallback((categoryId: string) => {
    setSearchForm((prevState) => ({
      ...prevState,
      categories: [
        ...prevState.categories
          .replace(/\s+/g, "")
          .split(",")
          .filter((item) => item !== ""),
        categoryId,
      ].join(","),
    }));
  }, []);
  const handleChangeDates = (operatesFrom, operatesTo, modifiedSince) => {
    setSearchForm({
      ...searchForm,
      operatesFrom,
      operatesTo,
      modifiedSince
    });
  };
  const handleClearSearchForm = () => setSearchForm(initialFormState);

  return (
    <motion.div initial="exit" animate="enter" exit="exit">
      <Grid
        templateColumns="repeat(6, 1fr)"
        gap={12}
        minW={{ xl: "1200px" }}
        w="100%"
      >
        <GridItem colSpan={4}>
          <motion.div variants={divVariants}>
            <Heading as="h3" mb={4} size="lg">
              Product Search
            </Heading>
            <Tabs isFitted variant="enclosed-colored">
              <TabList mb="1em">
                <Tab>Search Form</Tab>
                <Tab>JSON</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <SearchForm
                    form={searchForm}
                    handleChangeDates={handleChangeDates}
                    handleClearSearchForm={handleClearSearchForm}
                    handleInputChange={handleSearchInputChange}
                    loading={product.loading}
                  />
                </TabPanel>
                <TabPanel>
                  <SearchJSON form={searchForm} loading={product.loading} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </motion.div>
        </GridItem>
        <GridItem colSpan={2}>
          <motion.div variants={divVariants}>
            {!_.isEmpty(product.categories) ? (
              <Categories
                categories={product.categories}
                handleAddCategory={handleAddCategory}
              />
            ) : null}
            <GetSearch loading={product.loading} />
          </motion.div>
        </GridItem>

        {product.loading === "searching" ? (
          <Loading colSpan={6} text="Searching..." />
        ) : null}

        {product.searchResults && product.loading !== "searching" && (
          <GridItem colSpan={6} w="100%">
            <motion.div variants={divVariants}>
              <SearchResults
                loading={product.loading}
                searchResults={product.searchResults}
              />
            </motion.div>
          </GridItem>
        )}
      </Grid>
    </motion.div>
  );
}

export default Search;
