import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useParams } from "react-router-dom";

import {
  Box,
  Flex,
  Heading,
  Link,
  Tab,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";
import { DateTime } from "luxon";

import { getProductDepartures } from "../productSlice";
import JSONDisplay from "../../../components/JSONDisplay";
import Loading from "../../../components/Loading/Loading";

function ProductDepartures() {
  let { productId } = useParams();

  const departures = useSelector(({ product }) => product.departures);
  const product = useSelector(({ product }) => product);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProductDepartures(productId));
  }, [dispatch, productId]);

  return !_.isEmpty(departures) ? (
    <div>
      <Heading as="h3" mb={4} size="lg">
        {departures.productName}
        <Text color="gray.800" fontSize="xs">
          id: {departures.productId}
        </Text>
      </Heading>

      <Box mb={8}>
        <Link
          as={RouterLink}
          color="blue.500"
          to={`/product/details/${productId}`}
        >
          View product details
        </Link>
      </Box>

      <Tabs isFitted variant="enclosed-colored">
        <TabList mb="1em">
          <Tab>Departures</Tab>
          <Tab>JSON</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  <Th>From Price</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {!_.isEmpty(departures.departures)
                  ? departures.departures.map((departure, departureIndex) => (
                    <Tr key={departureIndex}>
                      <Td>
                        {DateTime.fromISO(departure.date).toFormat(
                          "yyyy-MM-dd"
                        )}
                      </Td>
                      <Td>
                        <Flex>
                          {departure.fromPrices.map((price, priceIndex) =>
                            !_.isEmpty(price) ? (
                              <Box key={priceIndex} px={2}>
                                {price.amount.toLocaleString("en-au", {
                                  style: "currency",
                                  currency: price.currency,
                                  currencyDisplay: "code"
                                })}
                              </Box>
                            ) : null
                          )}
                        </Flex>
                      </Td>
                      <Td>
                        <Link
                          as={RouterLink}
                          color="blue.500"
                          px={6}
                          to={`/flow/${productId}/${departure.date}`}
                        >
                          Start flow
                        </Link>
                      </Td>
                    </Tr>
                  ))
                  : null}
              </Tbody>
            </Table>
          </TabPanel>
          <TabPanel>
            <JSONDisplay jsonToDisplay={departures.departures} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  ) : product.loading === "searchingDepartures" ?
    <Loading text="Loading departures..." />
    :
    <Link
      _hover={{
        textDecoration: "none",
      }}
      as={RouterLink}
      to={`/products`}
    >
      <Box
        _hover={{
          bg: "blue.600",
        }}
        align="center"
        bg="blue.500"
        borderRadius="md"
        color="white"
        px={4}
        py={2.5}
        w="100%"
      >
        Return to Get Departures
      </Box>
    </Link>;
}

export default ProductDepartures;
