import { Box, Heading, Text} from "@chakra-ui/react";

function SupplierTncs({ generalTerms, maxHeight = "500px" }) {
  return generalTerms ? (
    <Box
      bg="gray.100"
      borderWidth="1px"
      borderRadius="lg"
      color="gray.800"
      maxHeight={maxHeight}
      my={4}
      overflowY="auto"
      p={6}
    >
      <Heading as="h6" mb={4} size="sm">
        Supplier Terms and Conditions
      </Heading>      
      {generalTerms ? (
        <Box my={2}>
          <Text fontSize="xs" fontWeight="semibold">
            generalTerms
          </Text>
          <Text fontSize="sm">{generalTerms}</Text>
        </Box>
      ) : null}      
    </Box>
  ) : null;
}

export default SupplierTncs;
