import React from "react";

import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import _ from "lodash";

function AddOn({ addOn }) {
  return (
    <Box>
      <Text as="samp" color="gray.700" fontSize="xs">
        addOn
        <Tooltip label="Optional add-on products that can be purchased in conjunction with a main Product.">
          <InfoOutlineIcon color="gray.700" ml={2} />
        </Tooltip>
      </Text>
      {addOn.name ? (
        <Flex align="flex-start" justify="space-between">
          <Text fontWeight="semibold" fontSize="sm">
            {addOn.name}
          </Text>
        </Flex>
      ) : null}

      {addOn.description ? (
        <Box my={1}>
          <Text color="gray.700" fontSize="xs">
            Description
          </Text>
          <Text fontSize="xs">{addOn.description}</Text>
        </Box>
      ) : null}

      {_.isNumber(addOn.unitsAvailable) ? (
        <Flex align="flex-start" justify="space-between">
          <Text color="gray.700" fontSize="sm">
            Units available
          </Text>
          <Text fontSize="sm">{addOn.unitsAvailable}</Text>
        </Flex>
      ) : null}

      {addOn.unitsMin || addOn.unitsMax ? (
        <Flex align="flex-start" justify="space-between">
          <Text color="gray.700" fontSize="sm">
            Units Min/Max
          </Text>
          <Text fontSize="sm">
            {_.isNumber(addOn.unitsMin) ? addOn.unitsMin : null} /{" "}
            {_.isNumber(addOn.unitsMax) ? addOn.unitsMax : null}
          </Text>
        </Flex>
      ) : null}

      {addOn.unitsMultipleOf ? (
        <Flex align="flex-start" justify="space-between">
          <Text color="gray.700" fontSize="sm">
            Units Multiple Of
          </Text>
          <Text fontSize="sm">{addOn.unitsMultipleOf}</Text>
        </Flex>
      ) : null}

      {addOn.ageMin || addOn.ageMax ? (
        <Flex align="flex-start" justify="space-between">
          <Text color="gray.700" fontSize="sm">
            Age Min - Max
          </Text>
          <Text fontSize="sm">
            {_.isNumber(addOn.ageMin) ? addOn.ageMin : null} -{" "}
            {_.isNumber(addOn.ageMax) ? addOn.ageMax : null}
          </Text>
        </Flex>
      ) : null}

      {addOn.ageQualified ? (
        <Flex align="flex-start" justify="space-between">
          <Text color="gray.700" fontSize="sm">
            Age Qualified
          </Text>
          <Text fontSize="sm">{addOn.ageQualified}</Text>
        </Flex>
      ) : null}

      {!_.isEmpty(addOn.price) ? (
        <Flex align="flex-start" justify="space-between">
          <Text color="gray.700" fontSize="sm">
            Price
          </Text>
          <Text fontSize="sm">
            {addOn.price.currency ? addOn.price.currency : null}{" "}
            {_.isNumber(addOn.price.amount) ? addOn.price.amount : null}
          </Text>
        </Flex>
      ) : null}

      {addOn.specialNotes ? (
        <Box my={1}>
          <Text color="gray.700" fontSize="xs">
            Special Notes
          </Text>
          <Text fontSize="xs">{addOn.specialNotes}</Text>
        </Box>
      ) : null}

      {addOn.otherCharges ? (
        <Box my={1}>
          <Text color="gray.700" fontSize="xs">
            Other Charges
          </Text>
          <Text fontSize="xs">{addOn.otherCharges}</Text>
        </Box>
      ) : null}
    </Box>
  );
}

export default AddOn;
