import { useCallback, useState, useEffect } from "react";
import SearchForm from "./SearchForm";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../app/store";
import SearchQueryString from "./Components/SearchQueryString";

import {
  GridItem,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { bookingSearchClear } from "../bookingSlice";
import { divVariants } from "../../../configs/pageMotions";


const initialFormState: {
  resultsPerPage: number;
  currentPage: number;
  anyReference: string;
  clientReference: string;
  confirmedFrom?: string;
  confirmedTo?: string;
  travelFrom?: string;
  travelTo?: string;
  includeChannel: boolean;
  includeDistributor: boolean;
  id: string;
  livnReference: string;
  partyEmailAddress: string;
  partyName: string;
  productIds: string;
  supplierIds: string;
  supplierReference: string;
  order: string;
  orderDescending: boolean;
} = {
  resultsPerPage: 50,
  currentPage: 1,
  anyReference: "",
  clientReference: "",
  id: "",
  livnReference: "",
  partyEmailAddress: "",
  partyName: "",
  productIds: "",
  supplierIds: "",
  confirmedFrom: undefined,
  confirmedTo: undefined,
  travelFrom: undefined,
  travelTo: undefined,
  supplierReference: "",
  order: "",
  orderDescending: false,
  includeChannel: false,
  includeDistributor: false
};

function Search() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(bookingSearchClear());
  }, [dispatch]);
  const [searchForm, setSearchForm] = useState(initialFormState);
  const booking = useSelector(({ booking }) => booking);

  const handleSearchInputChange = useCallback((event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setSearchForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleClearSearchForm = () => setSearchForm(initialFormState);

  return (
    <GridItem colSpan={3}>
      <motion.div variants={divVariants}>
        <Heading as="h3" mb={4} size="lg">
          Get Booking
        </Heading>
        <Tabs isFitted variant="enclosed-colored">
          <TabList mb="1em">
            <Tab>Search Form</Tab>
            <Tab>Query String</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <SearchForm
                form={searchForm}
                handleClearSearchForm={handleClearSearchForm}
                handleInputChange={handleSearchInputChange}
                loading={booking.loading}
              />
            </TabPanel>
            <TabPanel>
              <SearchQueryString form={searchForm} loading={booking.loading} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </motion.div>
    </GridItem>

  );
}

export default Search;
