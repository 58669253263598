import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { DateTime } from "luxon";

function BookingDetails({ booking }) {
  return (
    <Box
      bg="gray.100"
      borderWidth="1px"
      borderRadius="lg"
      color="gray.800"
      my={4}
      p={6}
    >
      <SimpleGrid columns={{sm: 1, md: 2, lg: 3}} spacingX={16} spacingY={4}>
        {booking.id ? (
          <Flex align="flex-start" direction="column" justify="flex-start">
            <Text fontWeight="semibold">id:</Text>
            <Text w="100%">{booking.id}</Text>
          </Flex>
        ) : null}
        {booking.livnReference ? (
          <Flex align="flex-start" direction="column" justify="flex-start">
            <Text fontWeight="semibold">livnReference:</Text>
            <Text w="100%">{booking.livnReference}</Text>
          </Flex>
        ) : null}
        {booking.passThroughReference ? (
          <Flex align="flex-start" direction="column" justify="flex-start">
            <Text fontWeight="semibold">passThroughReference:</Text>
            <Text w="100%">{booking.passThroughReference}</Text>
          </Flex>
        ) : null}
        {booking.partyName ? (
          <Flex align="flex-start" direction="column" justify="flex-start">
            <Text fontWeight="semibold">partyName:</Text>
            <Text w="100%">{booking.partyName}</Text>
          </Flex>
        ) : null}
        {booking.partyEmailAddress ? (
          <Flex align="flex-start" direction="column" justify="flex-start">
            <Text fontWeight="semibold">partyEmailAddress:</Text>
            <Text w="100%">{booking.partyEmailAddress}</Text>
          </Flex>
        ) : null}
        {booking.supplierReference ? (
          <Flex align="flex-start" direction="column" justify="flex-start">
            <Text fontWeight="semibold">supplierReference:</Text>
            <Text w="100%">{booking.supplierReference}</Text>
          </Flex>
        ) : null}
        {booking.confirmed ? (
          <Flex align="flex-start" direction="column" justify="flex-start">
            <Text fontWeight="semibold">confirmed:</Text>
            <Text w="100%">
              {DateTime.fromISO(booking.confirmed).toFormat("yyyy-MM-dd HH:mm")}
            </Text>
          </Flex>
        ) : null}
      </SimpleGrid>
    </Box>
  );
}

export default BookingDetails;
