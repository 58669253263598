import React from "react";

import { CopyIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Flex,
  GridItem,
  Text,
} from "@chakra-ui/react";
import { CopyToClipboard } from "react-copy-to-clipboard";

function Error({ error }) {
  return (
    <GridItem colSpan={6}>
      <Alert status="error" variant="left-accent">
        <AlertIcon />
        <Box flex="1">
          <AlertTitle fontSize="lg">Internal error message</AlertTitle>
          <AlertDescription display="block" my={2}>
            <Flex alignItems="center">
              <CopyToClipboard text={error.internalErrorMessage}>
                <Button
                  colorScheme="blackAlpha"
                  mr={4}
                  size="xs"
                  variant="outline"
                  w="75px"
                >
                  <CopyIcon color="black" />
                </Button>
              </CopyToClipboard>
              <Text>
                {error.internalErrorMessage
                  ? error.internalErrorMessage
                  : "No internal message applied"}
              </Text>
            </Flex>
          </AlertDescription>
          <AlertTitle fontSize="lg">Customer error message</AlertTitle>
          <AlertDescription
            borderBottom="1px"
            borderColor="gray.700"
            display="block"
            my={2}
            pb={3}
          >
            <Flex alignItems="center">
              <CopyToClipboard text={error.customerErrorMessage}>
                <Button
                  colorScheme="blackAlpha"
                  mr={4}
                  size="xs"
                  variant="outline"
                  w="75px"
                >
                  <CopyIcon color="black" />
                </Button>
              </CopyToClipboard>
              <Text>
                {error.customerErrorMessage
                  ? error.customerErrorMessage
                  : "No external message applied"}
              </Text>
            </Flex>
          </AlertDescription>
          <AlertDescription display="block" my={2}>
            <Flex align="flex-start" justify="space-between">
              <Text>Terminate Transaction</Text>
              <Text as="samp">
                {error.terminateTransaction ? "true" : "false"}
              </Text>
              <Text>Go Back To Retry</Text>
              <Text as="samp">{error.goBackToRetry ? "true" : "false"}</Text>
            </Flex>
          </AlertDescription>
        </Box>
      </Alert>
    </GridItem>
  );
}

export default Error;
