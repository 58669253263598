import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Grid,
  GridItem,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import _ from "lodash";
import { DateTime } from "luxon";
import copy from "copy-to-clipboard";

// Components
import Error from "./Components/Error";
import FlowJSON from "./Components/FlowJSON";
import Milestone from "./Components/Milestone";
// Steps
import Bookings from "./Steps/Bookings";
import FareSelection from "./Steps/FareSelection";
import FinalQuote from "./Steps/FinalQuote";
import GetFlow from "./Steps/GetFlow";
import PostFlow from "./Steps/PostFlow";
import PreviewPrice from "./Steps/PreviewPrice";
import Questions from "./Steps/Questions";
import TemporaryHold from "./Steps/TemporaryHold";
// Steps Components
import PostFlowJSON from "./Steps/Components/PostFlowJSON";

import {
  checkoutFlowClear,
  postCheckoutFlow,
  postFareSelection,
  postPreviewPrice,
} from "./checkoutFlowSlice";

import { divVariants } from "../../configs/pageMotions";

function CheckoutFlow() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkoutFlowClear());
  }, [dispatch]);

  const checkoutFlow = useSelector(({ checkoutFlow }) => checkoutFlow);
  const { answers, bookings, errors, flow, loading, preview, step } =
    checkoutFlow;

  const [expired, setExpired] = useState(false);
  const handleSetExpired = (newExpiredValue) => setExpired(newExpiredValue);

  const [form, setForm] = useState([]);

  const handleChangeUIForm = (form) => {
    setForm(form);
  }

  /**
   * FARE_SELECTION
   */
  const [fareSelections, setFareSelection] = useState([]);
  const handleFareChange = (fareId, newValue) => {
    const foundIndex = _.findIndex(fareSelections, { uuid: fareId });
    if (foundIndex >= 0) {
      const newFares = [...fareSelections];
      newFares[foundIndex] = {
        uuid: fareId,
        quantity: fareSelections[foundIndex].quantity + newValue,
      };
      setFareSelection(newFares);
    } else {
      setFareSelection([...fareSelections, { uuid: fareId, quantity: 1 }]);
    }
  };
  const [addOnSelections, setAddOnSelections] = useState([]);
  const handleAddOnChange = (addOnId, newValue) => {
    const foundIndex = _.findIndex(addOnSelections, { uuid: addOnId });
    if (foundIndex >= 0) {
      const newAddOns = [...addOnSelections];
      newAddOns[foundIndex] = {
        uuid: addOnId,
        quantity: addOnSelections[foundIndex].quantity + newValue,
      };
      setAddOnSelections(newAddOns);
    } else {
      setAddOnSelections([...addOnSelections, { uuid: addOnId, quantity: 1 }]);
    }
  };
  const prepareFarePayload = () => {
    return {
      id: flow.id,
      steps: [
        {
          id: step.id,
          fareSelections: !_.isEmpty(fareSelections)
            ? _.filter(fareSelections, (selection) => {
              return selection.quantity > 0;
            })
            : undefined,
          addOnSelections: !_.isEmpty(addOnSelections)
            ? _.filter(addOnSelections, (selection) => {
              return selection.quantity > 0;
            })
            : undefined,
        },
      ],
    };
  };
  const handleClearFaresAddOns = () => {
    setFareSelection([]);
    setAddOnSelections([]);
    setFormAnswers([]);
  };
  const handleCopyFareJSON = () => copy(JSON.stringify(prepareFarePayload()));
  const handleFareSubmission = () => {
    dispatch(postFareSelection(prepareFarePayload()));
  };
  const handlePreviewPrice = () => {
    dispatch(postPreviewPrice(prepareQuestionsPayload()));
  };

  function getBase64(file) {
    if (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
  }


  /**
   * QUESTIONS
   */
  const [formAnswers, setFormAnswers] = useState(answers);

  const handleMultiChange = (elements) => {
    if (elements.length > 0) {
      const name = elements[0].name;
      const value = (elements.map((e) => e.key)).join(',');
      setFormAnswers({
        ...formAnswers,
        [name]: value
      });
    }
  }

  const handleInputChange = async (event) => {
    const target = event.target;
    let value = null;

    if (target.type === 'checkbox') {
      value = target.checked;
    } else if (target.type === 'BOOLEAN') {
      value = target.value === 'true' ? true : false;
    } else if (target.type === 'file') {
      value = await getBase64(target.files[0]);
    } else {
      value = target.value;
    }
    const name = target.name;
    setFormAnswers({
      ...formAnswers,
      [name]: value
    });
  };

  const handleUseExampleValues = () => {
    let answers = {};
    step.questions.questionGroups.forEach((group) =>
      group.questions.forEach((question) => {
        if (question.answerType === "DATE") {
          answers[question.uuid] = new Date(question.example);
        } else if (question.answerType === 'BOOLEAN') {
          answers[question.uuid] = "true";
        } else if (question.answerType !== 'BINARY') {
          answers[question.uuid] = question.example || "";
        }
      })
    );
    // In case the binary field is added before the form is filled with example values
    answers = { ...formAnswers, ...answers };
    setFormAnswers(answers);
  };

  const handleUseFirstPaxValues = () => {
    const answers = { ...formAnswers };
    let firstPaxQuestions = [];
    //find the first pax question group
    const firstPax = _.find(step.questions.questionGroups, (qg => {
      return qg.caption.includes('Passenger');
    }));
    if (firstPax) {
      for (let question of firstPax.questions) {
        if (formAnswers[question.uuid]) {
          firstPaxQuestions.push({
            title: question.title,
            value: formAnswers[question.uuid]
          });
        }
      }
      for (let group of step.questions.questionGroups) {
        for (let question of group.questions) {
          const firstPaxAnswer = _.find(firstPaxQuestions, { title: question.title });
          if (!_.isEmpty(firstPaxAnswer)) {
            if (question.answerType === "DATE" && question.title === firstPaxAnswer.title) {
              answers[question.uuid] = new Date(firstPaxAnswer.value)
            } else if (question.answerType === "SELECT_SINGLE" && question.title === firstPaxAnswer.title) {
              // find the selected value
              const found = _.find(firstPax.questions, { title: question.title });
              const selected = (_.find(found.selectOptions, { uuid: firstPaxAnswer.value })).title;
              //assign selected value to other passengers, find correct uuid first
              const uuid = (_.find(question.selectOptions, { title: selected })).uuid;
              if (uuid) { // checking if the selected option from the first pax is part of the options of the other paxes
                answers[question.uuid] = uuid;
              }
            } else if (question.title === firstPaxAnswer.title && question.answerType !== "BINARY") {
              answers[question.uuid] = firstPaxAnswer.value
            }
          }
        }
      }
    }
    setFormAnswers(answers);
  };

  /**
   * POST FLOW with questions
   */
  const prepareQuestionsPayload = () => {
    const answers = [];
    for (const [key, value] of Object.entries(formAnswers)) {
      const answer = {
        questionUuid: key,
        value: _.isDate(value)
          ? DateTime.fromJSDate(value).toFormat("yyyy-MM-dd")
          : value,
      };
      if (value) {
        answers.push(answer);
      }
    }
    const steps = [];
    flow.steps.forEach((flowStep) => {
      if (flowStep.id === step.id) {
        steps.push({
          id: step.id,
          answers: {
            answers,
          },
          fareDetails: undefined,
          fareSelections:
            step.milestone === "FARE_SELECTION" && !_.isEmpty(fareSelections)
              ? _.filter(fareSelections, (selection) => {
                return selection.quantity > 0;
              })
              : undefined,
          addOnSelections:
            step.milestone === "FARE_SELECTION" && !_.isEmpty(addOnSelections)
              ? _.filter(addOnSelections, (selection) => {
                return selection.quantity > 0;
              })
              : undefined,
          questions: undefined,
        });
      }
    });
    return { id: flow.id, steps };
  };
  const handleCopyQuestionsJSON = () =>
    copy(JSON.stringify(prepareQuestionsPayload()));
  const handlePostCheckoutFlow = () => {
    handleClearFaresAddOns();
    dispatch(postCheckoutFlow(prepareQuestionsPayload()));
  };

  /**
   * POST FLOW without submitting extra data
   */
  const handleNextCheckoutFlow = () =>
    dispatch(
      postCheckoutFlow({
        id: flow.id,
        steps: flow.steps,
      })
    );

  /**
   * POST FLOW with back
   */
  const handleBackCheckoutFlow = () => {
    handleClearFaresAddOns();
    dispatch(postCheckoutFlow({ id: flow.id }, "BACK"));
  };

  return (
    <motion.div initial="exit" animate="enter" exit="exit">
      <Grid templateColumns="repeat(6, 1fr)" gap={12} w="100%">
        <GridItem colSpan={4}>
          <motion.div variants={divVariants}>
            <Heading as="h3" mb={4} size="lg">
              Checkout Flow
            </Heading>
            <Tabs isFitted variant="enclosed-colored">
              <TabList mb="1em">
                <Tab>Post Flow Form</Tab>
                <Tab>JSON</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <PostFlow
                    handleClearFaresAddOns={handleClearFaresAddOns}
                    loading={loading}
                    handleChangeUIForm={handleChangeUIForm}
                  />
                </TabPanel>
                <TabPanel>
                  <PostFlowJSON
                    handleClearFaresAddOns={handleClearFaresAddOns}
                    loading={loading}
                    formDefault={form}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </motion.div>
        </GridItem>

        <GetFlow loading={loading} />

        {!_.isEmpty(flow) ? <FlowJSON flow={flow} /> : null}

        {!_.isEmpty(errors)
          ? errors.map((err, index) =>
            !_.isEmpty(err) ? <Error error={err} key={index} /> : null
          )
          : null}

        {!_.isEmpty(step) ? (
          <GridItem colSpan={6}>
            <motion.div variants={divVariants}>
              <Milestone handleSetExpired={handleSetExpired} step={step} />
              {step.milestone === "FARE_SELECTION" ? (
                <FareSelection
                  addOnSelections={addOnSelections}
                  checkoutFlow={checkoutFlow}
                  fareSelections={fareSelections}
                  handleAddOnChange={handleAddOnChange}
                  handleBackCheckoutFlow={handleBackCheckoutFlow}
                  handleCopyFareJSON={handleCopyFareJSON}
                  handleFareChange={handleFareChange}
                  handleFareSubmission={handleFareSubmission}
                  handlePreviewPrice={handlePreviewPrice}
                />
              ) : null}
              {!_.isEmpty(step.questions) ? (
                <Questions
                  checkoutFlow={checkoutFlow}
                  expired={expired}
                  fareSelections={fareSelections}
                  formAnswers={formAnswers}
                  handleBackCheckoutFlow={handleBackCheckoutFlow}
                  handleCopyQuestionsJSON={handleCopyQuestionsJSON}
                  handleInputChange={handleInputChange}
                  handleMultiChange={handleMultiChange}
                  handlePostCheckoutFlow={handlePostCheckoutFlow}
                  handlePreviewPrice={handlePreviewPrice}
                  handleUseExampleValues={handleUseExampleValues}
                  handleUseFirstPaxValues={handleUseFirstPaxValues}
                  movement={{
                    nextStepSequenceNumber: step.nextStepSequenceNumber
                  }}
                />
              ) : null}

              {!_.isEmpty(preview) ? (
                <PreviewPrice previewPrice={preview} />
              ) : null}

              {step.milestone === "FINAL_QUOTE" &&
                !_.isEmpty(step.finalQuote) ? (
                <FinalQuote
                  enableButtons={_.isEmpty(step.questions)}
                  expired={expired}
                  finalQuote={step.finalQuote}
                  handleBackCheckoutFlow={handleBackCheckoutFlow}
                  handleNextCheckoutFlow={handleNextCheckoutFlow}
                  movement={{
                    nextStepSequenceNumber: step.nextStepSequenceNumber,
                  }}
                />
              ) : null}

              {step.milestone === "TEMPORARY_HOLD" ? (
                <TemporaryHold
                  enableButtons={_.isEmpty(step.questions)}
                  expired={expired}
                  step={step}
                  handleBackCheckoutFlow={handleBackCheckoutFlow}
                  handleNextCheckoutFlow={handleNextCheckoutFlow}
                />
              ) : null}
            </motion.div>
          </GridItem>
        ) : null}

        {!_.isEmpty(bookings) ? (
          <GridItem colSpan={6}>
            <motion.div variants={divVariants}>
              <Bookings bookings={bookings} />
            </motion.div>
          </GridItem>
        ) : null}
      </Grid>
    </motion.div>
  );
}

export default CheckoutFlow;
