import React from "react";

import {
  Box,
  Table,
  Heading,
  Thead,
  Th,
  Tooltip,
  Tr,
  Td,
  Tbody,
} from "@chakra-ui/react";
import { formatDateTime } from "../../features/dateUtils";

function Cancellations({ cancellations }) {
  const originDescription = {
    api: "Booking was cancelled through the API, where it went through the upstream reservation system.",
    internal: "Booking was cancelled internally by Livn, where like the API, also went through the upstream reservation system.",
    manual: "Booking was merely marked as cancelled. This happens in cases where the supplier has already cancelled the booking on their end."
  }

  return (
    <Box
      bg="gray.100"
      borderWidth="1px"
      borderRadius="lg"
      color="gray.800"
      my={4}
      p={6}
    >
      <Heading as="h6" mb={4} size="sm">
        Cancellations
      </Heading>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Created</Th>
            <Th>Confirmed</Th>
            <Th>Reason</Th>
            <Th>User</Th>
            <Th>Cancelled By Supplier</Th>
            <Th>Origin</Th>
          </Tr>
        </Thead>
        <Tbody>
          {cancellations.map(({ confirmed, created, reason, userInfo, id, cancelledBySupplier, origin }) => (
            <Tr key={id}>
              <Td>{created && formatDateTime(created)}</Td>
              <Td>{confirmed && formatDateTime(confirmed)}</Td>
              <Td>{reason}</Td>
              <Td>
                {userInfo ? (
                  <Tooltip label={`User id: ${userInfo.id}`}>
                    {userInfo.loginName}
                  </Tooltip>
                ) : origin !== "API" ? 'Livn User' : 'Not Available'}
              </Td>
              <Td>
                <Tooltip
                  label="Indicates whether the cancellation has been made on the supplier's end and not through the upstream reservation system."
                >
                  {cancelledBySupplier ? "true" : "false"}
                </Tooltip>
              </Td>
              <Td>
                <Tooltip label={
                  origin === "INTERNAL"
                    ? originDescription.internal
                    : origin === "MANUAL"
                      ? originDescription.manual
                      : originDescription.api
                }>
                  {origin}
                </Tooltip>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}

export default Cancellations;
