import { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import imagePlaceholder from "../../../resources/no-image.png";

import { CheckCircleIcon, InfoIcon, TimeIcon } from "@chakra-ui/icons";
import {
  Button as ChakraButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  List,
  ListItem,
  ListIcon,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import _ from "lodash";

import { divVariants } from "../../../configs/pageMotions";

import { getProduct, getProductDepartures } from "../productSlice";

import Loading from "../../../components/Loading/Loading";
import Duration from "../Components/Duration";
import SourceDetails from "../Components/SourceDetails";
import Locations from "./Components/Locations";
import Departures from "./Components/Departures";
import JSONDisplay from "../../../components/JSONDisplay";
import { useSelector } from "../../../app/store";
import { useParams } from "react-router-dom";
import {
  Link as ReactRouterLink,
  LinkProps as RouterLinkProps
} from "react-router-dom";


import { ButtonProps } from "@chakra-ui/react";

function ProductDetails() {
  let { productId } = useParams<any>();
  const product = useSelector(({ product }) => product);
  const { departures, details, loading } = product;

  const [imageId, setImageId] = useState(0);
  const handleImageChange = (newId) => setImageId(newId);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProduct(productId));
  }, [dispatch, productId]);

  const handleGetDepartures = () => dispatch(getProductDepartures(details?.id, true));

  type LinkButtonProps = ButtonProps & RouterLinkProps;

  const LinkButton: React.FC<LinkButtonProps> = forwardRef(
    (props: LinkButtonProps, ref: React.Ref<any>) => {
      return <ChakraButton _hover={{
        bg: "blue.600",
      }}
        align="center"
        bg="blue.500"
        borderRadius="md"
        color="white"
        px={4}
        py={2.5}
        ref={ref} as={ReactRouterLink} {...props} />;
    }
  );


  return !!details ? (
    <motion.div initial="exit" animate="enter" exit="exit">
      <motion.div variants={divVariants}>
        <Box mb={4}>
          <Heading as="h2" fontWeight="semibold" pb="2.5" size="2xl">
            {details.name}
          </Heading>
          <HStack justify="space-between">
            <Text color="gray.800" fontSize="sm">
              id: {details.id}
            </Text>
            {details.v1Cid ? (
              <Text color="gray.800" fontSize="sm">
                v1Cid: {details.v1Cid}
              </Text>
            ) : null}
            <Text color="gray.800" fontSize="sm">
              demo: {details.demo ? "true" : "false"}
            </Text>
            {!_.isEmpty(details.categories) ? (
              <Flex>
                <Text color="gray.800" mr={2}>
                  Categories:{" "}
                </Text>
                <Text color="gray.900">
                  {" "}
                  {details.categories.map((category, index) => (
                    <span key={index}>
                      {category.name}
                      {index < details.categories.length - 1 ? ", " : null}
                    </span>
                  ))}
                </Text>
              </Flex>
            ) : null}
          </HStack>
        </Box>
      </motion.div>

      <motion.div variants={divVariants}>
        <Tabs isFitted variant="enclosed-colored">
          <TabList mb="1em">
            <Tab>Details</Tab>
            <Tab>JSON</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Grid alignItems="top" gap={4} templateColumns="repeat(12, 1fr)">
                <GridItem colSpan={2}>
                  <VStack
                    h="490px"
                    overflowX="hidden"
                    overflowY="auto"
                    spacing={2}
                  >
                    {!_.isEmpty(details.images)
                      ? details.images.map((image, index) => (
                        <Box key={index}>
                          <Image
                            alt={details.name}
                            border="2px"
                            borderColor={
                              index === imageId ? "blue.500" : "gray.500"
                            }
                            onClick={() => handleImageChange(index)}
                            src={image.url}
                            w="90%"
                          />
                          <Text color="gray.800" fontSize="xs">
                            {image.width} x {image.height}
                          </Text>
                        </Box>
                      ))
                      : <Box key={details.name}>
                        <Image
                          alt={details.name}
                          border="2px"
                          borderColor={"blue.500"}
                          src={imagePlaceholder}
                          w="90%"
                        />
                      </Box>}
                  </VStack>
                </GridItem>
                <GridItem colSpan={7}>
                  {!_.isEmpty(details.images) &&
                    !_.isEmpty(details.images[imageId]) ? (
                    <Box
                      backgroundImage={`url(${details.images[imageId].url})`}
                      backgroundPosition="center"
                      backgroundRepeat="no-repeat"
                      backgroundSize="cover"
                      h="100%"
                      w="100%"
                    />
                  ) : <Box
                    backgroundImage={imagePlaceholder}
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                    h="100%"
                    w="100%"
                  />}
                </GridItem>
                <GridItem colSpan={3}>
                  <VStack
                    alignItems="inherit"
                    h="100%"
                    justifyContent="space-between"
                    w="100%"
                  >
                    <SimpleGrid
                      border="1px"
                      borderColor="gray.400"
                      gap={2}
                      h="100%"
                      mb={4}
                      p={4}
                    >
                      <SourceDetails details={details} />
                    </SimpleGrid>
                    <Box bg="gray.400" p={4}>
                      {!_.isEmpty(details.fromPrices) ? (
                        <Box mb={2}>
                          <Text fontSize="md" fontWeight="semibold">
                            From
                          </Text>
                          {details.fromPrices.map((price, priceIndex) => {
                            let priceString = price.amount.toLocaleString("en-au", {
                              style: "currency",
                              currency: price.currency,
                              currencyDisplay: "code"
                            })

                            let s = priceString;
                            let i = s.indexOf(' ');
                            let currencyCode = s.slice(0, i).trim();
                            let amount = s.slice(i + 1, s.length).trim();

                            return !_.isEmpty(price) ? (
                              <Box display="flex" justifyContent="space-between" fontSize="sm" key={priceIndex} pr={2}>
                                <span>{currencyCode}</span><span>{amount}</span>
                              </Box>
                            ) : null
                          })}
                        </Box>
                      ) : null}
                      {details.commissionPerc ? (
                        <HStack>
                          <Text color="gray.700" fontSize="sm">
                            Commission Perc
                          </Text>
                          <Text color="gray.900" fontSize="sm">
                            {details.commissionPerc}%
                          </Text>
                        </HStack>
                      ) : null}
                      <HStack>
                        <Text color="gray.700" fontSize="sm">
                          Net Rates
                        </Text>
                        <Text color="gray.900" fontSize="sm">
                          {details.usesNetRates ? "true" : "false"}
                        </Text>
                        {details.netRatesMarkupPerc ? (
                          <Text color="gray.900" fontSize="sm">
                            {details.netRatesMarkupPerc}%
                          </Text>
                        ) : null}
                      </HStack>
                      <Button
                        colorScheme="blue"
                        mt={4}
                        isLoading={loading === "pending"}
                        loadingText="Loading"
                        onClick={() => handleGetDepartures()}
                        w="100%"
                      >
                        Get Departures
                      </Button>
                    </Box>
                  </VStack>
                </GridItem>
                {departures && product.loading !== "searchingDepartures" ? (
                  <GridItem colSpan={12}>
                    <Departures
                      departures={departures}
                      productId={details.id}
                    />
                  </GridItem>
                ) :
                  product.loading === "searchingDepartures" ?
                    <Loading text="Loading departures..." /> : null}
                <GridItem colSpan={12}>
                  <SimpleGrid
                    borderBottom="1px"
                    borderBottomColor="gray.500"
                    borderTop="1px"
                    borderTopColor="gray.500"
                    columns={4}
                    p={4}
                    spacing={2}
                  >
                    {details.duration ? (
                      <Duration
                        duration={details.duration}
                        durationStr={details.durationStr}
                        durationRangeMax={details.durationRangeMax}
                      />
                    ) : null}
                    {details.timeStart ? (
                      <HStack>
                        <TimeIcon color="gray.700" />
                        {details.timeStart ? (
                          <Box>
                            <Text color="gray.700" fontSize="sm">
                              Start Time
                            </Text>
                            <Text color="gray.700" fontWeight="semibold">
                              {details.timeStart}
                            </Text>
                          </Box>
                        ) : null}
                        {details.timeStartRangeMax ? (
                          <Box>
                            <Text color="gray.700" fontSize="sm">
                              Start Time Range Max
                            </Text>
                            <Text color="gray.700" fontWeight="semibold">
                              {details.timeStartRangeMax}
                            </Text>
                          </Box>
                        ) : null}
                      </HStack>
                    ) : null}
                    {!_.isEmpty(details.operatingLanguages) ? (
                      <Text color="gray.700">
                        {details.operatingLanguages.map(
                          (language, index) => language
                        )}
                      </Text>
                    ) : null}
                    {details.groupSizeMax ? (
                      <Box>
                        <Text color="gray.700" fontSize="sm">
                          Group Size Max
                        </Text>
                        <Text color="gray.700" fontWeight="semibold">
                          {details.groupSizeMax}
                        </Text>
                      </Box>
                    ) : null}
                    {details.ageMin || details.ageMax ? (
                      <Box>
                        <Text color="gray.700" fontSize="sm">
                          {details.ageMin && details.ageMax
                            ? null
                            : details.ageMin
                              ? "Min "
                              : "Max "}
                          Age
                          {details.ageMin && details.ageMax ? " Range" : null}
                        </Text>
                        <Text color="gray.700" fontWeight="semibold">
                          {details.ageMin}
                          {details.ageMin && details.ageMax ? " - " : null}
                          {details.ageMax}
                        </Text>
                      </Box>
                    ) : null}
                    {details.operatingSchedule ? (
                      <GridItem colSpan={4}>
                        <Text color="gray.700">
                          {details.operatingSchedule}
                        </Text>
                      </GridItem>
                    ) : null}
                  </SimpleGrid>
                </GridItem>
                <GridItem colSpan={12}>
                  <Box mb={4} px={4}>
                    <Heading as="h3" fontWeight="normal" mb={4} size="lg">
                      Description
                    </Heading>
                    {details.description ? (
                      <Text color="gray.900" mb={8} whiteSpace="pre-wrap">
                        {details.description}
                      </Text>
                    ) : null}
                    <Heading as="h4" fontWeight="normal" my={2} size="md">
                      Highlights
                    </Heading>
                    {!_.isEmpty(details.highlights) &&
                      !_.isEmpty(details.highlights.highlights) ? (
                      <List mt={4} spacing={2}>
                        {details.highlights.highlights.map(
                          (highlight, index) => (
                            <ListItem color="gray.900" key={index}>
                              <ListIcon
                                as={CheckCircleIcon}
                                color="green.500"
                              />{" "}
                              {highlight}
                            </ListItem>
                          )
                        )}
                      </List>
                    ) : null}
                  </Box>
                </GridItem>
                <GridItem colSpan={12}>
                  <Accordion allowMultiple allowToggle>
                    {!_.isEmpty(details.inclusions) &&
                      !_.isEmpty(details.inclusions.items) ? (
                      <AccordionItem>
                        <AccordionButton>
                          <Heading as="h3" fontWeight="normal" my={2} size="lg">
                            Inclusions
                          </Heading>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel mb={4}>
                          <List spacing={2}>
                            {details.inclusions.items.map(
                              (inclusion, index) => (
                                <ListItem color="gray.900" key={index}>
                                  <HStack>
                                    <ListIcon
                                      as={CheckCircleIcon}
                                      color="green.500"
                                    />
                                    <Text color="gray.800">
                                      {inclusion.type}
                                    </Text>
                                    <Text color="gray.900">
                                      {inclusion.content}
                                    </Text>
                                  </HStack>
                                </ListItem>
                              )
                            )}
                          </List>
                        </AccordionPanel>
                      </AccordionItem>
                    ) : null}

                    {!_.isEmpty(details.itinerary) &&
                      !_.isEmpty(details.itinerary.items) ? (
                      <AccordionItem>
                        <AccordionButton>
                          <Heading as="h3" fontWeight="normal" my={2} size="lg">
                            Itinerary
                          </Heading>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel mb={4}>
                          <List
                            borderLeft="1px"
                            borderLeftColor="gray.500"
                            borderLeftStyle="dashed"
                            spacing={4}
                          >
                            {details.itinerary.items.map((itinerary, index) => (
                              <ListItem key={index}>
                                <HStack align="flex-start">
                                  <ListIcon
                                    as={InfoIcon}
                                    bg="white"
                                    color="green.500"
                                    ml={-2}
                                    mt={1.5}
                                  />
                                  <Box>
                                    <Heading
                                      as="h5"
                                      fontWeight="normal"
                                      size="md"
                                    >
                                      {itinerary.title}
                                    </Heading>
                                    <Text color="gray.800" fontSize="sm">
                                      {itinerary.timeFrom}
                                      {itinerary.dayFrom
                                        ? "Day " + itinerary.dayFrom
                                        : null}
                                      {(itinerary.timeTo &&
                                        itinerary.timeTo !==
                                        itinerary.timeFrom) ||
                                        (itinerary.dayTo &&
                                          itinerary.dayTo !== itinerary.dayFrom)
                                        ? " to "
                                        : null}
                                      {itinerary.timeTo &&
                                        itinerary.timeTo !== itinerary.timeFrom
                                        ? itinerary.timeFrom
                                        : null}{" "}
                                      {itinerary.dayTo &&
                                        itinerary.dayTo !== itinerary.dayFrom
                                        ? "Day " + itinerary.dayTo
                                        : null}
                                    </Text>
                                    <Text
                                      color="gray.900"
                                      mt={1}
                                      whiteSpace="pre-wrap"
                                    >
                                      {itinerary.body}
                                    </Text>
                                  </Box>
                                </HStack>
                              </ListItem>
                            ))}
                          </List>
                        </AccordionPanel>
                      </AccordionItem>
                    ) : null}

                    {!_.isEmpty(details.locationsEnd) ||
                      !_.isEmpty(details.locationsStart) ? (
                      <Locations
                        locationsEnd={details.locationsEnd}
                        locationsStart={details.locationsStart}
                      />
                    ) : null}

                    <AccordionItem>
                      <AccordionButton>
                        <Heading as="h3" fontWeight="normal" my={2} size="lg">
                          Additional Info
                        </Heading>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel mb={-4}>
                        {details.pickupNotes ? (
                          <Box mb={8}>
                            <Heading
                              as="h4"
                              fontWeight="normal"
                              my={2}
                              size="md"
                            >
                              Pick-up Notes
                            </Heading>
                            <Text color="gray.900" whiteSpace="pre-wrap">
                              {details.pickupNotes}
                            </Text>
                          </Box>
                        ) : null}
                        {details.dropoffNotes ? (
                          <Box mb={8}>
                            <Heading
                              as="h4"
                              fontWeight="normal"
                              my={2}
                              size="md"
                            >
                              Drop-off Notes
                            </Heading>
                            <Text color="gray.900" whiteSpace="pre-wrap">
                              {details.dropoffNotes}
                            </Text>
                          </Box>
                        ) : null}
                        {details.specialNotes ? (
                          <Box mb={8}>
                            <Heading
                              as="h4"
                              fontWeight="normal"
                              my={2}
                              size="md"
                            >
                              Special Notes
                            </Heading>
                            <Text color="gray.900" whiteSpace="pre-wrap">
                              {details.specialNotes}
                            </Text>
                          </Box>
                        ) : null}
                        {details.redemptionNotes ? (
                          <Box mb={8}>
                            <Heading
                              as="h4"
                              fontWeight="normal"
                              my={2}
                              size="md"
                            >
                              Redemption Notes
                            </Heading>
                            <Text color="gray.900" whiteSpace="pre-wrap">
                              {details.redemptionNotes}
                            </Text>
                          </Box>
                        ) : null}
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </GridItem>
              </Grid>
            </TabPanel>
            <TabPanel>
              <JSONDisplay jsonToDisplay={details} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </motion.div>
    </motion.div>
  ) : (
    product.loading === "searching" ?
      <Loading text="Loading product..." /> :
      <LinkButton to="/products">
        Return to Get Product
      </LinkButton>

  );
}
export default ProductDetails;
