import React from "react";

import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import _ from "lodash";

function Fare({ fare }) {
  return (
    <Box>
      <Text as="samp" color="gray.500" fontSize="xs">
        fare
        <Tooltip label="The third and highest level of differentiation of top-level products into the ultimately bookable product variants (the other being BaseVariants and TimeSlots). Each Fare identifies the bookable pax or ticket type within a specific TimeSlot and thus BaseVariant of the top-level Product. Important Note: The absense of any of the various optional fields does not necessarily mean that there is no such applicable limitation, as we can only pass on machine-readble values we receive from our upstream reservation systems in an equally reliable and machine-readable format.">
          <InfoOutlineIcon color="gray.500" ml={2} />
        </Tooltip>
      </Text>

      {fare.name ? (
        <Flex align="flex-start" justify="space-between">
          <Text color="gray.800">Name</Text>
          <Text fontWeight="semibold">{fare.name}</Text>
        </Flex>
      ) : null}

      {fare.description ? (
        <Box my={1}>
          <Text color="gray.800" fontSize="sm">
            Description
          </Text>
          <Text fontSize="sm">{fare.description}</Text>
        </Box>
      ) : null}

      {_.isNumber(fare.unitsAvailable) ? (
        <Flex align="flex-start" justify="space-between">
          <Text color="gray.800">Units available</Text>
          <Text>{fare.unitsAvailable}</Text>
        </Flex>
      ) : null}

      {(fare.unitsMin && fare.unitsMin >= 1) ||
      (fare.unitsMax && fare.unitsMax >= 1) ? (
        <Flex align="flex-start" justify="space-between">
          <Text color="gray.800">
            Units
            {_.isNumber(fare.unitsMin) ? " Min " : null}
            {(fare.unitsMin >= 0) && fare.unitsMax ? "/" : null}
            {_.isNumber(fare.unitsMax) ? " Max " : null}
          </Text>
          <Text>
            {_.isNumber(fare.unitsMin) ? fare.unitsMin : null}
            {(fare.unitsMin >= 0) && fare.unitsMax ? "/" : null}
            {_.isNumber(fare.unitsMax) ? fare.unitsMax : null}
          </Text>
        </Flex>
      ) : null}

      {fare.unitsMultipleOf ? (
        <Flex align="flex-start" justify="space-between">
          <Text color="gray.800">Units Multiple Of</Text>
          <Text>{fare.unitsMultipleOf}</Text>
        </Flex>
      ) : null}

      {fare.ageMin || fare.ageMax ? (
        <Flex align="flex-start" justify="space-between">
          <Text color="gray.800">
            Age
            {_.isNumber(fare.ageMin) ? " Min " : null}
            {_.isNumber(fare.ageMin) && _.isNumber(fare.ageMax) ? "-" : null}
            {_.isNumber(fare.ageMax) ? " Max " : null}
          </Text>
          <Text>
            {_.isNumber(fare.ageMin) ? fare.ageMin : null}
            {_.isNumber(fare.ageMin) && _.isNumber(fare.ageMax) ? " - " : null}
            {_.isNumber(fare.ageMax) ? fare.ageMax : null}
          </Text>
        </Flex>
      ) : null}

      {fare.ageQualified ? (
        <Flex align="flex-start" justify="space-between">
          <Text color="gray.800">Age Qualified</Text>
          <Text>{fare.ageQualified}</Text>
        </Flex>
      ) : null}

      {!_.isEmpty(fare.price) ? (
        <Flex align="flex-start" justify="space-between">
          <Text color="gray.800">Price</Text>
          <Text>
            {fare.price.currency ? fare.price.currency : null}{" "}
            {_.isNumber(fare.price.amount) ? fare.price.amount : null}
          </Text>
        </Flex>
      ) : null}

      {fare.specialNotes ? (
        <Box my={1}>
          <Text color="gray.800" fontSize="sm">
            Special Notes
          </Text>
          <Text fontSize="sm">{fare.specialNotes}</Text>
        </Box>
      ) : null}

      {fare.otherCharges ? (
        <Box my={1}>
          <Text color="gray.800" fontSize="sm">
            Other Charges
          </Text>
          <Text fontSize="sm">{fare.otherCharges}</Text>
        </Box>
      ) : null}
    </Box>
  );
}

export default Fare;
