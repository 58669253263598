import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormLabel,
  GridItem,
  Tooltip,
  Heading,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

import { deleteBooking } from "./bookingSlice";

import { divVariants } from "../../configs/pageMotions";

function CancelBooking({ loading, bookingId, onClose }) {
  const [form, setForm] = useState({
    cancelBookingId: bookingId ? bookingId : "",
    reason: ""
  });
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const dispatch = useDispatch();
  const handleCancelBooking = () => {
    dispatch(deleteBooking(form));
    //close the modal
    onClose();
  }

  return (
    <GridItem colSpan={2}>
      <motion.div variants={divVariants}>
        <Heading as="h3" mb={4} size="lg">
          Cancel Booking
        </Heading>
        <SimpleGrid columns={1} spacing={4}>
          <FormControl id="cancelBookingId" isRequired={true}>
            <FormLabel>Booking id</FormLabel>
            <Input
              name="cancelBookingId"
              onChange={handleInputChange}
              value={form.cancelBookingId}
              w="100%"
            />
          </FormControl>
          <FormControl id="reason" isRequired={true}>
            <FormLabel>Reason
              <Tooltip label="Reason for cancellation.">
                <InfoOutlineIcon color="gray.800" ml={2} />
              </Tooltip>
            </FormLabel>
            <Input
              name="reason"
              onChange={handleInputChange}
              value={form.reason}
              w="100%"
            />
          </FormControl>
          <Button
            colorScheme="red"
            disabled={form.cancelBookingId && form.reason ? false : true}
            isLoading={loading === "cancelling"}
            loadingText="Cancelling"
            mt={4}
            onClick={() => handleCancelBooking()}
            w="100%"
          >
            Cancel
          </Button>
        </SimpleGrid>
      </motion.div>
    </GridItem>
  );
}

export default CancelBooking;
