import React from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import _ from "lodash";

import { getTickets } from "./ticketSlice";

import JSONDisplay from "../../components/JSONDisplay";
import PaginationButtons from "../../components/Pagination/PaginationButtons";
import Pagination from "../../components/Pagination/Pagination";
import TicketItem from "../../components/Tickets/TicketItem";

function SearchResults({ searchResults }) {
  const { paging } = searchResults;

  const dispatch = useDispatch();
  const handlePageChange = (page) => {
    dispatch(
      getTickets({
        id: searchResults.id,
        resultsPerPage: searchResults.paging.resultsPerPage,
        currentPage: page,
      })
    );
  };

  return (
    <Box>
      {searchResults.paging ? (
        <Heading as="h3" mb={4} size="lg">
          Results: {searchResults.paging.resultsTotal} total
        </Heading>
      ) : null}
      <Tabs isFitted variant="enclosed-colored">
        <TabList mb="1em">
          {searchResults.paging ? (
            <Tab>
              {searchResults.paging.resultsPerPage} results per page /{" "}
              {searchResults.paging.pagesTotal} page(s)
            </Tab>
          ) : (
            <Tab>Tickets</Tab>
          )}
          <Tab>JSON</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {searchResults.paging ? (
              <Pagination
                currentPage={paging.currentPage}
                handlePageChange={handlePageChange}
                pagesTotal={paging.pagesTotal}
              >
                <PaginationButtons
                  currentPage={paging.currentPage}
                  handlePageChange={handlePageChange}
                  pagesTotal={paging.pagesTotal}
                />
              </Pagination>
            ) : null}
            {!_.isEmpty(searchResults.ticket) ? (
              <TicketItem
                ticket={searchResults.ticket}
                key={searchResults.ticket.id}
              />
            ) : null}
            {!_.isEmpty(searchResults.tickets)
              ? searchResults.tickets.map((result) => (
                <TicketItem ticket={result} key={result.id} />
              ))
              : null}
            {searchResults.paging ? (
              <Pagination
                currentPage={paging.currentPage}
                handlePageChange={handlePageChange}
                pagesTotal={paging.pagesTotal}
              >
                <PaginationButtons
                  currentPage={paging.currentPage}
                  handlePageChange={handlePageChange}
                  pagesTotal={paging.pagesTotal}
                />
              </Pagination>
            ) : null}
          </TabPanel>
          <TabPanel>
            <JSONDisplay
              jsonToDisplay={
                !_.isEmpty(searchResults.tickets)
                  ? searchResults.tickets
                  : !_.isEmpty(searchResults.tickets)
                    ? searchResults.tickets
                    : {}
              }
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default SearchResults;
