import React, { useState, useEffect, memo } from "react";
import { useDispatch } from "react-redux";

import { CopyIcon } from "@chakra-ui/icons";
import { Box, Button, SimpleGrid, Text, Textarea } from "@chakra-ui/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getTickets } from "../ticketSlice";

function SearchQueryString({ form, loading }) {

  const [queryString, setQueryString] = useState("");

  const handleInputChange = (event) => {
    setQueryString(event.target.value);
  };

  //use the forms data to determine JSON
  useEffect(() => {
    //get fields with values
    let temp = {}
    for (const key in form) {
      if (form[key] !== "" && form[key] !== null && form[key] !== undefined) {
        temp[key] = form[key];
      }
    }
    const query = Object.keys(temp).map(key => key + '=' + temp[key]).join('&');
    setQueryString(query);
  }, [form]);

  const dispatch = useDispatch();
  const handleGetTickets = () => {
    const query = JSON.parse('{"' + queryString.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) });
    dispatch(getTickets(query));
  };

  return (
    <Box>
      <Text mb={4}>
        If you'd rather not fill out the form and type the query string directly, you may
        enter it here.
      </Text>
      <Textarea
        h="405px"
        name="Query String"
        onChange={handleInputChange}
        resize="vertical"
        w="100%"
        value={queryString}
      />
      <SimpleGrid columns={2} spacing={2}>
        <CopyToClipboard text={queryString}>
          <Button mt={4} variant="outline" w="100%">
            <CopyIcon color="gray" />
          </Button>
        </CopyToClipboard>
        <Button
          colorScheme="green"
          isLoading={loading === "pending"}
          loadingText="Posting"
          mt={4}
          onClick={() => handleGetTickets()}
          w="100%"
        >
          Search
        </Button>
      </SimpleGrid>
    </Box>
  );
}

export default memo(SearchQueryString);
