import { Box, Button, SimpleGrid } from "@chakra-ui/react";
import _ from "lodash";

import QuoteInvoice from "../../../components/QuoteInvoice/QuoteInvoice";
import CancellationPolicy from "./Components/CancellationPolicy";
import SupplierTncs from "./Components/SupplierTncs";

function FinalQuote({
  enableButtons,
  expired,
  finalQuote,
  handleBackCheckoutFlow,
  handleNextCheckoutFlow,
  loading,
  movement: { nextStepSequenceNumber },
}) {
  return (
    <Box>
      <QuoteInvoice details={{ ...finalQuote, title: "Final Quote Details" }} />
      {!_.isEmpty(finalQuote.generalTerms) ? (
        <SupplierTncs generalTerms={finalQuote.generalTerms} />
      ) : null}
      {!_.isEmpty(finalQuote.cancellationPolicy) ? (
        <CancellationPolicy cancellationPolicy={finalQuote.cancellationPolicy} />
      ) : null}
      {enableButtons ? (
        <SimpleGrid columns={2} spacing={2}>
          <Button
            colorScheme={expired ? "red" : "gray"}
            isLoading={loading === "pending"}
            loadingText="Loading"
            mt={2}
            onClick={() => handleBackCheckoutFlow()}
            variant="outline"
            w="100%"
          >
            Back
          </Button>
          {nextStepSequenceNumber ? (
            <Button
              colorScheme={expired ? "red" : "green"}
              isLoading={loading === "pending"}
              loadingText="Posting"
              mt={2}
              onClick={() => handleNextCheckoutFlow()}
              w="100%"
            >
              Next
            </Button>
          ) : null}
        </SimpleGrid>
      ) : null}
    </Box>
  );
}

export default FinalQuote;
