import React from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Grid,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";
import { DateTime } from "luxon";
import JSONDisplay from "../../../../components/JSONDisplay";

function Departures({ productId, departures: { departures } }) {
  return (
    <Box bg="gray.400" h="50vh" overflowY="auto">
      <Tabs isFitted>
        <TabList mb="1em">
          <Tab>Departures</Tab>
          <Tab>JSON</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Grid
              gap={4}
              templateColumns={[
                "repeat(1, 1fr)",
                "repeat(2, 1fr)",
                "repeat(3, 1fr)",
                "repeat(4, 1fr)"
              ]}
            >
              {!_.isEmpty(departures)
                ? departures.map((departure, departureIndex) => (
                  <Box
                    bg="white"
                    border="1px"
                    borderColor="gray.400"
                    borderRadius="md"
                    key={departureIndex}
                    p={4}
                  >
                    <Text fontSize="md" fontWeight="semibold">
                      {DateTime.fromISO(departure.date).toFormat(
                        "yyyy-MM-dd"
                      )}
                    </Text>
                    <Grid
                      gap={2}
                      templateColumns={[
                        "repeat(1, 1fr)",
                        "repeat(2, 1fr)"
                      ]}
                    >
                      {departure.fromPrices.map((price, priceIndex) => {
                        let priceString = price.amount.toLocaleString("en-au", {
                          style: "currency",
                          currency: price.currency,
                          currencyDisplay: "code"
                        })

                        let s = priceString;
                        let i = s.indexOf(' ');
                        let currencyCode = s.slice(0, i).trim();
                        let amount = s.slice(i + 1, s.length).trim();

                        return !_.isEmpty(price) ? (
                          <Box display="flex" justifyContent="space-between" fontSize="sm" key={priceIndex} pr={2}>
                            <span>{currencyCode}</span><span>{amount}</span>
                          </Box>
                        ) : null
                      })}
                    </Grid>
                    <Link
                      _hover={{
                        textDecoration: "none",
                      }}
                      as={RouterLink}
                      to={`/flow/${productId}/${departure.date}`}
                    >
                      <Box
                        _hover={{
                          bg: "blue.600",
                        }}
                        align="center"
                        border="1px"
                        borderColor="blue.500"
                        borderRadius="md"
                        fontSize="md"
                        mt={2}
                        p={1}
                        w="100%"
                      >
                        <Text color="blue.500">Start flow</Text>
                      </Box>
                    </Link>
                  </Box>
                ))
                : null}
            </Grid>
          </TabPanel>
          <TabPanel>
            <JSONDisplay jsonToDisplay={departures} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default Departures;
