import React from "react";

import { CopyIcon } from "@chakra-ui/icons";
import { Box, Button } from "@chakra-ui/react";
import JSONPretty from "react-json-pretty";
import { CopyToClipboard } from "react-copy-to-clipboard";

function JSONDisplay({ jsonToDisplay }) {
  return (
    <React.Fragment>
      <Box align="right" mb={-5} mr={8} position="relative" zIndex={0}>
        <CopyToClipboard text={JSON.stringify(jsonToDisplay)}>
          <Button bg="white" mt={4} variant="outline" w="75px">
            <CopyIcon color="gray" />
          </Button>
        </CopyToClipboard>
      </Box>
      <Box mt={2} maxWidth={1200}>
        <JSONPretty
          id="json-pretty"
          data={jsonToDisplay}
          mainStyle="background:#fff;height:50vh;overflow:auto;white-space:pre-wrap;"
          errorStyle="background:#fff;height:50vh;overflow:auto;white-space:pre-wrap;"
          keyStyle="color:#ee663a;"
          stringStyle="color:#547cd3;"
          valueStyle="color:#dead0a;"
          booleanStyle="color:#21b679;"
        ></JSONPretty>
      </Box>
    </React.Fragment>
  );
}

export default JSONDisplay;
