import React from "react";

import { Box, Heading } from "@chakra-ui/react";
import DetailsLayout from "./DetailsLayout";

function ProductDetails({ productDetails }) {
  return (
    <Box mt={4}>
      <Heading as="h5" mb={4} size="md">
        Product Details
      </Heading>
      {productDetails.map((productDetail, index) => (
        <DetailsLayout key={index} details={productDetail} />
      ))}
    </Box>
  );
}

export default ProductDetails;
