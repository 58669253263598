import { Box, Heading, Flex } from "@chakra-ui/react";
import _ from "lodash";

import CancellationPolicy from "../CancellationPolicy/CancellationPolicy";
import SupplierTncs from "../SupplierTncs/SupplierTncs";
import QuoteInvoice from "../QuoteInvoice/QuoteInvoice";
import Tickets from "../Tickets/Tickets";
import BillingInfo from "./BillingInfo";
import BookingDetails from "./BookingDetails";
import ExternalResources from "./ExternalResources";
import Cancellations from "../Cancellations/Cancellations";
import { PDFDownload } from "../PDFDownload/PDFDownload";
import CancelBookingModal from "./CancelBookingModal";

function Booking({ booking, fromCancellation }) {
  let isCancelled = fromCancellation;
  if (booking.cancellations) {
    for (let b of booking.cancellations) {
      if (b.confirmed) {
        isCancelled = true;
        break;
      }
    }
  }

  return (
    <Box
      bg="blue.500"
      borderWidth="1px"
      borderRadius="lg"
      color="white"
      mb={8}
      p={6}
      w="100%"
    >
      <Flex justify="space-between" align="center" mb={4}>
        {booking.title ? (
          <Heading as="h4" size="md">
            {booking.title}
          </Heading>
        ) : null}
        <Flex align="center">
          {isCancelled ? (
            <Box
              borderRadius="100px"
              py="1"
              px="3"
              boxShadow="inner"
              backgroundColor="red.300"
              ml="auto"
              mr="4"
            >
              Cancelled
            </Box>
          ) : null
          }
          <PDFDownload id={booking.id} type={"bookings"} />
        </Flex>
        {!isCancelled ? (
          <CancelBookingModal loading={booking.loading} booking={booking} />
        ) : null}
      </Flex>
      <BookingDetails booking={booking} />
      {!_.isEmpty(booking.billingInfo) ? (
        <BillingInfo billingInfo={booking.billingInfo} />
      ) : null}
      {!_.isEmpty(booking.tickets) ? (
        <Tickets tickets={booking.tickets} />
      ) : null}
      {!_.isEmpty(booking.invoice) ? (
        <QuoteInvoice details={booking.invoice} />
      ) : null}
      {!_.isEmpty(booking.externalResources) ? (
        <ExternalResources
          externalResources={booking.externalResources.externalResources}
        />
      ) : null}
      {!_.isEmpty(booking.generalTerms) ? (
        <SupplierTncs generalTerms={booking.generalTerms} />
      ) : null}
      {!_.isEmpty(booking.cancellationPolicy) ? (
        <CancellationPolicy cancellationPolicy={booking.cancellationPolicy} />
      ) : null}
      {!_.isEmpty(booking.cancellations) ? (
        <Cancellations cancellations={booking.cancellations} />
      ) : null}
    </Box>
  );
}

export default Booking;
