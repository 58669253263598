import React from "react";

import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";

function BarcodeDetails({ barcodeDetails }) {
  return (
    <Box mt={4}>
      <Heading as="h5" mb={4} size="md">
        Barcode Details
      </Heading>
      <Flex align="center" justify="start" flexWrap="wrap">
        <Box mx="15px" mb="16px">
          <Text>barcodeRaw</Text>
          <Text>{barcodeDetails.barcodeRaw}</Text>
        </Box>

        <Box mx="15px" mb="16px">
          <Text>format</Text>
          <Text>{barcodeDetails.format}</Text>
        </Box>

        <Box mx="15px" mb="16px">
          <Text>barcodeRequired</Text>
          <Text>{`${barcodeDetails.barcodeRequired}`}</Text>
        </Box>
      </Flex>
      <Flex justify="center">
        {barcodeDetails.barcodeData ? (
          <Image
            alt={barcodeDetails.barcodeRaw}
            src={barcodeDetails.barcodeData}
            borderRadius="8px"
            maxW="300px"
            my="20px"
          />
        ) : null}
      </Flex>
    </Box>
  );
}

export default BarcodeDetails;
