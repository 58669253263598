import { Link as RouterLink } from "react-router-dom";
import placeholderImage from "../../../resources/no-image.png";

import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";

function SupplierDetails({ supplier }) {
  return (
    <Box border="1px" borderColor="gray.400" borderRadius="md" mb={4} p={6}>
      <Grid alignItems="top" gap={6} templateColumns="repeat(12, 1fr)">
        <GridItem colSpan={2}>
          {!_.isEmpty(supplier.logo) ? (
            <Box>
              <Image alt={supplier.logo.title} src={supplier.logo.url} />
              <Text color="gray.800" fontSize="xs">
                {supplier.logo.width} x {supplier.logo.height}
              </Text>
            </Box>
          ) : <Image alt={supplier.name} src={placeholderImage} />}
        </GridItem>
        <GridItem colSpan={7}>
          <VStack
            alignItems="inherit"
            h="100%"
            justifyContent="space-between"
            w="100%"
          >
            <Box>
              <Flex alignItems="flex-end" justifyContent="space-between">
                <Tooltip label={supplier.id ? supplier.id : ""}>
                  <Heading as="h4" fontWeight="semibold" size="md">
                    {supplier.name}
                  </Heading>
                </Tooltip>
                {supplier.resSystem ? (
                  <Text color="gray.800" fontSize="sm">
                    {supplier.resSystem}
                  </Text>
                ) : null}
              </Flex>
              <Text color="gray.800" fontSize="md" my={2} noOfLines={4}>
                {supplier.description}
              </Text>
            </Box>
            <Flex alignItems="flex-end" justify="space-between">
              <Box>
                <Link
                  _hover={{
                    textDecoration: "none",
                  }}
                  as={RouterLink}
                  to={`/supplier/details/${supplier.id}`}
                >
                  <Box
                    _hover={{
                      bg: "blue.600",
                    }}
                    bg="blue.500"
                    borderRadius="md"
                    color="white"
                    fontSize="sm"
                    px={4}
                    py={2}
                  >
                    More details
                  </Box>
                </Link>
              </Box>
            </Flex>
          </VStack>
        </GridItem>
        <GridItem colSpan={3}>
          <SimpleGrid gap={2}>
            {!_.isEmpty(supplier.distributor) ? (
              <GridItem>
                <Text color="gray.800" fontSize="xs" fontWeight="semibold">
                  Distributor
                </Text>
                {supplier.distributor.name ? (
                  <Tooltip
                    label={
                      supplier.distributor.id ? supplier.distributor.id : ""
                    }
                  >
                    <Text color="gray.800" fontSize="sm">
                      {supplier.distributor.name}
                    </Text>
                  </Tooltip>
                ) : null}
              </GridItem>
            ) : null}
            {!_.isEmpty(supplier.distributor) &&
              !_.isEmpty(supplier.distributor.company) ? (
              <GridItem>
                <Text color="gray.800" fontSize="xs" fontWeight="semibold">
                  Company
                </Text>
                {supplier.distributor.company.name ? (
                  <Tooltip
                    label={
                      supplier.distributor.company.id
                        ? supplier.distributor.company.id
                        : ""
                    }
                  >
                    <Text color="gray.800" fontSize="sm">
                      {supplier.distributor.company.name}
                    </Text>
                  </Tooltip>
                ) : null}
              </GridItem>
            ) : null}
            {!_.isEmpty(supplier.channel) ? (
              <GridItem>
                <Text color="gray.800" fontSize="xs" fontWeight="semibold">
                  Channel
                </Text>
                {!_.isEmpty(supplier.channel) ? (
                  <Tooltip
                    label={supplier.channel.id ? supplier.channel.id : ""}
                  >
                    <Box>
                      {supplier.channel.directConnect ? (
                        <Text color="gray.800" fontSize="sm">
                          Direct Connect
                        </Text>
                      ) : null}
                      {supplier.channel.billingCurrency ? (
                        <Text color="gray.800" fontSize="sm">
                          {supplier.channel.billingCurrency}
                        </Text>
                      ) : null}
                    </Box>
                  </Tooltip>
                ) : null}
              </GridItem>
            ) : null}
            {!_.isEmpty(supplier.catalogue) ? (
              <GridItem>
                <Text color="gray.800" fontSize="xs" fontWeight="semibold">
                  Catalogue
                </Text>
                {supplier.catalogue.name ? (
                  <Tooltip
                    label={supplier.catalogue.id ? supplier.catalogue.id : ""}
                  >
                    <Text color="gray.800" fontSize="sm">
                      {supplier.catalogue.name}
                    </Text>
                  </Tooltip>
                ) : null}
              </GridItem>
            ) : null}
          </SimpleGrid>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default SupplierDetails;
