import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILogConsole } from "../../types/LogConsoleTypes";



export const logConsoleSlice = createSlice({
  name: "logConsole",
  initialState: [] as ILogConsole[],
  reducers: {
    updateLog(state, action: PayloadAction<ILogConsole>) {
      if (state.length === 100) {
        state.shift();
        state.push(action.payload);
      } else {
        state.push(action.payload);
      }
    },
    clearLog(state) {
      state.length = 0
    }
  },
});

const { actions, reducer } = logConsoleSlice;
export const {
  updateLog,
  clearLog
} = actions;
export default reducer;

export const addLog = (logEntry) => (dispatch) => {
  dispatch(updateLog(logEntry));
};

export const clearLogs = () => (dispatch) => {
  dispatch(clearLog());
};
