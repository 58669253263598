import { useMemo } from "react";
import { useDispatch } from "react-redux";

import { InfoOutlineIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Tooltip,
  Select
} from "@chakra-ui/react";
import copy from "copy-to-clipboard";
import { DateTime } from "luxon";
import { SearchInput } from "./Components/SearchInput";
import { SearchDatePicker } from "./Components/SearchDatePicker";
import { countries } from "countries-list";
import { postProductSearch } from "../productSlice";
import { Multiselect } from "multiselect-react-dropdown";

const multiSelectStyles = {
  searchBox: {
    borderColor: "var(--chakra-colors-gray-400)",
    borderRadius: "var(--chakra-radii-md)",
    minHeight: "var(--chakra-sizes-10)",
    paddingInlineStart: "var(--chakra-space-4)",
  },
  chips: {
    background: "var(--chakra-colors-blue-500)",
  },
};

const formattedCountries = Object.entries(countries).reduce(
  (acc, curr) => [...acc, { value: curr[0], label: curr[1].name }], []
);

function SearchForm({
  form,
  handleChangeDates,
  handleClearSearchForm,
  handleInputChange,
  loading,
}) {
  const dispatch = useDispatch();
  const prepareSearchForm = () => {
    let temp = {}
    for (const key in form) {
      if (form[key] !== "" && form[key] !== null) {
        temp[key] = form[key];
      }
    }
    return {
      ...temp,
      paging: {
        resultsPerPage: parseInt(form.resultsPerPage, 10) || 100,
        currentPage: parseInt(form.currentPage, 10) || 1
      },
      resultsPerPage: undefined,
      currentPage: undefined,
      productIds: form.productIds
        ? form.productIds.replaceAll(" ", "").split(",")
        : undefined,
      supplierIds: form.supplierIds
        ? form.supplierIds.replaceAll(" ", "").split(",")
        : undefined,
      categories: form.categories
        ? form.categories.replaceAll(" ", "").split(",")
        : undefined,
      operatesFrom: form.operatesFrom
        ? DateTime.fromJSDate(form.operatesFrom).toFormat("yyyy-MM-dd")
        : undefined,
      operatesTo: form.operatesTo
        ? DateTime.fromJSDate(form.operatesTo).toFormat("yyyy-MM-dd")
        : undefined,
      modifiedSince: form.modifiedSince ? form.modifiedSince : undefined,
    };
  };
  const handlePostProductSearch = () => {
    dispatch(postProductSearch(prepareSearchForm()))
  };
  const handleCopySearchJSON = () => copy(JSON.stringify(prepareSearchForm()));

  const handleCountryChange = (selectedItems) => {
    handleInputChange({
      target: {
        value: selectedItems.map(({ value }) => value),
        name: "countries",
      },
    });
  };

  const selectedCountries = useMemo(
    () =>
      form.countries.map((countryCode) =>
        formattedCountries.find(({ value }) => value === countryCode)
      ),
    [form.countries]
  );

  const handleDateRange = (range) => {
    const now = DateTime.now();
    switch (range) {
      case "30_DAYS": {
        handleChangeDates(
          now.plus({ days: 1 }).toJSDate(),
          now.plus({ days: 31 }).toJSDate()
        );
        break;
      }
      case "MONTH": {
        const nextMonth = DateTime.fromObject({
          day: 1,
          month: now.month + 1,
          year: now.year,
        });
        handleChangeDates(
          nextMonth.toJSDate(),
          DateTime.fromObject({
            day: nextMonth.daysInMonth,
            month: now.month + 1,
            year: now.year,
          }).toJSDate()
        );
        break;
      }
      case "60_DAYS": {
        handleChangeDates(
          now.plus({ days: 1 }).toJSDate(),
          now.plus({ days: 61 }).toJSDate()
        );
        break;
      }
      case "QUARTER": {
        handleChangeDates(
          now.plus({ days: 1 }).toJSDate(),
          now.plus({ days: 1, months: 3 }).toJSDate()
        );
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <>
      <Grid
        alignItems="center"
        gap={4}
        templateColumns="repeat(3, 1fr)"
        w="100%"
      >
        <GridItem>
          <SearchInput
            id="airport"
            value={form.airport}
            placeholder={"SYD"}
            tooltipLabel={
              "Search by IATA airport code. Used in conjunction with radius. Valid airport codes will be resolved to the applicable coordinates (latitude and longitude)."
            }
            handleChange={handleInputChange}
          />
        </GridItem>
        <GridItem>
          <SearchInput
            id="categories"
            value={form.categories}
            tooltipLabel={
              "Search by product category IDs as returned by GET /api/products/categories."
            }
            handleChange={handleInputChange}
          />
        </GridItem>
        <GridItem>
          <FormControl id="categoriesMatchingMode">
            <FormLabel>categoriesMatchingMode
              <Tooltip label="Used to specify how product Categories are to be matched in a ProductSearch.  ANY: Product must match at least one of the specified categories. Default for product search; ALL: Product must match all specified categories, but may have other additional categories.; FULL: Product must exclusively match all but only the specified categories.">
                <InfoOutlineIcon color="gray.800" ml={2} />
              </Tooltip>
            </FormLabel>
            <Select
              name="categoriesMatchingMode"
              onChange={handleInputChange}
              placeholder="Select matching mode"
              value={form.categoriesMatchingMode}
              w="100%"
            >
              <option value="ANY">ANY</option>
              <option value="ALL">ALL</option>
              <option value="FULL">FULL</option>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <SearchInput
            id="fts"
            value={form.fts}
            placeholder={"description='Sydney'+highlights='Harbour Bridge'"}
            tooltipLabel={
              "A fulltext search query. The query syntax is {searchField}={'searchValue'}. Available search fields are name, description and highlights. The search value must be surrounded with single quotes. To chain together multiple criteria use + for AND and | for OR e.g description='Sydney'+highlights='Harbour Bridge'"
            }
            handleChange={handleInputChange}
          />
        </GridItem>
        <GridItem>
          <FormControl id="includeDisabled">
            <Checkbox
              name="includeDisabled"
              mb={2}
              mt={8}
              onChange={handleInputChange}
              value={form.includeDisabled}
              isChecked={form.includeDisabled}
            >
              includeDisabled
              <Tooltip label="Include currently disabled products, i.e. products which cannot be booked at the moment.">
                <InfoOutlineIcon color="gray.800" ml={2} />
              </Tooltip>
            </Checkbox>
          </FormControl>
        </GridItem>
        <GridItem>
          <SearchInput
            id="latitude"
            value={form.latitude}
            helperText="maximum: 90, minimum: -90"
            tooltipLabel={
              "Search by geographic coordinates. Used in conjunction with radius."
            }
            handleChange={handleInputChange}
          />
        </GridItem>
        <GridItem>
          <SearchInput
            id="longitude"
            value={form.longitude}
            helperText="maximum: 180, minimum: -180"
            tooltipLabel={
              "Search by geographic coordinates. Used in conjunction with radius."
            }
            handleChange={handleInputChange}
          />
        </GridItem>
        <GridItem>
          <SearchInput
            id="radius"
            value={form.radius}
            helperText="maximum: 1000, minimum: 0.01"
            tooltipLabel={
              "Defines the search radius in kilometres for geography-based searches. Must be used in conjunction with latitude and longitude or airport."
            }
            handleChange={handleInputChange}
          />
        </GridItem>
        <GridItem>
          <SearchDatePicker
            id="operatesFrom"
            tooltipLabel="If specified, limit search results to products that operate and according to our cache have bookable availability on or after this date. Can be used in combination with operatesTo to cover date ranges. Format yyyy-MM-dd"
            selected={form.operatesFrom}
            handleChange={handleInputChange}
            dateFormat="yyyy-MM-dd"
          />
        </GridItem>
        <GridItem>
          <SearchDatePicker
            id="operatesTo"
            tooltipLabel="Used in conjunction with operatesFrom to limit search results to products that operate and can be booked on any date in the specified period. Format yyyy-MM-dd"
            selected={form.operatesTo}
            handleChange={handleInputChange}
            dateFormat="yyyy-MM-dd"
          />
        </GridItem>
        <GridItem>
          <SearchDatePicker
            id="modifiedSince"
            tooltipLabel="Format yyyy-MM-dd HH:mm"
            selected={form.modifiedSince}
            handleChange={handleInputChange}
            maxDate={new Date()}
            dateFormat="yyyy-MM-dd HH:mm"
            showTimeSelect={true}
          />
        </GridItem>
        <GridItem>
          <FormLabel>Preset date ranges</FormLabel>
          <ButtonGroup isAttached variant="outline">
            <Tooltip label="Next 30 days">
              <Button onClick={() => handleDateRange("30_DAYS")}>+30d</Button>
            </Tooltip>
            <Tooltip label="Next calendar month">
              <Button onClick={() => handleDateRange("MONTH")}>+1m</Button>
            </Tooltip>
            <Tooltip label="Next 60 days">
              <Button onClick={() => handleDateRange("60_DAYS")}>+60d</Button>
            </Tooltip>
            <Tooltip label="Next quarter">
              <Button onClick={() => handleDateRange("QUARTER")}>+1q</Button>
            </Tooltip>
          </ButtonGroup>
        </GridItem>
        <GridItem>
          <FormControl id="order">
            <FormLabel>order
              <Tooltip label="Specify the sort order of the returned results.">
                <InfoOutlineIcon color="gray.800" ml={2} />
              </Tooltip>
            </FormLabel>
            <Select
              name="order"
              onChange={handleInputChange}
              placeholder="Select order"
              value={form.order}
              w="100%"
            >
              <option value="ID">ID</option>
              <option value="DISTANCE">DISTANCE</option>
              <option value="DURATION">DURATION</option>
              <option value="SUPPLIER_ID">SUPPLIER_ID</option>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl id="orderDescending">
            <Checkbox
              name="orderDescending"
              mb={2}
              mt={8}
              onChange={handleInputChange}
              value={form.orderDescending}
              isChecked={form.orderDescending}
            >
              orderDescending
              <Tooltip label="Reverse the default ascending order direction.">
                <InfoOutlineIcon color="gray.800" ml={2} />
              </Tooltip>
            </Checkbox>
          </FormControl>
        </GridItem>
        <GridItem>
          <SearchInput
            id="productIds"
            value={form.productIds}
            tooltipLabel="ID(s) of the product(s) to limit search results to."
            handleChange={handleInputChange}
            placeholder="IDs - Comma Separated"
          />
        </GridItem>
        <GridItem>
          <SearchInput
            id="supplierIds"
            value={form.supplierIds}
            tooltipLabel="ID(s) of the supplier(s) to limit search results to."
            handleChange={handleInputChange}
            placeholder="IDs - Comma Separated"
          />
        </GridItem>
        <GridItem>
          <SearchInput
            id="durationMin"
            value={form.durationMin}
            tooltipLabel="In minutes."
            handleChange={handleInputChange}
          />
        </GridItem>
        <GridItem>
          <SearchInput
            id="durationMax"
            value={form.durationMax}
            tooltipLabel="In minutes."
            handleChange={handleInputChange}
          />
        </GridItem>
        <GridItem>
          <FormControl id="countries">
            <FormLabel>countries
              <Tooltip label='ISO 3166-1 alpha-2 two-letter country codes of countries to limit product search by. Special case "XX" can be used to represent the country at the specified latitude &#38; longitude or airport parameter.'>
                <InfoOutlineIcon color="gray.800" ml={2} />
              </Tooltip>
            </FormLabel>
            <Multiselect
              options={formattedCountries}
              selectedValues={selectedCountries}
              onSelect={handleCountryChange}
              onRemove={handleCountryChange}
              displayValue="label"
              style={multiSelectStyles}
            />
          </FormControl>
        </GridItem>
        <GridItem>
          <SearchInput
            id="resultsPerPage"
            value={form.resultsPerPage}
            tooltipLabel="The number of results on each page of the requested search."
            handleChange={handleInputChange}
          />
        </GridItem>
        <GridItem>
          <SearchInput
            disabled={true}
            id="currentPage"
            value={form.currentPage}
            tooltipLabel="The current page to view from the requested search."
            handleChange={handleInputChange}
          />
        </GridItem>
      </Grid>
      <Grid
        alignItems="top"
        gap={4}
        mt={4}
        templateColumns="repeat(3, 1fr)"
        w="100%"
      >
        <GridItem>
          <Button
            mt={4}
            onClick={() => handleClearSearchForm()}
            variant="outline"
            w="100%"
          >
            Clear form
          </Button>
        </GridItem>
        <GridItem>
          <Button
            mt={4}
            onClick={() => handleCopySearchJSON()}
            variant="outline"
            w="100%"
          >
            Copy request payload
          </Button>
        </GridItem>
        <GridItem>
          <Button
            leftIcon={<SearchIcon />}
            colorScheme="green"
            isLoading={loading === "searching"}
            loadingText="Searching"
            mt={4}
            onClick={() => handlePostProductSearch()}
            w="100%"
          >
            Search
          </Button>
        </GridItem>
      </Grid>
    </>
  );
}

export default SearchForm;
