import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  FormControl,
  FormLabel,
  GridItem,
  Heading,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

import { getCheckoutFlow } from "../checkoutFlowSlice";

import { divVariants } from "../../../configs/pageMotions";

import "react-datepicker/dist/react-datepicker.css";
import "../date-picker.css";

function GetFlow({ loading }) {
  const [form, setForm] = useState({});
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const dispatch = useDispatch();
  const handleGetCheckoutFlow = () => dispatch(getCheckoutFlow(form.flowId));

  return (
    <GridItem colSpan={2}>
      <motion.div variants={divVariants}>
        <Heading as="h3" mb={4} size="lg">
          Get Flow
        </Heading>
        <SimpleGrid columns={1} spacing={4}>
          <FormControl id="flowId" isRequired={true}>
            <FormLabel>Flow id</FormLabel>
            <Input
              name="flowId"
              onChange={handleInputChange}
              value={form.flowId}
              w="100%"
            />
          </FormControl>
          <Button
            colorScheme="blue"
            isLoading={loading === "pending"}
            loadingText="Getting"
            disabled={form.flowId ? false : true}
            mt={4}
            onClick={() => handleGetCheckoutFlow()}
            w="100%"
          >
            Get
          </Button>
        </SimpleGrid>
      </motion.div>
    </GridItem>
  );
}

export default GetFlow;
