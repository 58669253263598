import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, Text, Tooltip } from "@chakra-ui/react";
import _ from "lodash";

function CancellationPolicy({ cancellationPolicy, maxHeight = "500px" }) {
  return !_.isEmpty(cancellationPolicy) ? (
    <Box
      bg="gray.100"
      borderWidth="1px"
      borderRadius="lg"
      color="gray.800"
      maxHeight={maxHeight}
      my={4}
      overflowY="auto"
      p={6}
    >
      <Heading as="h6" mb={4} size="sm">
        {" "}
        Cancellation Policy{" "}
      </Heading>
      <Flex align="center" justify="space-between">
        <Text as="samp" fontSize="md">
          setAtTimeOfBooking
          <Tooltip label="If true, the rules are relative to the time of booking, rather than the departure date, and will be adjusted accordingly at the time of checkout.">
            <InfoOutlineIcon ml={2} />
          </Tooltip>
        </Text>
        <Text as="samp" fontSize="md">
          {cancellationPolicy.setAtTimeOfBooking ? "true" : "false"}
        </Text>
      </Flex>
      {cancellationPolicy.text ? (
        <Box my={2}>
          <Text fontSize="xs" fontWeight="semibold">
            text
          </Text>
          <Text fontSize="sm">{cancellationPolicy.text}</Text>
        </Box>
      ) : null}
      {!_.isEmpty(cancellationPolicy.rules) ? (
        <Box>
          <Text fontSize="xs" fontWeight="semibold">
            rules
          </Text>
          <ul>
            {cancellationPolicy.rules.map((rule, index) => (
              <Box
                key={index}
                mb={index + 1 < cancellationPolicy.rules.length ? 2 : 0}
              >
                <li>
                  <Text>
                    {rule.cancellationPossible
                      ? `After ${rule.after}, the cancellation cost is ${rule.cancellationCostPerc}% of the booking value provided by the reservation system. Cancellations are possible with
                the upstream reservation system past the instant indicated.`
                      : `After ${rule.after} cancellations are not possible with
                the upstream reservation system.`}
                  </Text>
                </li>
              </Box>
            ))}
          </ul>
        </Box>
      ) : null}
    </Box>
  ) : null;
}

export default CancellationPolicy;
