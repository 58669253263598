import React from "react";

import BookingList from "../../../components/Booking/BookingList";

function Bookings({ bookings }) {
  return (
    <BookingList bookings={{ list: bookings, title: "Completed Bookings" }} />
  );
}

export default Bookings;
