import { useCallback, useState, useEffect } from "react";
import SearchForm from "./SearchForm";
import { useDispatch } from "react-redux";
import { useSelector } from "../../app/store";
import SearchQueryString from "./Components/SearchQueryString";

import {
  GridItem,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { ticketSearchClear } from "./ticketSlice";
import { divVariants } from "../../configs/pageMotions";


const initialFormState: {
  resultsPerPage: number;
  currentPage: number;
  anyReference: string;
  id: string;
  order: string;
  orderDescending: boolean;
  passengerName?: string;
  supplierReference?: string;
} = {
  currentPage: 1,
  resultsPerPage: 50,
  anyReference: "",
  id: "",
  order: "",
  passengerName: "",
  supplierReference: "",
  orderDescending: false,
};

function Search() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ticketSearchClear());
  }, [dispatch]);
  const [searchForm, setSearchForm] = useState(initialFormState);
  const ticket = useSelector(({ ticket }) => ticket);

  const handleSearchInputChange = useCallback((event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setSearchForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleClearSearchForm = () => setSearchForm(initialFormState);

  return (
    <GridItem colSpan={6}>
      <motion.div variants={divVariants}>
        <Heading as="h3" mb={4} size="lg">
          Get Tickets
        </Heading>
        <Tabs isFitted variant="enclosed-colored">
          <TabList mb="1em">
            <Tab>Search Form</Tab>
            <Tab>Query String</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <SearchForm
                form={searchForm}
                handleClearSearchForm={handleClearSearchForm}
                handleInputChange={handleSearchInputChange}
                loading={ticket.loading}
              />
            </TabPanel>
            <TabPanel>
              <SearchQueryString form={searchForm} loading={ticket.loading} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </motion.div>
    </GridItem>

  );
}

export default Search;
