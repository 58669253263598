import { configureStore } from "@reduxjs/toolkit";
import {
  useSelector as useReduxSelector,
  TypedUseSelectorHook,
} from "react-redux";

import bookingReducer from "../features/Booking/bookingSlice";
import checkoutFlowReducer from "../features/CheckoutFlow/checkoutFlowSlice";
import productReducer from "../features/Product/productSlice";
import supplierReducer from "../features/Supplier/supplierSlice";
import ticketReducer from "../features/Ticket/ticketSlice";
import errorReducer from "../features/Error/errorSlice";
import loginFlowReducer from "../features/Login/loginSlice";
import logConsoleReducer from "../features/LogConsole/logConsoleSlice";

const store = configureStore({
  reducer: {
    booking: bookingReducer,
    checkoutFlow: checkoutFlowReducer,
    product: productReducer,
    supplier: supplierReducer,   
    ticket: ticketReducer,
    error: errorReducer,
    login: loginFlowReducer,
    logConsole: logConsoleReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
