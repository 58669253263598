import React from "react";

import { Box, Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react";

function BillingInfo({ billingInfo }) {
  return (
    <Box
      bg="gray.100"
      borderWidth="1px"
      borderRadius="lg"
      color="gray.800"
      my={4}
      p={6}
    >
      <Heading as="h4" mb={4} size="md">
        Billing Info
      </Heading>
      <SimpleGrid columns={2} spacingX={16} spacingY={4}>
        {billingInfo.billingCurrency ? (
          <Flex align="flex-start" justify="space-between">
            <Text color="gray.800">billingCurrency</Text>
            <Text>{billingInfo.billingCurrency}</Text>
          </Flex>
        ) : null}
        {billingInfo.billingExchangeRate ? (
          <Flex align="flex-start" justify="space-between">
            <Text color="gray.800">billingExchangeRate</Text>
            <Text>{billingInfo.billingExchangeRate}</Text>
          </Flex>
        ) : null}
      </SimpleGrid>
    </Box>
  );
}

export default BillingInfo;
