import React from "react";

import {
  Box,
  Button,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";

function TemporaryHold({
  enableButtons,
  expired,
  loading,
  handleBackCheckoutFlow,
  handleNextCheckoutFlow,
  step,
}) {
  const { nextStepSequenceNumber } = step;

  return (
    <Box>
      <Tabs isFitted variant="enclosed-colored">
        <TabList mb="1em">
          <Tab>Temporary Hold Details</Tab>          
        </TabList>
        <TabPanels>
          <TabPanel>
            <Text>{step.caption}</Text>
          </TabPanel>
          <TabPanel></TabPanel>
        </TabPanels>
      </Tabs>
      {enableButtons ? (
        <SimpleGrid columns={2} spacing={2}>
          <Button
            colorScheme={expired ? "red" : "gray"}
            isLoading={loading === "pending"}
            loadingText="Loading"
            mt={2}
            onClick={() => handleBackCheckoutFlow()}
            variant="outline"
            w="100%"
          >
            Back
          </Button>
          {nextStepSequenceNumber ? (
            <Button
              colorScheme={expired ? "red" : "green"}
              isLoading={loading === "pending"}
              loadingText="Posting"
              mt={2}
              onClick={() => handleNextCheckoutFlow()}
              w="100%"
            >
              Next
            </Button>
          ) : null}
        </SimpleGrid>
      ) : null}
    </Box>
  );
}

export default TemporaryHold;
