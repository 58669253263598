import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { logoutUpdate, setSession } from "../Login/loginSlice";

export interface IErrorState {
  code: string;
  config?: {
    data: string;
    method: string;
    url: string;
  };
  message: string;
  name: string;
  timestamp: string;
}

const initialState: IErrorState = {
  code: "",
  config: undefined,
  message: "",
  name: "",
  timestamp: "",
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    errorUpdate(state, { payload }: PayloadAction<IErrorState>) {
      state.code = payload.code;
      state.config = payload.config;
      state.message = payload.message;
      state.name = payload.name;
      state.timestamp = payload.timestamp;
    },
  },
});

const { actions, reducer } = errorSlice;
export const { errorUpdate } = actions;
export default reducer;

export const handleError =
  ({ response, name, config }: any) =>
  (dispatch) => {
    if (response && response.data) {
      const {
        data: { code, message, timestamp },
      } = response;
      const { data, method, url } = config;
      dispatch(clearError());
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      if (code === 401) {
        dispatch(setSession());
        dispatch(logoutUpdate());
      }
      dispatch(
        errorUpdate({
          code,
          config: { data, method, url },
          message,
          name,
          timestamp,
        })
      );
    }
  };

export const clearError = () => (dispatch) => {
  dispatch(
    errorUpdate({
      code: "",
      config: undefined,
      message: "",
      name: "",
      timestamp: "",
    })
  );
};
