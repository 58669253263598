import React, { useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ChakraProvider } from "@chakra-ui/react";

import Login from "./features/Login/Login";
import { checkSession } from "./features/Login/loginSlice";

import { theme } from "./theme";
import Layout from "./hoc/Layout";

import { RootState } from "./app/store";
import { clearError } from "./features/Error/errorSlice";

import Booking from "./features/Booking/Booking";
import Ticket from "./features/Ticket/Ticket";

import CheckoutFlow from "./features/CheckoutFlow/CheckoutFlow";

import Product from "./features/Product/Product";
import ProductDetails from "./features/Product/Details/ProductDetails";
import ProductDepartures from "./features/Product/Departures/ProductDepartures";
import Search from "./features/Product/Search/Search";

import Supplier from "./features/Supplier/Supplier";
import SupplierDetails from "./features/Supplier/Details/SupplierDetails";
import SupplierSearch from "./features/Supplier/Search/Search";

function RequireAuth({ children, isAuthenticated }) {
  const location = useLocation();
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

function App() {
  const dispatch = useDispatch(); 
  useEffect(() => {
    dispatch(checkSession());
  }, [dispatch]);

  const login = useSelector(({ login }: RootState) => login);

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <RouterWrapper login={login} />
      </BrowserRouter>
    </ChakraProvider>
  );
}

// need to absract to new component so we can access useLocation
function RouterWrapper({ login }) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearError());
  }, [pathname, dispatch]);

  return (
    <Routes>
      <Route
        element={
          login.status !== "LOGGED_IN" ? (
            <Login login={login} />
          ) : (
            <Navigate to="/products/search" replace />
          )
        }
        path="/login"
      />
      <Route
        element={
          <RequireAuth isAuthenticated={login.status === "LOGGED_IN"}>
            <Layout login={login} />
          </RequireAuth>
        }
        path="/"
      >
        <Route element={<Booking />} path="/booking" />
        <Route element={<Ticket />} path="/ticket" />
        <Route element={<CheckoutFlow />} path="/flow" />
        <Route
          element={<CheckoutFlow />}
          path="/flow/:productId/:departureDate"
        />
        <Route element={<Search />} path="/products/search" />
        <Route element={<Product />} path="/products" />
        <Route
          element={<ProductDetails />}
          path="/product/details/:productId"
        />
        <Route
          element={<ProductDepartures />}
          path="/product/departures/:productId"
        />
        <Route element={<Supplier />} path="/suppliers" />
        <Route element={<SupplierSearch />} path="/suppliers/search" />
        <Route
          element={<SupplierDetails />}
          path="/supplier/details/:supplierId"
        />
      </Route>
    </Routes>
  );
}

export default App;
