import { DownloadIcon } from "@chakra-ui/icons";
import { Button, Text } from "@chakra-ui/react";
import axios from "../../configs/axios-config";
import { useState } from "react";

export const PDFDownload = ({ id, type }: { id: string; type: string }) => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const downloadPDF = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/${type}/pdf/${id}`, {
        responseType: "blob",
      });
      const file = window.URL.createObjectURL(response.data);
      let a = document.createElement("a");
      a.href = file;
      a.setAttribute("target", "_blank");
      a.setAttribute("rel", "noopener noreferrer");
      a.click();
      a.remove();
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      setDisabled(true);
      return;
    }
  };
  return (
    <Button
      leftIcon={<DownloadIcon />}
      disabled={disabled}
      onClick={downloadPDF}
      isLoading={loading}
      colorScheme='yellow'
    >
      <Text>{disabled ? "Unable To Generate PDF" : "PDF Download"}</Text>{" "}
    </Button>
  );
};
