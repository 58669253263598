import React from "react";

import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Heading,
  SimpleGrid,
} from "@chakra-ui/react";
import _ from "lodash";

import Location from "./Location";

function Locations({ locationsEnd, locationsStart }) {
  return (
    <AccordionItem>
      <AccordionButton>
        <Heading as="h3" fontWeight="normal" my={2} size="lg">
          Start &amp; End Locations
        </Heading>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel mb={4}>
        {!_.isEmpty(locationsStart) ? (
          <Box mb={4}>
            <Heading as="h4" fontWeight="semibold" my={2} size="md">
              Start Locations
            </Heading>
            <SimpleGrid columns={3} spacing={4}>
              {locationsStart.map((location, index) => (
                <Location key={index} location={location} />
              ))}
            </SimpleGrid>
          </Box>
        ) : null}
        {!_.isEmpty(locationsEnd) ? (
          <Box>
            <Heading as="h4" fontWeight="semibold" my={2} size="md">
              End Locations
            </Heading>
            <SimpleGrid columns={3} spacing={4}>
              {locationsEnd.map((location, index) => (
                <Location key={index} location={location} />
              ))}
            </SimpleGrid>
          </Box>
        ) : null}
      </AccordionPanel>
    </AccordionItem>
  );
}

export default Locations;
