import React from "react";
import {
  Box,
  Heading

} from "@chakra-ui/react";

import { default as SupplierTncsComponent } from "../../../../components/SupplierTncs/SupplierTncs";

function SupplierTncs({ generalTerms }) {
  return (
    <Box>
      <Heading as="h4" mb={4} size="md">
        Supplier Terms and Conditions
      </Heading>
      <SupplierTncsComponent
        generalTerms={generalTerms}
      />
    </Box>
  );
}

export default SupplierTncs;
