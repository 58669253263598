import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { CopyIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { Box, Button, SimpleGrid, Text, Textarea } from "@chakra-ui/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { DateTime } from "luxon";

import { postCheckoutFlow } from "../../checkoutFlowSlice";

function PostFlowJSON({ handleClearFaresAddOns, loading, formDefault }) {
  const [form, setForm] = useState(
    JSON.stringify(
      {
        ...formDefault,
        currency:
          formDefault.currency === "" ? undefined : formDefault.currency,
        clientReference:
          formDefault.clientReference === ""
            ? undefined
            : formDefault.clientReference,
        date: formDefault.date
          ? DateTime.fromJSDate(formDefault.date).toFormat("yyyy-MM-dd")
          : undefined,
      },
      null,
      2,
    ),
  );

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    setForm(value);
  };

  useEffect(() => {
    setForm(
      JSON.stringify(
        {
          productId: formDefault.productId ? +formDefault.productId : undefined,
          currency:
            formDefault.currency === "" ? undefined : formDefault.currency,
          clientReference:
            formDefault.clientReference === ""
              ? undefined
              : formDefault.clientReference,
          date: formDefault.date
            ? DateTime.fromJSDate(formDefault.date).toFormat("yyyy-MM-dd")
            : undefined,
        },
        null,
        2,
      ),
    );
  }, [formDefault]);

  const dispatch = useDispatch();

  const handlePostCheckoutFlow = () => {
    handleClearFaresAddOns();
    dispatch(postCheckoutFlow(JSON.parse(form)));
  };

  return (
    <Box>
      <Text mb={4}>
        If you'd rather not fill out the form and use JSON instead, you may
        enter it here.
      </Text>
      <Textarea
        h="25vh"
        name="JSON"
        onChange={handleInputChange}
        resize="vertical"
        w="100%"
        value={form}
      />
      <SimpleGrid columns={2} spacing={2}>
        <CopyToClipboard text={form}>
          <Button mt={4} variant="outline" w="100%">
            <CopyIcon color="gray" />
          </Button>
        </CopyToClipboard>
        <Button
          leftIcon={<TriangleUpIcon />}
          colorScheme="green"
          isLoading={loading === "pending"}
          loadingText="Posting"
          mt={4}
          onClick={() => handlePostCheckoutFlow()}
          w="100%"
        >
          Post Flow
        </Button>
      </SimpleGrid>
    </Box>
  );
}

export default PostFlowJSON;
