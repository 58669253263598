import React from "react";
import { Button } from "@chakra-ui/react";

const numbersToShow = 16;
const numbersHalf = numbersToShow / 2;

function PaginationButtons({ currentPage, handlePageChange, pagesTotal }) {
  let paginationNumbers = [...Array(pagesTotal)].map((_, i) => i + 1);
  //do we have more pages than our limit to show
  const isOverflowing = pagesTotal > numbersToShow;

  if (isOverflowing) {
    //is there more than half our limit to the first & final number from the current page
    const overflowBackwards = numbersHalf - (currentPage - 1) < 0;
    const overflowForwards = currentPage + numbersHalf < pagesTotal;
    //select half our limit from the left and half from the right of the current page
    paginationNumbers = [...paginationNumbers].slice(
      Math.max(currentPage - numbersHalf - 1, 0),
      currentPage + numbersHalf
    );
    //add ellipsis to signify an overflow in pages
    if (overflowBackwards)
      paginationNumbers = [1, "...", ...paginationNumbers.slice(2)];
    if (overflowForwards)
      paginationNumbers = [
        ...paginationNumbers.slice(0, paginationNumbers.length - 2),
        "...",
        pagesTotal,
      ];
  }

  return (
    <>
      {paginationNumbers.map((value, index) => (
        <Button
          colorScheme="blue"
          disabled={currentPage === value || value === "..."}
          key={`${value}${index}`}
          mr="-px"
          onClick={() => handlePageChange(value)}
        >
          {value}
        </Button>
      ))}
    </>
  );
}

export default PaginationButtons;
