import React from "react";

import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";

function BaseVariant({ base }) {
  return (
    <Box>
      {base.name ? (
        <Text fontSize="lg" fontWeight="semibold" noOfLines={1}>
          <Tooltip label={base.name}>{base.name}</Tooltip>
        </Text>
      ) : null}
      {base.specialNotes ? (
        <Text fontSize="md" fontWeight="normal" noOfLines={1}>
          {base.specialNotes}
        </Text>
      ) : null}
      <Flex align="flex-end" justify="space-between">
        <Text as="samp" color="gray.500" fontSize="md">
          baseVariant
          <Tooltip label="Base variants are the lowest level of differentiation of top-level products into the ultimately bookable product variants (the other being TimeSlots and Fares), e.g. 'Bus' vs 'Bus &amp; Boat' options of a city sightseeing pass.">
            <InfoOutlineIcon color="gray.500" ml={2} />
          </Tooltip>
        </Text>
        <Text as="samp" color="gray.400" fontSize="md">
          {base.available ? (
            <code>available: true</code>
          ) : (
            <code>available: false</code>
          )}
        </Text>
      </Flex>
    </Box>
  );
}

export default BaseVariant;
