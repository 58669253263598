import React, { useRef } from 'react';
import _ from "lodash";
import CancelBooking from "../../features/Booking/CancelBooking";
import { CloseIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useDisclosure
} from '@chakra-ui/react';

function CancelBookingModal({ loading, booking }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  return (
    <>
      {_.isEmpty(booking.cancellations) ? (
        <>
          <Button
            leftIcon={<CloseIcon />}
            colorScheme='red'
            onClick={onOpen}>
            Cancel Booking
          </Button>
          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader />
                <AlertDialogBody>
                  <CancelBooking loading={loading} onClose={onClose} bookingId={booking.id} />
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose} w="100%">
                    Back
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      ) : null}
    </>
  )
}

export default CancelBookingModal;
