import React from "react";

import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";

function TimeSlot({ timeSlot }) {
  return (
    <Box>
      <Flex align="flex-end" justify="space-between">
        {timeSlot.name ? (
          <Text color="blue.500" fontSize="lg" fontWeight="semibold">
            {timeSlot.name}
          </Text>
        ) : null}
        <Text as="samp" color="gray.500" fontSize="sm">
          timeSlot
          <Tooltip label="The second level of differentiation of top-level products into the ultimately bookable product variants (the other being BaseVariants and Fares). Within the requested booking date, TimeSlots identify the different bookable departures or activity session start times and/or durations, within their parent ProductVariant. Alternatively, in the absence of a concrete allocated time slot, this class can be used to identify such open-timed products, that permit arrival at the place of the activity throughout the day, or within specified opening/operating hours.Important Note: The absense of any of the various optional fields does not necessarily mean that there is no such applicable limitation, as we can only pass on machine-readble values we receive from our upstream reservation systems in an equally reliable and machine-readable format.">
            <InfoOutlineIcon color="gray.500" ml={2} />
          </Tooltip>
        </Text>
      </Flex>

      <Flex justify="space-between" wrap="wrap">
        <Flex mb={2} flex="1 1 auto">
          <Text mr={2} color="gray.800">Available:</Text>
          <Text as="samp">{timeSlot.available ? "true" : "false"}</Text>
        </Flex>
        <Flex mb={2} flex="1 1 auto">
          <Text mr={2} color="gray.800">Availability Shared:</Text>
          <Text as="samp">{timeSlot.availabilityShared ? "true" : "false"}</Text>
        </Flex>
        {timeSlot.timeStart ? (
          <Flex mb={2} flex="1 1 auto">
            <Text mr={2} color="gray.800">Time Start:</Text>
            <Text fontWeight="semibold">{timeSlot.timeStart}</Text>
          </Flex>
        ) : null}
        {timeSlot.duration ? (
          <Flex mb={2} flex="1 1 auto">
            <Text mr={2} color="gray.800">Duration:</Text>
            <Text fontWeight="semibold">{timeSlot.duration}</Text>
          </Flex>
        ) : null}
        {timeSlot.specialNotes ? (
          <Flex mb={2} flex="1 1 auto">
            <Text mr={2} color="gray.800">Special Notes:</Text>
            <Text>{timeSlot.specialNotes}</Text>
          </Flex>
        ) : null}
      </Flex>
    </Box>
  );
}

export default TimeSlot;
