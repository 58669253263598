import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { InfoOutlineIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Tooltip
} from "@chakra-ui/react";
import copy from "copy-to-clipboard";
import { motion } from "framer-motion";
import { countries } from "countries-list";
import { getSuppliers } from "../supplierSlice";
import Multiselect from "multiselect-react-dropdown";
import { divVariants } from "../../../configs/pageMotions";

const multiSelectStyles = {
  searchBox: {
    borderColor: "var(--chakra-colors-gray-400)",
    borderRadius: "var(--chakra-radii-md)",
    minHeight: "var(--chakra-sizes-10)",
    paddingInlineStart: "var(--chakra-space-4)",
  },
  chips: {
    background: "var(--chakra-colors-blue-500)",
  },
};

const formattedCountries = Object.entries(countries).reduce(
  (acc, curr) => [...acc, { value: curr[0], label: curr[1].name }],
  []
);

function SearchForm({
  form,
  handleInputChange,
  handleClearSearchForm,
  loading
}) {

  const dispatch = useDispatch();
  const prepareSearchForm = () => {
    let temp = {}
    for (const key in form) {
      if (form[key] !== "" && form[key] !== null && form[key] !== undefined) {
        temp[key] = form[key];
      }
    }
    return {
      ...temp,
      paging: {
        resultsPerPage: parseInt(form.resultsPerPage, 10) || 100,
        currentPage: parseInt(form.currentPage, 10) || 1
      },
      resultsPerPage: undefined,
      currentPage: undefined,
      supplierIds: form.supplierIds
        ? form.supplierIds.replaceAll(" ", "").split(",")
        : undefined
    };
  };

  const handlePostSupplierSearch = () => {
    dispatch(getSuppliers(prepareSearchForm()))
  };
  const handleCopySearchJSON = () => copy(JSON.stringify(prepareSearchForm()));

  const handleCountryChange = (selectedItems) => {
    handleInputChange({
      target: {
        value: selectedItems.map(({ value }) => value),
        name: "countries",
      },
    });
  };

  const selectedCountries = useMemo(
    () =>
      form.countries.map((countryCode) =>
        formattedCountries.find(({ value }) => value === countryCode)
      ),
    [form.countries]
  );

  const handleCountryOfOperationChange = (selectedItems) => {
    handleInputChange({
      target: {
        value: selectedItems.map(({ value }) => value),
        name: "countriesOfOperation",
      },
    });
  };

  const selectedCountriesOfOperation = useMemo(
    () =>
      form.countriesOfOperation.map((countryCode) =>
        formattedCountries.find(({ value }) => value === countryCode)
      ),
    [form.countriesOfOperation]
  );

  return (
    <Box>
      <motion.div variants={divVariants}>
        <Grid gap={4} templateColumns="repeat(12, 1fr)">
          <GridItem colSpan={3}>
            <FormControl id="supplierIds">
              <FormLabel>supplierIds
                <Tooltip label="ID(s) of the supplier(s) to limit search results to.">
                  <InfoOutlineIcon color="gray.600" ml={2} />
                </Tooltip>
              </FormLabel>
              <Input
                name="supplierIds"
                placeholder="IDs - Comma Seperated"
                onChange={handleInputChange}
                value={form.supplierIds}
                w="100%"
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={3}>
            <FormControl id="countries">
              <FormLabel>countries
                <Tooltip label="Limit results to suppliers based in these countries.">
                  <InfoOutlineIcon color="gray.600" ml={2} />
                </Tooltip>
              </FormLabel>
              <Multiselect
                options={formattedCountries}
                selectedValues={selectedCountries}
                placeholder="Company Country"
                onSelect={handleCountryChange}
                onRemove={handleCountryChange}
                displayValue="label"
                style={multiSelectStyles}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={3}>
            <FormControl id="countriesOfOperation">
              <FormLabel>countriesOfOperation
                <Tooltip label="Limit results to suppliers that operate products in these countries.">
                  <InfoOutlineIcon color="gray.600" ml={2} />
                </Tooltip>
              </FormLabel>
              <Multiselect
                options={formattedCountries}
                selectedValues={selectedCountriesOfOperation}
                placeholder="Operation Country"
                onSelect={handleCountryOfOperationChange}
                onRemove={handleCountryOfOperationChange}
                displayValue="label"
                style={multiSelectStyles}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={2}>
            <FormControl id="includeDisabled">
              {form.includeDisabled}
              <Checkbox
                name="includeDisabled"
                mb={2}
                mt={10}
                onChange={handleInputChange}
                value={form.includeDisabled}
                isChecked={form.includeDisabled}
              >
                includeDisabled
                <Tooltip label="If True, include suppliers not in accessible inventory.">
                  <InfoOutlineIcon color="gray.600" ml={2} />
                </Tooltip>
              </Checkbox>
            </FormControl>
          </GridItem>
          <GridItem colSpan={2}>
            <FormControl id="resultsPerPage">
              <FormLabel>resultsPerPage
                <Tooltip label="The number of results on each page of the requested search.">
                  <InfoOutlineIcon color="gray.600" ml={2} />
                </Tooltip>
              </FormLabel>
              <Input
                name="resultsPerPage"
                onChange={handleInputChange}
                value={form.resultsPerPage}
                w="100%"
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={2}>
            <FormControl id="currentPage">
              <FormLabel>currentPage
                <Tooltip label="The current page to view from the requested search.">
                  <InfoOutlineIcon color="gray.600" ml={2} />
                </Tooltip>
              </FormLabel>
              <Input
                name="currentPage"
                onChange={handleInputChange}
                disabled={true}
                value={form.currentPage}
                w="100%"
              />
            </FormControl>
          </GridItem>
        </Grid>
        <Grid
          alignItems="top"
          gap={4}
          mt={4}
          templateColumns="repeat(3, 1fr)"
          w="100%"
        >
          <GridItem>
            <Button
              mt={4}
              onClick={() => handleClearSearchForm()}
              variant="outline"
              w="100%"
            >
              Clear form
            </Button>
          </GridItem>
          <GridItem>
            <Button
              mt={4}
              onClick={() => handleCopySearchJSON()}
              variant="outline"
              w="100%"
            >
              Copy request payload
            </Button>
          </GridItem>
          <GridItem>
            <Button
              leftIcon={<SearchIcon />}
              colorScheme="green"
              isLoading={loading === "searching"}
              loadingText="Searching"
              mt={4}
              onClick={() => handlePostSupplierSearch()}
              w="100%"
            >
              Search
            </Button>
          </GridItem>
        </Grid>
      </motion.div>
    </Box>
  );
}

export default SearchForm;
