import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Link,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

import { divVariants } from "../../../configs/pageMotions";

function ProductForm() {
  const [form, setForm] = useState({
    productId: 1,
  });
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setForm({
      ...form,
      [name]: value,
    });
  };

  return (
    <GridItem colSpan={3}>
      <motion.div variants={divVariants}>
        <Heading as="h3" mb={4} size="lg">
          Get Product
        </Heading>
        <Grid gap={4} templateColumns="repeat(6, 1fr)">
          <GridItem colSpan={4}>
            <FormControl id="productId" isRequired={true}>
              <FormLabel>Product id</FormLabel>
              <Input
                name="productId"
                onChange={handleInputChange}
                value={form.productId}
                w="100%"
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={2}>
            <Box mt={7}>
              <Link
                _hover={{
                  textDecoration: "none",
                }}
                as={RouterLink}
                to={`/product/details/${form.productId}`}
              >
                <Box
                  _hover={{
                    bg: "blue.600",
                  }}
                  align="center"
                  bg="blue.500"
                  borderRadius="md"
                  color="white"
                  px={4}
                  py={2.5}
                  w="100%"
                >
                  Get
                </Box>
              </Link>
            </Box>
          </GridItem>
        </Grid>
      </motion.div>
    </GridItem>
  );
}

export default ProductForm;
