import React from "react";

import { Grid, GridItem } from "@chakra-ui/react";
import { motion } from "framer-motion";
import _ from "lodash";

import { divVariants } from "../../configs/pageMotions";

import Loading from "../../components/Loading/Loading";
import Search from "./Search";
import SearchResults from "./SearchResults";
import { useSelector } from "../../app/store";

function Tickets() {
  const ticket = useSelector(({ ticket }) => ticket);

  return (
    <motion.div initial="exit" animate="enter" exit="exit">
      <Grid
        templateColumns="repeat(6, 1fr)"
        gap={12}
        minW={{ xl: "1200px" }}
        w="100%"
      >
        <Search />
        {ticket.loading === "pending" ? (
          <Loading colSpan={6} text="Retrieving tickets..." />
        ) : null}

        {!_.isEmpty(ticket.searchResults) && ticket.loading === "idle" ? (
          <GridItem colSpan={6}>
            <motion.div variants={divVariants}>
              <SearchResults searchResults={ticket.searchResults} />
            </motion.div>
          </GridItem>
        ) : null}
      </Grid>
    </motion.div>
  );
}

export default Tickets;
