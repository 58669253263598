import React, { memo } from "react";
import { useDispatch } from "react-redux";

import { CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import _ from "lodash";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { getSupplierSearch } from "../supplierSlice";

import {
  staggeredChild,
  staggeredChildren,
} from "../../../configs/pageMotions";

import Pagination from "../../../components/Pagination/Pagination";
import PaginationButtons from "../../../components/Pagination/PaginationButtons";
import SupplierDetails from "../Components/SupplierDetails";
import SupplierDetailsLoading from "./Components/SupplierDetailsLoading";
import JSONDisplay from "../../../components/JSONDisplay";

function SearchResults({ loading, searchResults }) {
  const { paging } = searchResults;

  const dispatch = useDispatch();
  const handlePageChange = (page) => {
    dispatch(getSupplierSearch(searchResults.id, page, paging.resultsPerPage));
  };

  return (
    <Box>
      <Box mb={4}>
        <Flex alignItems="center">
          <Tooltip label={searchResults.id ? searchResults.id : ""}>
            <Heading as="h3" size="lg">
              Results: {paging.resultsTotal} total
            </Heading>
          </Tooltip>
          <CopyToClipboard text={searchResults.id}>
            <IconButton
              colorScheme="gray"
              icon={<CopyIcon />}
              ml={4}
              size="sm"
              variant="outline"
            />
          </CopyToClipboard>
        </Flex>
      </Box>
      <Tabs isFitted variant="enclosed-colored">
        <TabList mb="1em">
          <Tab>
            {paging.resultsPerPage} results per page / {paging.pagesTotal}{" "}
            page(s)
          </Tab>
          <Tab>JSON</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Pagination
              currentPage={paging.currentPage}
              handlePageChange={handlePageChange}
              pagesTotal={paging.pagesTotal}
            >
              <PaginationButtons
                currentPage={paging.currentPage}
                handlePageChange={handlePageChange}
                pagesTotal={paging.pagesTotal}
              />
            </Pagination>
            {loading === "searching" || loading === "pending" ? (
              <React.Fragment>
                <SupplierDetailsLoading />
                <SupplierDetailsLoading />
                <SupplierDetailsLoading />
                <SupplierDetailsLoading />
                <SupplierDetailsLoading />
                <SupplierDetailsLoading />
              </React.Fragment>
            ) : null}

            {!_.isEmpty(searchResults.suppliers) && loading === "idle" ? (
              <motion.div
                initial="exit"
                animate="enter"
                exit="exit"
                variants={staggeredChildren}
              >
                {searchResults.suppliers.map((foundSupplier, index) => (
                  <motion.div key={index} variants={staggeredChild}>
                    <SupplierDetails supplier={foundSupplier} />
                  </motion.div>
                ))}
              </motion.div>
            ) : null}

            <Pagination
              currentPage={paging.currentPage}
              handlePageChange={handlePageChange}
              pagesTotal={paging.pagesTotal}
            >
              <PaginationButtons
                currentPage={paging.currentPage}
                handlePageChange={handlePageChange}
                pagesTotal={paging.pagesTotal}
              />
            </Pagination>
          </TabPanel>
          <TabPanel>
            <JSONDisplay jsonToDisplay={searchResults} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default memo(SearchResults);
