import React from "react";

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";

import JSONDisplay from "../../components/JSONDisplay";
import Booking from "../../components/Booking/Booking";

function Cancellation({ cancellation }) {
  return (
    <Box>
      {cancellation.confirmed && (
        <Alert mb={4} status="success">
          <AlertIcon />
          <Box flex="1">
            <AlertTitle fontSize="lg">Cancellation Complete</AlertTitle>
            <AlertDescription display="block" my={2}>
              <Text>Reason: {cancellation.reason}</Text>
              <Text>See below for more details.</Text>
            </AlertDescription>
          </Box>
        </Alert>
      )}
      {cancellation.failed && (
        <Alert mb={4} status="error">
          <AlertIcon />
          Cancellation Failed
        </Alert>
      )}
      {!cancellation.failed && !cancellation.confirmed && (
        <Alert mb={4} status="info">
          <AlertIcon />
          Cancellation In Progress
        </Alert>
      )}
      <Tabs isFitted variant="enclosed-colored">
        <TabList mb="1em">
          {cancellation.paging ? (
            <Tab>
              {cancellation.paging.resultsPerPage} results per page /{" "}
              {cancellation.paging.pagesTotal} page(s)
            </Tab>
          ) : (
            <Tab>Bookings</Tab>
          )}
          <Tab>JSON</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {!_.isEmpty(cancellation.booking) ? (
              <Booking
                booking={cancellation.booking}
                key={cancellation.booking.id}
                fromCancellation={true}
              />
            ) : null}
          </TabPanel>
          <TabPanel>
            <JSONDisplay jsonToDisplay={cancellation.booking} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default Cancellation;
