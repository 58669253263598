import { memo } from "react";

import { Box, Flex, Heading, Text} from "@chakra-ui/react";
import { IProductState } from "../../../types/ProductTypes";

function Categories({
  categories,
  handleAddCategory,
}: {
  categories: IProductState["categories"] | undefined;
  handleAddCategory: (a: string) => void;
}) {
  return (
    <Box>
      <Heading as="h3" mb={4} size="lg">
        Categories
      </Heading>
      {categories && categories.length > 0 && (
        <Box h="350px" mb={8} overflowY="auto" w="100%">
          {categories.map((category) => (
            <Flex
              cursor="pointer"
              key={category.id}
              onClick={() => handleAddCategory(`${category.id}`)}
            >
              <Box w='10%'>
                <Text color="gray.800" textAlign={'right'} mr={3}>
                  {category.id}
                </Text>
              </Box>
              <Box w='90%'>
                <Text color="gray.900">{category.name}</Text>
              </Box>
            </Flex>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default memo(
  Categories,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.categories) ===
    JSON.stringify(nextProps.categories)
);
