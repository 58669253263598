import React from "react";

import { GridItem, Text, Tooltip } from "@chakra-ui/react";
import _ from "lodash";

function SourceDetails({ details }) {
  return (
    <React.Fragment>
      {!_.isEmpty(details.supplier) ? (
        <GridItem>
          <Text color="gray.800" fontSize="xs" fontWeight="semibold">
            Supplier
          </Text>
          {details.supplier.name ? (
            <Tooltip label={details.supplier.id ? details.supplier.id : ""}>
              <Text color="gray.800" fontSize="sm">
                {details.supplier.name}
              </Text>
            </Tooltip>
          ) : null}
          {details.supplier.resSystem ? (
            <Text color="gray.800" fontSize="sm">
              {details.supplier.resSystem}
            </Text>
          ) : null}
        </GridItem>
      ) : null}
      {!_.isEmpty(details.distributor) ? (
        <GridItem>
          <Text color="gray.800" fontSize="xs" fontWeight="semibold">
            Distributor
          </Text>
          {details.distributor.name ? (
            <Tooltip
              label={details.distributor.id ? details.distributor.id : ""}
            >
              <Text color="gray.800" fontSize="sm">
                {details.distributor.name}
              </Text>
            </Tooltip>
          ) : null}
        </GridItem>
      ) : null}
      {!_.isEmpty(details.distributor) &&
      !_.isEmpty(details.distributor.company) ? (
        <GridItem>
          <Text color="gray.800" fontSize="xs" fontWeight="semibold">
            Company
          </Text>
          {details.distributor.company.name ? (
            <Tooltip
              label={
                details.distributor.company.id
                  ? details.distributor.company.id
                  : ""
              }
            >
              <Text color="gray.800" fontSize="sm">
                {details.distributor.company.name}
              </Text>
            </Tooltip>
          ) : null}
        </GridItem>
      ) : null}
      {!_.isEmpty(details.catalogue) ? (
        <GridItem>
          <Text color="gray.800" fontSize="xs" fontWeight="semibold">
            Catalogue
          </Text>
          {details.catalogue.name ? (
            <Tooltip label={details.catalogue.id ? details.catalogue.id : ""}>
              <Text color="gray.800" fontSize="sm">
                {details.catalogue.name}
              </Text>
            </Tooltip>
          ) : null}
        </GridItem>
      ) : null}
    </React.Fragment>
  );
}

export default SourceDetails;
