import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { animateScroll as scroll } from "react-scroll";
import { DateTime } from "luxon";

import axios from "../../configs/axios-config";
import { handleError } from "../Error/errorSlice";
import { IProductState } from "../../types/ProductTypes";
import { addLog } from "../LogConsole/logConsoleSlice";

const initialState: IProductState = {
  loading: "idle",
  categories: undefined,
  departures: undefined,
  details: undefined,
  searchResults: undefined,
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    productClear(state) {
      state.loading = "idle";
      state.categories = undefined;
      state.departures = undefined;
      state.details = undefined;
    },
    clearProductDepartures(state) {
      state.loading = "idle";
      state.departures = undefined;
    },
    productLoading(state, action: PayloadAction<undefined | string>) {
      state.loading =
        state.loading === "idle"
          ? action.payload
            ? action.payload
            : "pending"
          : "idle";
    },
    updateDepartures(state, action) {
      state.loading = "idle";
      state.departures = action.payload;
    },
    updateProduct(state, action) {
      state.loading = "idle";
      state.departures = undefined;
      state.details = action.payload;
    },
    updateProductCategories(state, action) {
      state.loading = "idle";
      state.categories = action.payload;
    },
    updateProductSearch(state, action) {
      state.loading = "idle";
      state.searchResults = action.payload.searchResults;
    },
    productSearchClear(state) {
      state.loading = "idle";
      state.searchResults = undefined;
    },
  },
});

const { actions, reducer } = productSlice;
export const {
  productClear,
  productLoading,
  clearProductDepartures,
  updateDepartures,
  updateProduct,
  updateProductCategories,
  updateProductSearch,
  productSearchClear
} = actions;
export default reducer;

export const getProduct = (productId) => (dispatch) => {
  dispatch(productClear());
  dispatch(productLoading("searching"));
  axios
    .get(`/products/${productId}`)
    .then((response) => {
      dispatch(addLog({ timestamp: DateTime.utc().toISO(), description: 'Get Product', url: `/products/${productId}`, processId: `${response.headers['x-process-id']}` }));
      dispatch(updateProduct(response.data));
    })
    .catch((err) => {
      dispatch(productLoading());
      dispatch(handleError(err));
    });
};

export const getProductCategories = () => (dispatch) => {
  dispatch(productLoading());
  axios
    .get("/products/categories")
    .then((response) => {
      dispatch(updateProductCategories(response.data));
    })
    .catch((err) => {
      dispatch(productLoading());
      dispatch(handleError(err));
    });
};

export const getProductDepartures = (productId, fromProdDetails) => (dispatch) => {
  dispatch(clearProductDepartures());
  dispatch(productLoading("searchingDepartures"));
  axios
    .get(`/products/${productId}/departures`)
    .then((response) => {
      if (fromProdDetails) {
        scroll.scrollTo(500);
      }
      dispatch(addLog({ timestamp: DateTime.utc().toISO(), description: 'Get Product departures', url: `/products/${productId}/departures`, processId: `${response.headers['x-process-id']}` }));
      dispatch(updateDepartures(response.data));
    })
    .catch((err) => {
      dispatch(productLoading());
      dispatch(handleError(err));
    });
};

export const getProductSearch = (searchId, currentPage, resultsPerPage) => (dispatch) => {
  dispatch(productLoading());
  axios
    .get(`/products/search/${searchId}`, {
      params: {
        currentPage,
        resultsPerPage
      },
    })
    .then((response) => {
      dispatch(addLog({ timestamp: DateTime.utc().toISO(), description: 'Retrieved existing Product search', url: `/products/search/${searchId}`, processId: `${response.headers['x-process-id']}` }));
      scroll.scrollTo(800);
      dispatch(
        updateProductSearch({
          paging: {
            currentPage: currentPage ? currentPage : 1,
          },
          searchResults: response.data,
        })
      );
    })
    .catch((err) => {
      dispatch(productLoading());
      dispatch(handleError(err));
    });
};

export const postProductSearch = (searchCriteria) => (dispatch) => {
  dispatch(productLoading("searching"));
  axios
    .post("/products/search", {
      ...searchCriteria,
      paging: {
        resultsPerPage: searchCriteria.paging.resultsPerPage,
        currentPage: 1
      },
    })
    .then((response) => {
      dispatch(addLog({ timestamp: DateTime.utc().toISO(), description: 'Searched for products', url: `/products/search`, processId: `${response.headers['x-process-id']}` }));
      scroll.scrollTo(800);
      dispatch(
        updateProductSearch({
          searchResults: response.data,
        })
      );
    })
    .catch((err) => {
      dispatch(productLoading());
      dispatch(handleError(err));
    });
};
