import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { animateScroll as scroll } from "react-scroll";
import axios from "../../configs/axios-config";
import { ISupplierState } from "../../types/SupplierTypes";
import { handleError } from "../Error/errorSlice";
import { DateTime } from "luxon";
import { addLog } from "../LogConsole/logConsoleSlice";

const initialState: ISupplierState = {
  loading: "idle",
  details: undefined,
  searchResults: undefined,
};

export const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    supplierClear(state) {
      state.loading = "idle";
      state.details = undefined;
    },
    supplierLoading(state, action: PayloadAction<undefined | string>) {
      state.loading = state.loading === "idle" ? action.payload ? action.payload : "pending" : "idle";     
    },
    updateSupplier(state, action) {
      state.loading = "idle";
      state.details = action.payload;
    },
    updateSupplierList(state, action) {
      state.loading = "idle";
      state.searchResults = action.payload.searchResults;
    },
    supplierSearchClear(state) {
      state.loading = "idle";
      state.searchResults = undefined;
    },
  },
});

const { actions, reducer } = supplierSlice;
export const {
  supplierClear,
  supplierLoading,
  updateSupplier,
  updateSupplierList,
  supplierSearchClear
} = actions;
export default reducer;

export const getSupplier = (supplierId) => (dispatch) => {
  dispatch(supplierClear());
  dispatch(supplierLoading("searching"));
  axios
    .get(`/suppliers/${supplierId}`)
    .then((response) => {
      dispatch(addLog({ timestamp: DateTime.utc().toISO(), description: 'Get Supplier', url: `/suppliers/${supplierId}`, processId: `${response.headers['x-process-id']}` }));
      dispatch(updateSupplier(response.data));
    })
    .catch((err) => {
      dispatch(supplierLoading());
      dispatch(handleError(err));
    });
};

export const getSupplierSearch = (searchId, currentPage, resultsPerPage) => (dispatch) => {
  dispatch(supplierLoading());
  axios
    .get(`/suppliers/search/${searchId}`, {
      params: {
        currentPage,
        resultsPerPage
      },
    })
    .then((response) => {
      dispatch(addLog({ timestamp: DateTime.utc().toISO(), description: 'Retrieved existing supplier search', url: `/suppliers/search/${searchId}`, processId: `${response.headers['x-process-id']}` }));
      dispatch(
        updateSupplierList({
          paging: {
            currentPage: currentPage ? currentPage : 1,
          },
          searchResults: response.data,
        })
      );
    })
    .catch((err) => {
      dispatch(supplierLoading());
      dispatch(handleError(err));
    });
};

export const getSuppliers = (searchCriteria) => (dispatch) => {
  dispatch(supplierLoading("searching"));
  axios
    .post("/suppliers/search", {
      ...searchCriteria,
      paging: {
        resultsPerPage: searchCriteria.paging.resultsPerPage,
        currentPage: searchCriteria.paging.currentPage
      },
    })
    .then((response) => {
      scroll.scrollTo(400);
      dispatch(addLog({ timestamp: DateTime.utc().toISO(), description: 'Searched for suppliers', url: `/suppliers/search`, processId: `${response.headers['x-process-id']}` }));
      dispatch(
        updateSupplierList({
          searchResults: response.data,
        })
      );
    })
    .catch((err) => {
      dispatch(supplierLoading());
      dispatch(handleError(err));
    });
};
