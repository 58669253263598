import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Box, Button, SimpleGrid, Text, Tooltip } from "@chakra-ui/react";
import { DateTime, Interval } from "luxon";

import { postLogout } from "../../features/Login/loginSlice";
import LogConsole from "../LogConsole/LogConsole";

function LoginUser({ login }) {

  const calculateTimeLeft = () => {
    if (login.status === "LOGGED_IN" && login.expiry) {
      if (DateTime.fromISO(login.expiry) > DateTime.now().setLocale("en-au")) {
        return Interval.fromDateTimes(
          DateTime.now().setLocale("en-au"),
          DateTime.fromISO(login.expiry)
        ).toDuration();
      } else {
        return "EXPIRED";
      }
    } else {
      return "";
    }
  };

  const [expires, setExpires] = useState();
  useEffect(() => {
    const timer = setTimeout(() => {
      if (expires === "EXPIRED") {
        dispatch(postLogout());
      } else {
        setExpires(calculateTimeLeft());
      }
    }, 1000);
    return () => clearTimeout(timer);
  });

  const dispatch = useDispatch();
  const handleLogout = () => dispatch(postLogout());

  return (
    <SimpleGrid columns={2} spacing={4}>
      <Box alignSelf="center">
        <Text color="blue.100" fontSize="md">
          Logged in as{" "}
          <strong>
            <Tooltip label={login.satellite}>{login.username}</Tooltip>
          </strong>
        </Text>
        {expires && expires !== "EXPIRED" ? (
          <Text color="blue.200" fontSize="md">
            JWT Expires: {expires.toFormat("hh:mm")}
          </Text>
        ) : null}
      </Box>
      <Button
        colorScheme="whiteAlpha"
        isLoading={login.loading === "pending"}
        loadingText="Logging out"
        variant='solid'
        onClick={() => handleLogout()}        
        w="100%"
      >
        Logout
      </Button>
      <LogConsole />
    </SimpleGrid>
  );
}

export default LoginUser;
