import React from "react";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  GridItem,
  Heading,
  Text,
} from "@chakra-ui/react";

import JSONDisplay from "../../../components/JSONDisplay";

function FlowJSON({ flow }) {
  return (
    <GridItem colSpan={6}>
      <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Heading as="h3" size="lg">
                The Flow
                <Text align="left" color="gray.800" fontSize="xs">
                  JSON response
                </Text>
              </Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <JSONDisplay jsonToDisplay={flow} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </GridItem>
  );
}

export default FlowJSON;
