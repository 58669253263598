import { useDispatch } from "react-redux";
import { InfoOutlineIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  Tooltip,
  GridItem,
  Input,
  Select,
} from "@chakra-ui/react";
import copy from "copy-to-clipboard";
import { motion } from "framer-motion";

import { divVariants } from "../../configs/pageMotions";

import { getTickets } from "./ticketSlice";

function SearchForm({
  form,
  handleClearSearchForm,
  handleInputChange,
  loading,
}) {
  const dispatch = useDispatch();
  const prepareSearchForm = () => {
    let temp = {}
    for (const key in form) {
      if (form[key] !== "" && form[key] !== null && form[key] !== undefined) {
        temp[key] = form[key];
      }
    }
    return {
      ...temp,
      resultsPerPage: parseInt(form.resultsPerPage, 10) || 100,
      currentPage: parseInt(form.currentPage, 10) || 1
    };
  };
  const handleGetTicket = () => {
    dispatch(getTickets(prepareSearchForm()))
  };
  const handleCopyQueryString = () => copy(Object.keys(prepareSearchForm()).map(key => key + '=' + prepareSearchForm()[key]).join('&'));

  return (
    <GridItem colSpan={6}>
      <motion.div variants={divVariants}>
        <Grid alignItems="top" gap={4} templateColumns="repeat(4, 1fr)">
          <GridItem>
            <FormControl id="anyReference">
              <FormLabel>anyReference
                <Tooltip label="Ticket reference to retrieve. Could be the id, supplierReference, or passenger name.">
                  <InfoOutlineIcon color="gray.800" ml={2} />
                </Tooltip>
              </FormLabel>
              <Input
                name="anyReference"
                onChange={handleInputChange}
                value={form.anyReference}
                w="100%"
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="id">
              <FormLabel>id
                <Tooltip label="Ticket.id to retrieve.">
                  <InfoOutlineIcon color="gray.800" ml={2} />
                </Tooltip>
              </FormLabel>
              <Input
                name="id"
                onChange={handleInputChange}
                value={form.id}
                w="100%"
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="order">
              <FormLabel>order
                <Tooltip label="Specify the sort order of the returned results.">
                  <InfoOutlineIcon color="gray.800" ml={2} />
                </Tooltip>
              </FormLabel>
              <Select
                name="order"
                onChange={handleInputChange}
                placeholder="Select order"
                value={form.order}
                w="100%"
              >
                <option value="ID">ID</option>
                <option value="PASSENGER_NAME">PASSENGER_NAME</option>
                <option value="SUPPLIER_REFERENCE">SUPPLIER_REFERENCE</option>
              </Select>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="orderDescending">
              <Checkbox
                name="orderDescending"
                mb={2}
                mt={10}
                onChange={handleInputChange}
                isChecked={form.orderDescending}
                value={form.orderDescending}
              >
                orderDescending
                <Tooltip label="If True, Reverse the default ascending order direction.">
                  <InfoOutlineIcon color="gray.800" ml={2} />
                </Tooltip>
              </Checkbox>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="passengerName">
              <FormLabel>passengerName
                <Tooltip label="Any passenger name associated with the ticket to retrieve.">
                  <InfoOutlineIcon color="gray.800" ml={2} />
                </Tooltip>
              </FormLabel>
              <Input
                name="passengerName"
                onChange={handleInputChange}
                value={form.passengerName}
                w="100%"
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="supplierReference">
              <FormLabel>supplierReference
                <Tooltip label="Ticket.supplierReference to retrieve.">
                  <InfoOutlineIcon color="gray.800" ml={2} />
                </Tooltip>
              </FormLabel>
              <Input
                name="supplierReference"
                onChange={handleInputChange}
                value={form.supplierReference}
                w="100%"
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="resultsPerPage">
              <FormLabel>resultsPerPage
                <Tooltip label="The number of results on each page of the requested search.">
                  <InfoOutlineIcon color="gray.800" ml={2} />
                </Tooltip>
              </FormLabel>
              <Input
                name="resultsPerPage"
                onChange={handleInputChange}
                value={form.resultsPerPage}
                w="100%"
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="currentPage">
              <FormLabel>currentPage
                <Tooltip label="The current page to view from the requested search.">
                  <InfoOutlineIcon color="gray.800" ml={2} />
                </Tooltip>
              </FormLabel>
              <Input
                name="currentPage"
                onChange={handleInputChange}
                value={form.currentPage}
                w="100%"
              />
            </FormControl>
          </GridItem>
        </Grid>
        <Grid
          alignItems="top"
          gap={4}
          mt={4}
          templateColumns="repeat(3, 1fr)"
          w="100%"
        >
          <GridItem>
            <Button
              mt={4}
              onClick={() => handleClearSearchForm()}
              variant="outline"
              w="100%"
            >
              Clear form
            </Button>
          </GridItem>
          <GridItem>
            <Button
              mt={4}
              onClick={() => handleCopyQueryString()}
              variant="outline"
              w="100%"
            >
              Copy query string
            </Button>
          </GridItem>
          <GridItem>
            <Button
              leftIcon={<SearchIcon />}
              colorScheme="green"
              isLoading={loading === "searching"}
              loadingText="Searching"
              mt={4}
              onClick={() => handleGetTicket()}
              w="100%"
            >
              Search
            </Button>
          </GridItem>
        </Grid>
      </motion.div>
    </GridItem>
  );
}

export default SearchForm;
