import React from "react";
import {
  Box,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";

import { default as CancellationPolicyComponent } from "../../../../components/CancellationPolicy/CancellationPolicy";
import JSONDisplay from "../../../../components/JSONDisplay";

function CancellationPolicy({ cancellationPolicy }) {
  return (
    <Box>
      <Heading as="h4" mb={4} size="md">
        Cancellation Policy
      </Heading>
      <Tabs isFitted variant="enclosed-colored">
        <TabList mb="1em">
          <Tab>Cancellation Policy</Tab>
          <Tab>JSON</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <CancellationPolicyComponent
              cancellationPolicy={cancellationPolicy}
            />
          </TabPanel>
          <TabPanel>
            <JSONDisplay jsonToDisplay={cancellationPolicy} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default CancellationPolicy;
