import { useState } from "react";
import { useDispatch } from "react-redux";

import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  SimpleGrid,
  Tooltip
} from "@chakra-ui/react";

import { getProductSearch } from "../productSlice";

function GetSearch({ loading }) {
  const [form, setForm] = useState({});
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const dispatch = useDispatch();
  const handleGetProductSearch = () =>
    dispatch(getProductSearch(form.searchId, 1, 100)); //this is to get an exisitng search by id

  return (
    <Box>
      <Heading as="h3" mb={4} size="lg">
        Get Search
      </Heading>
      <SimpleGrid columns={1} spacing={4}>
        <FormControl id="searchId" isRequired={true}>
          <FormLabel>Search id
            <Tooltip label="The unique identifier (UUID) initially assigned to the product search.">
              <InfoOutlineIcon color="gray.800" ml={2} />
            </Tooltip>
          </FormLabel>
          <Input
            name="searchId"
            onChange={handleInputChange}
            value={form.searchId}
            w="100%"
          />
        </FormControl>
        <Button
          colorScheme="blue"
          disabled={form.searchId ? false : true}
          isLoading={loading === "pending"}
          loadingText="Searching"
          mt={4}
          onClick={() => handleGetProductSearch()}
          w="100%"
        >
          Get
        </Button>
      </SimpleGrid>
    </Box>
  );
}

export default GetSearch;
