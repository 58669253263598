import { extendTheme } from "@chakra-ui/react";
import "@fontsource/montserrat";
import "@fontsource/roboto-mono";


export const theme = extendTheme({
  styles: {
    global: {
      body: {
        color: "#525775",
        fontFamily: "roboto mono",
        fontSize: "0.9rem",
      },
    },
  },
  fontSizes: {
    default: "0.9rem",
    xs: "0.70rem",
    sm: "0.75rem",
    md: "0.9rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
  colors: {
    gray: {
      100: "#fcfcfd",
      200: "#f0f1f4",
      300: "#e7e8ee",
      400: "#d8dae4",
      500: "#cccedb",
      600: "#b7bacd",
      700: "#9ca0ba",
      800: "#7e83a5",
      900: "#525775",
    },
    blue: {
      100: "#f2f8fc",
      200: "#c5e0f2",
      300: "#97c7e8",
      400: "#69afde",
      500: "#3794d3",
      600: "#2779af",
      700: "#1d5981",
      800: "#133c58",
      900: "#091d2a",
    },
    green: {
      100: "#f2fdf8",
      200: "#b6f2d9",
      300: "#7de8bb",
      400: "#41dd9c",
      500: "#21b577",
      600: "#1b9361",
      700: "#14704a",
      800: "#0e4e33",
      900: "#082b1c",
    },
    orange: {
      100: "#ff8e62",
      200: "#ff8458",
      300: "#ff7a4e",
      400: "#f87044",
      500: "#ee663a",
      600: "#e45c30",
      700: "#da5226",
      800: "#d0481c",
      900: "#c63e12",
    },
    red: {
      100: "#fff1f0",
      200: "#fca29c",
      300: "#fa5348",
      400: "#df1506",
      500: "#8b0d04",
      600: "#770b03",
      700: "#5e0903",
      800: "#4a0702",
      900: "#320501",
    },
    yellow: {
      100: "#fefbf0",
      200: "#fbeab1",
      300: "#f9d971",
      400: "#f6c832",
      500: "#e1ae0a",
      600: "#b58c08",
      700: "#896a06",
      800: "#5d4804",
      900: "#312602",
    },
  },
  components: {
    Input: {
      variants: {
        outline: {
          field: {
            borderColor: "gray.400",
          }
        }
      }
    },
    List: {
      baseStyle: {
        fontSize: "1rem",
      }
    }
  }
});
