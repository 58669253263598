import React from "react";
import { useSelector } from "react-redux";

import { Grid, GridItem } from "@chakra-ui/react";
import { motion } from "framer-motion";
import _ from "lodash";

import { divVariants } from "../../configs/pageMotions";

import Loading from "../../components/Loading/Loading";
import CancelBooking from "./CancelBooking";
import Cancellation from "./Cancellation";
import SearchResults from "../Booking/Search/SearchResults";
import Search from "../Booking/Search/Search";

function Booking() {
  const booking = useSelector(({ booking }) => booking);

  return (
    <motion.div initial="exit" animate="enter" exit="exit" w="100%">
      <Grid templateColumns="repeat(6, 1fr)" minW={{ xl: "1200px" }} gap={12} w="100%">
        <Search />
        <CancelBooking loading={booking.loading} />
        {booking.loading === "pending" ? (
          <Loading colSpan={6} text="Retrieving bookings..." />
        ) : null}

        {!_.isEmpty(booking.cancellation) && booking.loading === "idle" ? (
          <GridItem colSpan={6}>
            <motion.div variants={divVariants}>
              <Cancellation cancellation={booking.cancellation} />
            </motion.div>
          </GridItem>
        ) : null}

        {!_.isEmpty(booking.searchResults) && booking.loading === "idle" ? (
          <GridItem colSpan={6}>
            <motion.div variants={divVariants}>
              <SearchResults searchResults={booking.searchResults} />
            </motion.div>
          </GridItem>
        ) : null}
      </Grid>
    </motion.div>
  );
}

export default Booking;
