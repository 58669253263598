import React from "react";

import { Tr, Td } from "@chakra-ui/react";

function LineItem({ item }) {
  return (
    <Tr>
      <Td>{item.title}</Td>
      <Td isNumeric>{item.quantity}</Td>
      <Td isNumeric>
        {item.netPerUnit ?
          item.netPerUnit.amount.toLocaleString("en-au", {
            style: "currency",
            currency: item.netPerUnit.currency,
            currencyDisplay: "code"
          }) : null}
      </Td>
      <Td isNumeric>
        {item.netTotal ?
          item.netTotal.amount.toLocaleString("en-au", {
            style: "currency",
            currency: item.netTotal.currency,
            currencyDisplay: "code"
          }) : null}
      </Td>
      <Td isNumeric>
        {item.commissionTotal ?
          item.commissionTotal.amount.toLocaleString("en-au", {
            style: "currency",
            currency: item.commissionTotal.currency,
            currencyDisplay: "code"
          }) : null}
      </Td>
      <Td isNumeric>
        {item.grossPerUnit ?
          item.grossPerUnit.amount.toLocaleString("en-au", {
            style: "currency",
            currency: item.grossPerUnit.currency,
            currencyDisplay: "code"
          }) : null}
      </Td>
      <Td isNumeric>
        {item.grossTotal ?
          item.grossTotal.amount.toLocaleString("en-au", {
            style: "currency",
            currency: item.grossTotal.currency,
            currencyDisplay: "code"
          }) : null}
      </Td>
    </Tr>
  );
}

export default LineItem;
