import React from "react";

import { Box, Flex, GridItem, HStack, Text } from "@chakra-ui/react";
import { countries } from "countries-list";

function Location({ location }) {
  return (
    <GridItem>
      <Box border="1px" borderColor="gray.400" gap={4} h="100%" p={4} w="100%">
        {location.longitude && location.latitude ? (
          <Text color="gray.700" fontSize="sm" mb={2}>
            Lat: {location.latitude}, Long: {location.longitude}
          </Text>
        ) : null}
        {location.landmark ? (
          <Flex align="flex-end" justify="space-between">
            <Text color="gray.900">{location.landmark}</Text>
            <Text color="gray.500" fontSize="xs">
              landmark
            </Text>
          </Flex>
        ) : null}
        {location.business ? (
          <Flex align="flex-end" justify="space-between">
            <Text color="gray.900">{location.business}</Text>
            <Text color="gray.500" fontSize="xs">
              business
            </Text>
          </Flex>
        ) : null}
        {location.building ? (
          <Flex align="flex-end" justify="space-between">
            <Text color="gray.900">{location.building}</Text>
            <Text color="gray.500" fontSize="xs">
              building
            </Text>
          </Flex>
        ) : null}
        {location.address1 ? (
          <Flex align="flex-end" justify="space-between">
            <Text color="gray.900">{location.address1}</Text>
            <Text color="gray.500" fontSize="xs">
              address1
            </Text>
          </Flex>
        ) : null}
        {location.address2 ? (
          <Flex align="flex-end" justify="space-between">
            <Text color="gray.900">{location.address2}</Text>
            <Text color="gray.500" fontSize="xs">
              address2
            </Text>
          </Flex>
        ) : null}
        <HStack>
          {location.city ? <Text color="gray.900">{location.city}</Text> : null}
          {location.state ? (
            <Text color="gray.900">{location.state}</Text>
          ) : null}
          {location.postcode ? (
            <Text color="gray.900">{location.postcode}</Text>
          ) : null}
        </HStack>
        <HStack>
          {location.country ? (
            <Text color="gray.900">{countries[location.country].name}</Text>
          ) : null}
        </HStack>
        <Text color="gray.700" fontSize="sm" mt={2}>
          Street Address Accuracy:{" "}
          {location.streetAddressAccuracy ? "true" : "false"}
        </Text>
      </Box>
    </GridItem>
  );
}

export default Location;
