import React from "react";

import { Box, Heading } from "@chakra-ui/react";
import _ from "lodash";

import CancellationPolicy from "../CancellationPolicy/CancellationPolicy";
import SupplierTncs from "../SupplierTncs/SupplierTncs";
import DetailsLayout from "./DetailsLayout";
import { formatDateTime } from "../../features/dateUtils";

function BookingDetails({ bookingDetails }) {
  const formattedBookingDetails = bookingDetails.confirmed
    ? { ...bookingDetails, confirmed: formatDateTime(bookingDetails.confirmed) }
    : bookingDetails;
  return (
    <Box mt={4}>
      <Heading as="h5" mb={4} size="md">
        Booking Details
      </Heading>
      <DetailsLayout details={formattedBookingDetails} />
      {!_.isEmpty(bookingDetails.generalTerms) ? (
        <SupplierTncs
          generalTerms={bookingDetails.generalTerms}
          maxHeight="200px"
        />
      ) : null}
      {!_.isEmpty(bookingDetails.cancellationPolicy) ? (
        <CancellationPolicy
          cancellationPolicy={bookingDetails.cancellationPolicy}
          maxHeight="200px"
        />
      ) : null}
    </Box>
  );
}

export default BookingDetails;
