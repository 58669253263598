import React, { useEffect, useState } from "react";

import { Box, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { DateTime, Interval } from "luxon";

function Milestone({ handleSetExpired, step }) {
  const calculateTimeLeft = () => {
    const timeLeft = Interval.fromDateTimes(
      DateTime.now().setLocale("en-au"),
      DateTime.fromISO(step.expires)
    ).toDuration();
    return timeLeft;
  };

  const [expires, setExpires] = useState(
    !step.expires
      ? "NA"
      : DateTime.fromISO(step.expires) > DateTime.now().setLocale("en-au")
      ? calculateTimeLeft()
      : "Expired"
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      const expired = !step.expires
        ? "NA"
        : DateTime.fromISO(step.expires) > DateTime.now().setLocale("en-au")
        ? calculateTimeLeft()
        : "Expired";
      setExpires(expired);
      handleSetExpired(expired === "Expired");
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <SimpleGrid columns={2} spacing={2}>
      <Heading as="h3" mb={4} size="lg">
        {step.milestone}
        <Text align="left" color="gray.800" fontSize="xs">
          Milestone
        </Text>
      </Heading>
      <Box>
        <Text align="right" color="gray.700" pr={2}>
          Created:{" "}
          {DateTime.fromISO(step.created).toFormat("yyyy-MM-dd HH:mm.ss")}
        </Text>
        <Text
          align="right"
          bg={
            expires === "Expired" ||
            (expires.isDuration && expires.as("seconds") < 120)
              ? "red.500"
              : "white"
          }
          color={
            expires === "Expired" ||
            (expires.isDuration && expires.as("seconds") < 120)
              ? "white"
              : "gray.700"
          }
          p={2}
        >
          Expires:{" "}
          {expires === "NA"
            ? "NA"
            : expires === "Expired"
            ? "Already Expired"
            : expires.isDuration && expires.as("days") >= 1
            ? expires.toFormat("dd 'days' hh:mm:ss")
            : expires.toFormat("hh:mm:ss")}
        </Text>
      </Box>
    </SimpleGrid>
  );
}

export default Milestone;
