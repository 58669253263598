import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import placeholderImage from "../../../resources/no-image.png";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Link,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { countries } from "countries-list";
import _ from "lodash";

import { getSupplier } from "../supplierSlice";
import JSONDisplay from "../../../components/JSONDisplay";
import Loading from "../../../components/Loading/Loading";

function SupplierDetails() {
  let { supplierId } = useParams();
  const supplier = useSelector(({ supplier }) => supplier);
  const { details } = supplier;
  let firstSupplier = null;
  if (supplier.details) {
    firstSupplier = details[0];
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSupplier(supplierId));
  }, [dispatch, supplierId]);

  return !_.isEmpty(firstSupplier) ? (
    <Box w="100%">
      <Box mb={4}>
        <Heading as="h2" fontWeight="semibold" pb="2.5" size="2xl">
          {firstSupplier.name}
        </Heading>
        <HStack justify="space-between">
          <Text color="gray.800" fontSize="sm">
            id: {firstSupplier.id}
          </Text>
          {firstSupplier.v1Cid ? (
            <Text color="gray.800" fontSize="sm">
              v1Cid: {firstSupplier.v1Cid}
            </Text>
          ) : null}
          <Text color="gray.800" fontSize="sm">
            disabled: {firstSupplier.disabled ? "true" : "false"}
          </Text>
          <Text color="gray.800" fontSize="sm">
            usesNetRates: {firstSupplier.usesNetRates ? "true" : "false"}
          </Text>
        </HStack>
      </Box>

      <Tabs isFitted variant="enclosed-colored">
        <TabList mb="1em">
          <Tab>Details</Tab>
          <Tab>JSON</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Grid alignItems="top" gap={4} templateColumns="repeat(12, 1fr)">
              <GridItem colSpan={3}>
                <VStack
                  alignItems="inherit"
                  h="100%"
                  justifyContent="space-between"
                  w="100%"
                >
                  {!_.isEmpty(firstSupplier.logo) ? (
                    <Box align="center" w="100%">
                      <Image
                        alt={firstSupplier.logo.title}
                        src={firstSupplier.logo.url}
                      />
                      <Text color="gray.700" fontSize="xs">
                        {firstSupplier.logo.width} x {firstSupplier.logo.height}
                      </Text>
                    </Box>
                  ) : <Image alt={supplier.name} src={placeholderImage} />}
                  <SimpleGrid bg="gray.200" gap={2} h="100%" p={4}>
                    {firstSupplier.resSystem ? (
                      <GridItem>
                        <Text color="gray.900" fontSize="sm" mb={0}>
                          {firstSupplier.resSystem}
                        </Text>
                      </GridItem>
                    ) : null}
                  </SimpleGrid>
                  <SimpleGrid bg="gray.200" gap={2} h="100%" p={4}>
                    {!_.isEmpty(firstSupplier.nameOriginal) ? (
                      <GridItem>
                        <Text
                          color="gray.700"
                          fontSize="xs"
                          fontWeight="semibold"
                        >
                          Original Name
                        </Text>
                        <Text color="gray.900" fontSize="sm">
                          {firstSupplier.nameOriginal}
                        </Text>
                      </GridItem>
                    ) : null}
                    {!_.isEmpty(firstSupplier.nameCompany) ? (
                      <GridItem>
                        <Text
                          color="gray.700"
                          fontSize="xs"
                          fontWeight="semibold"
                        >
                          Company Name
                        </Text>
                        <Text color="gray.900" fontSize="sm">
                          {firstSupplier.nameCompany}
                        </Text>
                      </GridItem>
                    ) : null}
                    {!_.isEmpty(firstSupplier.nameTradingAs) ? (
                      <GridItem>
                        <Text
                          color="gray.700"
                          fontSize="xs"
                          fontWeight="semibold"
                        >
                          Trading Name
                        </Text>
                        <Text color="gray.900" fontSize="sm">
                          {firstSupplier.nameTradingAs}
                        </Text>
                      </GridItem>
                    ) : null}
                  </SimpleGrid>
                </VStack>
              </GridItem>
              <GridItem colSpan={3}>
                <SimpleGrid bg="gray.200" gap={2} h="100%" p={4}>
                  {!_.isEmpty(firstSupplier.businessNumber) ? (
                    <GridItem>
                      <Text
                        color="gray.700"
                        fontSize="xs"
                        fontWeight="semibold"
                      >
                        Business Number
                      </Text>
                      <Text color="gray.900" fontSize="sm">
                        {firstSupplier.businessNumber}
                      </Text>
                    </GridItem>
                  ) : null}
                  {!_.isEmpty(firstSupplier.email) ? (
                    <GridItem>
                      <Text
                        color="gray.700"
                        fontSize="xs"
                        fontWeight="semibold"
                      >
                        Email
                      </Text>
                      <Link
                        href={`mailto:${firstSupplier.email}`}
                        isExternal
                        fontSize="sm"
                      >
                        {firstSupplier.email} <ExternalLinkIcon mx="2px" />
                      </Link>
                    </GridItem>
                  ) : null}
                  {!_.isEmpty(firstSupplier.emailRes) ? (
                    <GridItem>
                      <Text
                        color="gray.700"
                        fontSize="xs"
                        fontWeight="semibold"
                      >
                        Res Email
                      </Text>
                      <Link
                        href={`mailto:${firstSupplier.emailRes}`}
                        isExternal
                        fontSize="sm"
                      >
                        {firstSupplier.emailRes} <ExternalLinkIcon mx="2px" />
                      </Link>
                    </GridItem>
                  ) : null}
                  {!_.isEmpty(firstSupplier.website) ? (
                    <GridItem>
                      <Text
                        color="gray.700"
                        fontSize="xs"
                        fontWeight="semibold"
                      >
                        Website
                      </Text>
                      <Link
                        href={firstSupplier.website}
                        isExternal
                        fontSize="sm"
                      >
                        {firstSupplier.website} <ExternalLinkIcon mx="2px" />
                      </Link>
                    </GridItem>
                  ) : null}
                  <GridItem>
                    <Text color="gray.700" fontSize="xs" fontWeight="semibold">
                      Address
                    </Text>
                    <Box>
                      {firstSupplier.address1 ? (
                        <Text color="gray.900" fontSize="sm">
                          {firstSupplier.address1}
                        </Text>
                      ) : null}
                      <Text color="gray.900" fontSize="sm">
                        {firstSupplier.city ? firstSupplier.city : null}
                        {firstSupplier.state ? firstSupplier.state : null}
                        {firstSupplier.postcode ? firstSupplier.postcode : null}
                      </Text>
                      {firstSupplier.country ? (
                        <Text color="gray.900" fontSize="sm">
                          {countries[firstSupplier.country].name}
                        </Text>
                      ) : null}
                    </Box>
                  </GridItem>
                </SimpleGrid>
              </GridItem>
              <GridItem colSpan={6}>
                <Box p={4}>
                  <Heading as="h3" fontWeight="normal" mb={4} size="md">
                    Description
                  </Heading>
                  {firstSupplier.description ? (
                    <Text color="gray.900">{firstSupplier.description}</Text>
                  ) : null}
                </Box>
              </GridItem>
              <GridItem colSpan={12}>
                <Box my={4}>
                  <Heading as="h3" fontWeight="normal" mb={2} size="md">
                    Variations
                  </Heading>
                  <SimpleGrid columns={2} gap={2} h="100%" w="100%">
                    {details.map((indvdSupplier) => (
                      <SimpleGrid
                        border="1px"
                        borderColor="gray.400"
                        columns={2}
                        gap={4}
                        h="100%"
                        key={indvdSupplier.catalogueSupplierId}
                        p={4}
                      >
                        {!_.isEmpty(indvdSupplier.catalogue) ? (
                          <GridItem>
                            <Text
                              color="gray.800"
                              fontSize="sm"
                              fontWeight="semibold"
                            >
                              Catalogue
                            </Text>
                            {indvdSupplier.catalogue.name ? (
                              <Tooltip
                                label={
                                  indvdSupplier.catalogue.id
                                    ? indvdSupplier.catalogue.id
                                    : ""
                                }
                              >
                                <Text color="gray.800" fontSize="md">
                                  {indvdSupplier.catalogue.name}
                                </Text>
                              </Tooltip>
                            ) : null}
                          </GridItem>
                        ) : null}
                        {!_.isEmpty(indvdSupplier.channel) ? (
                          <GridItem>
                            <Text
                              color="gray.800"
                              fontSize="sm"
                              fontWeight="semibold"
                            >
                              Channel
                            </Text>
                            {!_.isEmpty(indvdSupplier.channel) ? (
                              <Tooltip
                                label={
                                  indvdSupplier.channel.id
                                    ? indvdSupplier.channel.id
                                    : ""
                                }
                              >
                                <Box>
                                  {indvdSupplier.channel.directConnect ? (
                                    <Text color="gray.800" fontSize="md">
                                      Direct Connect
                                    </Text>
                                  ) : null}
                                  {indvdSupplier.channel.billingCurrency ? (
                                    <Text color="gray.800" fontSize="md">
                                      {indvdSupplier.channel.billingCurrency}
                                    </Text>
                                  ) : null}
                                </Box>
                              </Tooltip>
                            ) : null}
                          </GridItem>
                        ) : null}
                        {!_.isEmpty(indvdSupplier.distributor) ? (
                          <GridItem>
                            <Text
                              color="gray.800"
                              fontSize="sm"
                              fontWeight="semibold"
                            >
                              Distributor
                            </Text>
                            {indvdSupplier.distributor.name ? (
                              <Tooltip
                                label={
                                  indvdSupplier.distributor.id
                                    ? indvdSupplier.distributor.id
                                    : ""
                                }
                              >
                                <Text color="gray.800" fontSize="md" mb={1}>
                                  {indvdSupplier.distributor.name}
                                </Text>
                              </Tooltip>
                            ) : null}
                            {!_.isEmpty(indvdSupplier.distributor.emailRes) ? (
                              <GridItem>
                                <Text color="gray.800" fontSize="xs">
                                  Res Email
                                </Text>
                                <Link
                                  color="gray.800"
                                  href={`mailto:${indvdSupplier.distributor.emailRes}`}
                                  isExternal
                                  fontSize="md"
                                >
                                  {indvdSupplier.distributor.emailRes}{" "}
                                  <ExternalLinkIcon mx="2px" />
                                </Link>
                              </GridItem>
                            ) : null}
                          </GridItem>
                        ) : null}
                        {!_.isEmpty(indvdSupplier.distributor) &&
                          !_.isEmpty(indvdSupplier.distributor.company) ? (
                          <GridItem>
                            <Text
                              color="gray.800"
                              fontSize="sm"
                              fontWeight="semibold"
                            >
                              Company
                            </Text>
                            {indvdSupplier.distributor.company.name ? (
                              <Tooltip
                                label={
                                  indvdSupplier.distributor.company.id
                                    ? indvdSupplier.distributor.company.id
                                    : ""
                                }
                              >
                                <Text color="gray.800" fontSize="md" mb={1}>
                                  {indvdSupplier.distributor.company.name}
                                </Text>
                              </Tooltip>
                            ) : null}
                            {!_.isEmpty(
                              indvdSupplier.distributor.company.phone
                            ) ? (
                              <GridItem>
                                <Text color="gray.800" fontSize="xs">
                                  Phone
                                </Text>
                                <Text color="gray.800" fontSize="sm">
                                  {indvdSupplier.distributor.company.phone}
                                </Text>
                              </GridItem>
                            ) : null}
                            {!_.isEmpty(
                              indvdSupplier.distributor.company.email
                            ) ? (
                              <GridItem>
                                <Text color="gray.800" fontSize="xs">
                                  Email
                                </Text>
                                <Link
                                  color="gray.800"
                                  href={`mailto:${indvdSupplier.distributor.company.email}`}
                                  isExternal
                                  fontSize="sm"
                                >
                                  {indvdSupplier.distributor.company.email}{" "}
                                  <ExternalLinkIcon mx="2px" />
                                </Link>
                              </GridItem>
                            ) : null}
                          </GridItem>
                        ) : null}
                      </SimpleGrid>
                    ))}
                  </SimpleGrid>
                </Box>
              </GridItem>
            </Grid>
          </TabPanel>
          <TabPanel>
            <JSONDisplay jsonToDisplay={supplier} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  ) :
    supplier.loading === "searching" ?
      <Loading text="Loading supplier..." />
      :
      <Link
        _hover={{
          textDecoration: "none",
        }}
        as={RouterLink}
        to={`/suppliers`}
      >
        <Box
          _hover={{
            bg: "blue.600",
          }}
          align="center"
          bg="blue.500"
          borderRadius="md"
          color="white"
          px={4}
          py={2.5}
          w="100%"
        >
          Return to Get Supplier
        </Box>
      </Link>;
}

export default SupplierDetails;
