import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { Box, Flex, Link, Heading, SimpleGrid, Text } from "@chakra-ui/react";

import LoginUser from "../components/LoginUser/LoginUser";

const Header = ({ login }) => {
  return (
    <Flex
      as="nav"
      fontFamily={"montserrat"}
      align="center"
      bg="blue.500"
      color="white"
      justify="space-between"
      p={8}
      pos="fixed"
      w="100%"
      wrap="wrap"
      zIndex={20}
    >
      <Flex align="center">
        <Heading as="h1" size="xl" isTruncated>
          Alice
        </Heading>
      </Flex>

      {login.status === "LOGGED_IN" ? (
        <SimpleGrid columns={4} spacing={4}>
          <Box>
            <Text color="blue.300" fontSize="md" fontWeight="semibold">
              Product Controller
            </Text>
            <Link as={RouterLink} _focus={{ boxShadow: "none" }} fontSize="md" pr={6} to="/products/search">
              Search
            </Link>
            <Link as={RouterLink} _focus={{ boxShadow: "none" }} fontSize="md" pr={6} to="/products">
              Product
            </Link>
          </Box>
          <Box>
            <Text color="blue.300" fontSize="md" fontWeight="semibold">
              Supplier Controller
            </Text>
            <Link as={RouterLink} _focus={{ boxShadow: "none" }} fontSize="md" pr={6} to="/suppliers/search">
              Search
            </Link>
            <Link as={RouterLink} _focus={{ boxShadow: "none" }} fontSize="md" pr={6} to="/suppliers">
              Supplier
            </Link>
          </Box>
          <Box>
            <Text color="blue.300" _focus={{ boxShadow: "none" }} fontSize="md" fontWeight="semibold">
              Flow Controller
            </Text>
            <Link as={RouterLink} _focus={{ boxShadow: "none" }} fontSize="md" pr={6} to="/flow">
              Checkout
            </Link>
          </Box>
          <Box>
            <Text color="blue.300" fontSize="md" fontWeight="semibold">
              Booking &amp; Ticket Controllers
            </Text>
            <Link as={RouterLink} _focus={{ boxShadow: "none" }} fontSize="md" pr={6} to="/booking">
              Bookings
            </Link>
            <Link as={RouterLink} _focus={{ boxShadow: "none" }} fontSize="md" pr={6} to="/ticket">
              Tickets
            </Link>
          </Box>
        </SimpleGrid>
      ) : null}

      {login.status === "LOGGED_IN" ? <LoginUser login={login} /> : null}
    </Flex>
  );
};

export default Header;
