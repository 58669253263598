import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { CopyIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  CloseButton,
  Flex,
  Text,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { CopyToClipboard } from "react-copy-to-clipboard";
import JSONPretty from "react-json-pretty";

import { clearError } from "./errorSlice";

const Error = (props) => {
  const error = useSelector(({ error }) => error);

  const dispatch = useDispatch();
  const handleClearError = () => dispatch(clearError());

  return error.message || error.code ? (
    <Alert mb={6} status="error" variant="left-accent">
      <AlertIcon />
      <Box flex="1">
        <AlertTitle fontSize="lg">
          {error.code ? error.code : null}{" "}
          {error.name ? error.name + ":" : null}{" "}
          {error.config && error.config.method ? error.config.method : null}{" "}
          {error.config && error.config.url ? `/api${error.config.url}` : null}
          {" @ "}
          {error.timestamp
            ? DateTime.fromISO(error.timestamp).toFormat("yyyy-MM-dd HH:mm.ss")
            : null}
        </AlertTitle>
        {error.message ? (
          <AlertDescription display="block" my={2}>
            <Flex alignItems="center">
              <CopyToClipboard text={error.message}>
                <Button
                  colorScheme="blackAlpha"
                  mr={4}
                  size="xs"
                  variant="outline"
                  w="75px"
                >
                  <CopyIcon color="black" />
                </Button>
              </CopyToClipboard>
              <Text>{error.message}</Text>
            </Flex>
          </AlertDescription>
        ) : null}
        {error.config && error.config.data ? (
          <AlertDescription display="block" my={2}>
            <Text>Data sent:</Text>
            <JSONPretty
              id="json-pretty"
              data={error.config.data}
              mainStyle="background:#fff;height:10vh;overflow:auto;white-space:pre-wrap;"
              errorStyle="background:#fff;height:10vh;overflow:auto;white-space:pre-wrap;"
              keyStyle="color:#ee663a;"
              stringStyle="color:#547cd3;"
              valueStyle="color:#dead0a;"
              booleanStyle="color:#21b679;"
            ></JSONPretty>
          </AlertDescription>
        ) : null}
        <CloseButton
          position="absolute"
          onClick={() => handleClearError()}
          right="8px"
          top="8px"
        />
      </Box>
    </Alert>
  ) : null;
};

export default Error;
