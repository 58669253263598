import React from "react";

import {
  Box,
  Grid,
  GridItem,
  SimpleGrid,
  Skeleton,
  SkeletonText,
  VStack,
} from "@chakra-ui/react";

function ProductDetailsLoading() {
  return (
    <Box border="1px" borderColor="gray.400" borderRadius="md" mb={4} p={6}>
      <Grid alignItems="top" gap={6} templateColumns="repeat(12, 1fr)">
        <GridItem colSpan={4}>
          <Skeleton borderRadius="md" h="234px" w="100%" />
        </GridItem>
        <GridItem colSpan={6}>
          <VStack
            alignItems="inherit"
            h="100%"
            justifyContent="space-between"
            w="100%"
          >
            <Box>
              <Skeleton height="25px" mb={6} />
              <SkeletonText noOfLines={7} mb={6} spacing={3} />
            </Box>
          </VStack>
        </GridItem>
        <GridItem colSpan={2}>
          <SimpleGrid gap={6}>
            <SkeletonText noOfLines={2} spacing={3} />
            <SkeletonText noOfLines={2} spacing={3} />
            <SkeletonText noOfLines={2} spacing={3} />
            <SkeletonText noOfLines={2} spacing={3} />
          </SimpleGrid>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default ProductDetailsLoading;
