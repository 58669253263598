import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { InfoOutlineIcon } from "@chakra-ui/icons";

import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Link,
  Tooltip
} from "@chakra-ui/react";
import { motion } from "framer-motion";

import { divVariants } from "../../../configs/pageMotions";

function SupplierForm() {
  const [form, setForm] = useState({
    supplierId: 1,
  });
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setForm({
      ...form,
      [name]: value,
    });
  };

  return (
    <Box>
      <motion.div variants={divVariants}>
        <Heading as="h3" mb={4} size="lg">
          Get Supplier
        </Heading>
        <Grid gap={4} templateColumns="repeat(6, 1fr)">
          <GridItem colSpan={3}>
            <FormControl id="supplierId" isRequired={true}>
              <FormLabel>Supplier id</FormLabel>
              <Input
                name="supplierId"
                onChange={handleInputChange}
                value={form.supplierId}
                w="100%"
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl id="includeDisabled">
              <Checkbox
                name="includeDisabled"
                mb={2}
                mt={10}
                onChange={handleInputChange}
                value={form.includeDisabled}
              >
               includeDisabled
                <Tooltip label="If True, return supplier even if it is not in accessible inventory.">
                  <InfoOutlineIcon color="gray.800" ml={2} />
                </Tooltip>
              </Checkbox>
            </FormControl>
          </GridItem>
          <GridItem colSpan={2}>
            <Box mt={7}>
              <Link
                _hover={{
                  textDecoration: "none",
                }}
                as={RouterLink}
                to={`/supplier/details/${form.supplierId}`}
              >
                <Box
                  _hover={{
                    bg: "blue.600",
                  }}
                  align="center"
                  bg="blue.500"
                  borderRadius="md"
                  color="white"
                  px={4}
                  py={2.5}
                  w="100%"
                >
                  Get
                </Box>
              </Link>
            </Box>
          </GridItem>
        </Grid>
      </motion.div>
    </Box>
  );
}

export default SupplierForm;
