import React from "react";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, ButtonGroup, IconButton } from "@chakra-ui/react";

function Pagination({
  currentPage,
  handlePageChange,
  children: pageButtons,
  pagesTotal,
}) {
  return (
    <Box align="center" mb={6}>
      <ButtonGroup isAttached variant="outline">
        <IconButton
          aria-label="Back"
          colorScheme="blue"
          disabled={currentPage === 1}
          icon={<ChevronLeftIcon />}
          mr="-px"
          onClick={() => handlePageChange(currentPage - 1)}
        />
        {pageButtons}
        <IconButton
          aria-label="Next"
          colorScheme="blue"
          disabled={currentPage === pagesTotal}
          icon={<ChevronRightIcon />}
          onClick={() => handlePageChange(currentPage + 1)}
        />
      </ButtonGroup>
    </Box>
  );
}

export default Pagination;
