import React from "react";

import { TimeIcon } from "@chakra-ui/icons";
import { Box, HStack, Text } from "@chakra-ui/react";
import { Duration as LuxonDuration } from "luxon";

const humaniseDuration = (duration) => {
  const durationObject = LuxonDuration.fromObject({ minutes: duration });
  if (durationObject.as("days") % 1 === 0) {
    return [durationObject.as("days"), "days"];
  } else if (durationObject.as("hours") % 1 === 0) {
    return [durationObject.as("hours"), "hours"];
  } else {
    return [durationObject.as("minutes"), "minutes"];
  }
};

const generateDurationString = (duration, durationRangeMax) => {
  if (!duration) return undefined;
  const [durationAmount, durationType] = humaniseDuration(duration);
  if (!durationRangeMax) {
    return `${durationAmount} ${durationType}`;
  }
  const [durationMaxAmount, durationMaxType] =
    humaniseDuration(durationRangeMax);
  return `${durationAmount} ${
    durationType !== durationMaxType ? durationType : ""
  } - ${durationMaxAmount} ${durationMaxType}`;
};

function Duration({ duration, durationStr, durationRangeMax }) {
  let durationString = generateDurationString(duration, durationRangeMax);
  return (
    <HStack>
      <TimeIcon color="gray.700" />
      <Box>
        <Text color="gray.700" fontSize="sm">
          Duration
        </Text>
        {durationString && (
          <Text color="gray.700" fontWeight="semibold">
            {durationString}
          </Text>
        )}
        {durationStr ? (
          <Text color="gray.700" fontWeight="semibold">
            {durationStr}
          </Text>
        ) : null}
      </Box>
    </HStack>
  );
}

export default Duration;
