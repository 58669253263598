import { Box, Flex } from "@chakra-ui/react";
import _ from "lodash";

import BarcodeDetails from "./BarcodeDetails";
import BookingDetails from "./BookingDetails";
import PickupDetails from "./PickupDetails";
import ProductDetails from "./ProductDetails";
import PassengerDetails from "./PassengerDetails";
import TicketDetails from "./TicketDetails";
import { PDFDownload } from "../PDFDownload/PDFDownload";

function TicketItem({ ticket }) {
  return (
    <Box>
      <Flex align="center" mb={4}>
        <PDFDownload id={ticket.id} type={"tickets"} />
      </Flex>
      <Box
        border="1px"
        borderColor="gray.400"
        borderRadius="md"
        key={ticket.id}
        mb={4}
        p={4}
      >
        {!!ticket && <TicketDetails ticket={ticket} />}
        {!_.isEmpty(ticket.bookingDetails) ? (
          <BookingDetails bookingDetails={ticket.bookingDetails} />
        ) : null}
        {!_.isEmpty(ticket.passengerDetails) ? (
          <PassengerDetails passengerDetails={ticket.passengerDetails} />
        ) : null}
        {!_.isEmpty(ticket.productDetails) ? (
          <ProductDetails productDetails={ticket.productDetails} />
        ) : null}
        {!_.isEmpty(ticket.pickupDetails) ? (
          <PickupDetails pickupDetails={ticket.pickupDetails} />
        ) : null}
        {!_.isEmpty(ticket.barcodeDetails) ? (
          <BarcodeDetails barcodeDetails={ticket.barcodeDetails} />
        ) : null}
      </Box>
    </Box>
  );
}

export default TicketItem;
