import React from "react";

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  GridItem,
  Heading,
  Input,
  Select,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
} from "@chakra-ui/react";
import Multiselect from 'multiselect-react-dropdown';
import _ from "lodash";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "../date-picker.css";

import QuestionsJSON from "./Components/QuestionsJSON";

function QuestionsFull({
  checkoutFlow,
  expired,
  fareSelections,
  formAnswers,
  handleBackCheckoutFlow,
  handleCopyQuestionsJSON,
  handleInputChange,
  handleMultiChange,
  handlePostCheckoutFlow,
  handlePreviewPrice,
  handleUseExampleValues,
  handleUseFirstPaxValues,
  movement: { nextStepSequenceNumber },
}) {
  const { loading, step } = checkoutFlow;  
  return (
    <Tabs isFitted variant="enclosed-colored">
      <TabList mb="1em">
        <Tab>Questions Full</Tab>
        <Tab>JSON</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Box>
            <SimpleGrid alignItems="center" columns={2} mb={4} spacing={2}>
              <GridItem>
                <Text>
                  These are the questions as a user might see them. Use the
                  button to the right to use any example values where available
                  to help prefill the form.
                </Text>
              </GridItem>
              <GridItem>
                <Button
                  colorScheme="blue"
                  mt={2}
                  onClick={() => handleUseExampleValues()}
                  variant="outline"
                  w="100%"
                >
                  Use example values
                </Button>
              </GridItem>
            </SimpleGrid>
            {!_.isEmpty(step.questions.questionGroups)
              ? step.questions.questionGroups.map((group, groupIndex) => (
                <Box key={groupIndex} my={6}>
                  {group.caption ? (
                    <Heading as="h4" mb={2} size="md">
                      {group.caption}
                    </Heading>
                  ) : null}
                  <SimpleGrid columns={2} spacing={2}>
                    {!_.isEmpty(group.questions) &&
                      group.questions.map((question, questionIndex) => {
                        let hasFeeDescription;
                        if (!_.isEmpty(question.selectOptions)) {
                          let feeDescription = [];
                          question.selectOptions.map(selectOptions => {
                            feeDescription.push(selectOptions.hasOwnProperty("feeDescription"));
                            return hasFeeDescription = (feeDescription.indexOf(true) !== -1) ? true : false;
                          })
                        }
                        if (question.uuid) {
                          return (
                            <Box key={questionIndex}>
                              <FormControl
                                id={question.uuid}
                                isRequired={question.required}
                              >
                                {question.title ? (
                                  <FormLabel>{question.title}</FormLabel>
                                ) : null}
                                {question.answerType ? (
                                  question.answerType === "DATE" ? (
                                    <DatePicker
                                      dateFormat="yyyy-MM-dd"
                                      isClearable
                                      onChange={(date) =>
                                        handleInputChange({
                                          target: {
                                            name: question.uuid,
                                            type: "date",
                                            value: date,
                                          },
                                        })
                                      }
                                      selected={formAnswers[question.uuid]}
                                    />
                                  ) : question.answerType ===
                                    "SELECT_SINGLE" ? (
                                    <>
                                      <Select
                                        name={question.uuid}
                                        onChange={handleInputChange}
                                        placeholder="Please choose one"
                                        required={question.required}
                                        value={formAnswers[question.uuid]}
                                        w="100%"
                                      >
                                        {!_.isEmpty(question.selectOptions)
                                          ? question.selectOptions.map(
                                            (option) => (
                                              hasFeeDescription ?
                                                <React.Fragment key={option.uuid}>
                                                  <option
                                                    value={option.uuid}
                                                    title={
                                                      option.feeDescription ?
                                                        `Fee Description: ${option.feeDescription}`
                                                        : null
                                                    }
                                                  >
                                                    {option.title}
                                                    {option.title &&
                                                      option.description
                                                      ? ": "
                                                      : null}
                                                    {option.description}
                                                  </option>
                                                  {option.feeDescription ? (
                                                    <option style={{ fontStyle: "italic" }} disabled>
                                                      &nbsp;
                                                      - {option.feeDescription}
                                                    </option>
                                                  ) : null}
                                                </React.Fragment> :
                                                <option
                                                  key={option.uuid}
                                                  value={option.uuid}
                                                >
                                                  {option.title}
                                                  {option.title &&
                                                    option.description
                                                    ? ": "
                                                    : null}
                                                  {option.description}
                                                </option>
                                            )
                                          )
                                          : null}
                                      </Select>
                                    </>
                                  ) : question.answerType ===
                                    "BINARY" ? (
                                    <>
                                      <label style={{ cursor: "pointer" }}>
                                        <Input
                                          variant="flushed"
                                          name={question.uuid}
                                          onChange={handleInputChange}
                                          type="file"
                                        />
                                      </label>
                                    </>
                                  ) : question.answerType ===
                                    "SELECT_MULTIPLE" ? (
                                    <>
                                      <Multiselect
                                        name={question.uuid}
                                        displayValue="title"
                                        required={question.required}
                                        isObject={true}
                                        onSelect={(e) => handleMultiChange(e)}
                                        selectedValueDecorator={(e) => e.length >= 50 ? e.substring(0, 50) + "..." : e}
                                        options={!_.isEmpty(question.selectOptions)
                                          ? question.selectOptions.map(
                                            (option) => (
                                              {
                                                key: option.uuid,
                                                title: option.title,
                                                name: question.uuid
                                              }
                                            )
                                          )
                                          : null}
                                        selectedValues={formAnswers[question.uuid] ? formAnswers[question.uuid].split(",").map((item) => {
                                          return (
                                            {
                                              name: question.uuid,
                                              key: item,
                                              title: _.find(question.selectOptions, { uuid: item }).title
                                            }
                                          )
                                        }) : ""}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      {question.answerType ===
                                        "BOOLEAN" ? (
                                        <>
                                          <label style={{ cursor: "pointer" }}>
                                            <input
                                              name={question.uuid}
                                              onChange={handleInputChange}
                                              required={question.required}
                                              value="true"
                                              type="radio"
                                              checked={formAnswers[question.uuid] === 'true' ? true : false}
                                              style={{ marginRight: 5 }}
                                            />Yes
                                          </label><br />
                                          <label style={{ cursor: "pointer" }}>
                                            <input
                                              name={question.uuid}
                                              onChange={handleInputChange}
                                              required={question.required}
                                              value="false"
                                              type="radio"
                                              checked={formAnswers[question.uuid] === 'false' ? true : false}
                                              style={{ marginRight: 5 }}
                                            />No
                                          </label>
                                        </>
                                      ) : (
                                        <>
                                          {question.multiLine ? (
                                            <Textarea
                                              name={question.uuid}
                                              onChange={handleInputChange}
                                              placeholder={question.example}
                                              type={question.answerType}
                                              value={formAnswers[question.uuid] ?? ""}
                                              w="100%"
                                            />
                                          ) : (
                                            <Input
                                              name={question.uuid}
                                              onChange={handleInputChange}
                                              placeholder={question.example}
                                              type={question.answerType}
                                              value={formAnswers[question.uuid] ?? ""}
                                              w="100%"
                                            />
                                          )}
                                        </>
                                      )}
                                    </>
                                  )
                                ) : null}
                                {question.description ? (
                                  <FormHelperText>
                                    {question.description}
                                  </FormHelperText>
                                ) : null}
                                {question.feeDescription ? (
                                  <FormHelperText>
                                    {question.feeDescription}
                                  </FormHelperText>
                                ) : null}
                                {question.defaultValue ? (
                                  <FormHelperText>
                                    {question.answerType !== "SELECT_SINGLE" ? question.defaultValue : (_.find(question.selectOptions, { 'uuid': question.defaultValue })).title} - value assumed if the question is not answered.
                                  </FormHelperText>
                                ) : null}
                                {question.regex ? (
                                  <FormHelperText>
                                    Answer should match this regex: {question.regex}
                                  </FormHelperText>
                                ) : null}
                                {(question.answerType === "INTEGER" || question.answerType === "DECIMAL" || question.answerType === "DATE") && (question.valueMin || question.valueMax) ? (
                                  <FormHelperText>
                                    value:
                                    {question.valueMin && question.valueMax ?
                                      ` (Min: ${question.valueMin} - Max: ${question.valueMax})` : question.valueMin ?
                                        ` (Min: ${question.valueMin})` : question.valueMax ?
                                          ` (Max: ${question.valueMax})` : null}

                                  </FormHelperText>
                                ) : null}
                                {(question.answerType === "TEXT") && (question.lengthMin || question.lengthMax) ? (
                                  <FormHelperText>
                                    length:
                                    {_.isNumber(question.lengthMin) && _.isNumber(question.lengthMax) ?
                                      ` (Min: ${question.lengthMin} - Max: ${question.lengthMax})` : _.isNumber(question.lengthMin) ?
                                        ` (Min: ${question.lengthMin})` : _.isNumber(question.lengthMax) ?
                                          ` (Max: ${question.lengthMax})` : null}
                                  </FormHelperText>
                                ) : null}
                                {question.selectOptions &&
                                  _.find(question.selectOptions, { 'uuid': formAnswers[question.uuid] }) !== undefined &&
                                  _.find(question.selectOptions, { 'uuid': formAnswers[question.uuid] }).followUpQuestions &&
                                  !_.isEmpty(_.find(question.selectOptions, { 'uuid': formAnswers[question.uuid] }).followUpQuestions.questionGroups)
                                  ? _.find(question.selectOptions, { 'uuid': formAnswers[question.uuid] }).followUpQuestions.questionGroups.map((group, groupIndex) => (
                                    <Box key={groupIndex} my={6}>
                                      {group.caption ? (
                                        <Heading as="h4" mb={2} size="md">
                                          {group.caption}
                                        </Heading>
                                      ) : null}
                                      <SimpleGrid columns={2} spacing={2}>
                                        {!_.isEmpty(group.questions) &&
                                          group.questions.map((question, questionIndex) => {
                                            let hasFeeDescription;
                                            if (!_.isEmpty(question.selectOptions)) {
                                              let feeDescription = [];
                                              question.selectOptions.map(selectOptions => {
                                                feeDescription.push(selectOptions.hasOwnProperty("feeDescription"));
                                                return hasFeeDescription = (feeDescription.indexOf(true) !== -1) ? true : false;
                                              })
                                            }
                                            if (question.uuid) {
                                              return (
                                                <Box key={questionIndex}>
                                                  <FormControl
                                                    id={question.uuid}
                                                    isRequired={question.required}
                                                  >
                                                    {question.title ? (
                                                      <FormLabel>{question.title}</FormLabel>
                                                    ) : null}
                                                    {question.answerType ? (
                                                      question.answerType === "DATE" ? (
                                                        <DatePicker
                                                          dateFormat="yyyy-MM-dd"
                                                          isClearable
                                                          onChange={(date) =>
                                                            handleInputChange({
                                                              target: {
                                                                name: question.uuid,
                                                                type: "date",
                                                                value: date,
                                                              },
                                                            })
                                                          }
                                                          selected={formAnswers[question.uuid]}
                                                        />
                                                      ) : question.answerType ===
                                                        "SELECT_SINGLE" ? (
                                                        <>
                                                          {hasFeeDescription ? (
                                                            <Select
                                                              name={question.uuid}
                                                              onChange={handleInputChange}
                                                              placeholder="Please choose one"
                                                              required={question.required}
                                                              value={formAnswers[question.uuid]}
                                                              w="100%"
                                                            >
                                                              {!_.isEmpty(question.selectOptions)
                                                                ? question.selectOptions.map(
                                                                  (option) => (
                                                                    <React.Fragment key={option.uuid}>
                                                                      <option
                                                                        value={option.uuid}
                                                                        title={
                                                                          option.feeDescription ?
                                                                            `Fee Description: ${option.feeDescription}`
                                                                            : null
                                                                        }
                                                                      >
                                                                        {option.title}
                                                                        {option.title &&
                                                                          option.description
                                                                          ? ": "
                                                                          : null}
                                                                        {option.description}
                                                                      </option>
                                                                      {option.feeDescription ? (
                                                                        <option style={{ fontStyle: "italic" }} disabled>
                                                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                          - {option.feeDescription}
                                                                        </option>
                                                                      ) : null}
                                                                    </React.Fragment>
                                                                  )
                                                                )
                                                                : null}
                                                            </Select>
                                                          ) : (
                                                            <Select
                                                              name={question.uuid}
                                                              onChange={handleInputChange}
                                                              placeholder="Please choose one"
                                                              required={question.required}
                                                              value={formAnswers[question.uuid]}
                                                              w="100%"
                                                            >
                                                              {!_.isEmpty(question.selectOptions)
                                                                ? question.selectOptions.map(
                                                                  (option) => (
                                                                    <option
                                                                      key={option.uuid}
                                                                      value={option.uuid}
                                                                    >
                                                                      {option.title}
                                                                      {option.title &&
                                                                        option.description
                                                                        ? ": "
                                                                        : null}
                                                                      {option.description}
                                                                    </option>
                                                                  )
                                                                )
                                                                : null}
                                                            </Select>
                                                          )}
                                                        </>
                                                      ) : question.answerType ===
                                                        "BINARY" ? (
                                                        <>
                                                          <label style={{ cursor: "pointer" }}>
                                                            <Input
                                                              name={question.uuid}
                                                              onChange={handleInputChange}
                                                              type="file"
                                                            />
                                                          </label>
                                                        </>
                                                      ) : question.answerType ===
                                                        "SELECT_MULTIPLE" ? (
                                                        <>
                                                          <Multiselect
                                                            name={question.uuid}
                                                            displayValue="title"
                                                            required={question.required}
                                                            isObject={true}
                                                            onSelect={(e) => handleMultiChange(e)}
                                                            options={!_.isEmpty(question.selectOptions)
                                                              ? question.selectOptions.map(
                                                                (option) => (
                                                                  {
                                                                    key: option.uuid,
                                                                    title: option.title,
                                                                    name: question.uuid
                                                                  }
                                                                )
                                                              )
                                                              : null}
                                                            selectedValues={formAnswers[question.uuid] ? formAnswers[question.uuid].split(",").map((item) => {
                                                              return (
                                                                {
                                                                  name: question.uuid,
                                                                  key: item,
                                                                  title: _.find(question.selectOptions, { uuid: item }).title
                                                                }
                                                              )
                                                            }) : ""}
                                                          />
                                                        </>
                                                      ) : (
                                                        <>
                                                          {question.answerType ===
                                                            "BOOLEAN" ? (
                                                            <>
                                                              <label style={{ cursor: "pointer" }}>
                                                                <input
                                                                  name={question.uuid}
                                                                  onChange={handleInputChange}
                                                                  required={question.required}
                                                                  value="true"
                                                                  type="radio"
                                                                  checked={formAnswers[question.uuid] === 'true' ? true : false}
                                                                  style={{ marginRight: 5 }}
                                                                />Yes
                                                              </label><br />
                                                              <label style={{ cursor: "pointer" }}>
                                                                <input
                                                                  name={question.uuid}
                                                                  onChange={handleInputChange}
                                                                  required={question.required}
                                                                  value="false"
                                                                  type="radio"
                                                                  checked={formAnswers[question.uuid] === 'false' ? true : false}
                                                                  style={{ marginRight: 5 }}
                                                                />No
                                                              </label>
                                                            </>
                                                          ) : (
                                                            <>
                                                              {question.multiLine ? (
                                                                <Textarea
                                                                  name={question.uuid}
                                                                  onChange={handleInputChange}
                                                                  placeholder={question.example}
                                                                  type={question.answerType}
                                                                  value={formAnswers[question.uuid] ?? ""}
                                                                  w="100%"
                                                                />
                                                              ) : (
                                                                <Input
                                                                  name={question.uuid}
                                                                  onChange={handleInputChange}
                                                                  placeholder={question.example}
                                                                  type={question.answerType}
                                                                  value={formAnswers[question.uuid] ?? ""}
                                                                  w="100%"
                                                                />
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )
                                                    ) : null}
                                                    {question.description ? (
                                                      <FormHelperText>
                                                        {question.description}
                                                      </FormHelperText>
                                                    ) : null}
                                                    {question.defaultValue ? (
                                                      <FormHelperText>
                                                        {question.defaultValue} - value assumed if the question is not answered.
                                                      </FormHelperText>
                                                    ) : null}
                                                    {question.regex ? (
                                                      <FormHelperText>
                                                        Answer should match this regex: {question.regex}
                                                      </FormHelperText>
                                                    ) : null}
                                                    {(question.answerType === "INTEGER" || question.answerType === "DECIMAL" || question.answerType === "DATE") && (question.valueMin || question.valueMax) ? (
                                                      <FormHelperText>
                                                        value:
                                                        {question.valueMin && question.valueMax ?
                                                          ` (Min: ${question.valueMin} - Max: ${question.valueMax})` : question.valueMin ?
                                                            ` (Min: ${question.valueMin})` : question.valueMax ?
                                                              ` (Max: ${question.valueMax})` : null}

                                                      </FormHelperText>
                                                    ) : null}
                                                    {(question.answerType === "TEXT") && (question.lengthMin || question.lengthMax) ? (
                                                      <FormHelperText>
                                                        length:
                                                        {_.isNumber(question.lengthMin) && _.isNumber(question.lengthMax) ?
                                                          ` (Min: ${question.lengthMin} - Max: ${question.lengthMax})` : _.isNumber(question.lengthMin) ?
                                                            ` (Min: ${question.lengthMin})` : _.isNumber(question.lengthMax) ?
                                                              ` (Max: ${question.lengthMax})` : null}
                                                      </FormHelperText>
                                                    ) : null}

                                                  </FormControl>
                                                </Box>
                                              );
                                            } else {
                                              return null;
                                            }
                                          })}
                                      </SimpleGrid>
                                      {step.milestone !== "FARE_SELECTION" &&
                                        step.stepName !== "PICKUP_DROPOFF" &&
                                        groupIndex === 0 ? (
                                        <SimpleGrid
                                          alignItems="center"
                                          columns={2}
                                          my={4}
                                          spacing={2}
                                        >
                                          <GridItem>
                                            <Text hidden={step.questions.questionGroups.length === 1 ? true : false}>
                                              Clicking the button to the right will copy the
                                              details entered for the above passenger to those
                                              below where possible.
                                            </Text>
                                          </GridItem>
                                          <GridItem>
                                            <Button
                                              colorScheme="blue"
                                              mt={2}
                                              onClick={() => handleUseFirstPaxValues()}
                                              hidden={step.questions.questionGroups.length === 1 ? true : false}
                                              variant="outline"
                                              w="100%"
                                            >
                                              Copy to other passengers
                                            </Button>
                                          </GridItem>
                                        </SimpleGrid>
                                      ) : null}
                                    </Box>
                                  ))
                                  : null}


                              </FormControl>
                            </Box>
                          );
                        } else {
                          return null;
                        }
                      })}
                  </SimpleGrid>
                  {step.milestone !== "FARE_SELECTION" &&
                    step.stepName !== "PICKUP_DROPOFF" &&
                    groupIndex === 0 ? (
                    <SimpleGrid
                      alignItems="center"
                      columns={2}
                      my={4}
                      spacing={2}
                    >
                      <GridItem>
                        <Text hidden={step.questions.questionGroups.length === 1 ? true : false}>
                          Clicking the button to the right will copy the
                          details entered for the above passenger to those
                          below where possible.
                        </Text>
                      </GridItem>
                      <GridItem>
                        <Button
                          colorScheme="blue"
                          mt={2}
                          onClick={() => handleUseFirstPaxValues()}
                          hidden={step.questions.questionGroups.length === 1 ? true : false}
                          variant="outline"
                          w="100%"
                        >
                          Copy to other passengers
                        </Button>
                      </GridItem>
                    </SimpleGrid>
                  ) : null}
                </Box>
              ))
              : null}

            <SimpleGrid columns={step.previousStepSequenceNumber ? 4 : 3} mb={4} mt={8} spacing={2}>
              {step.stepName !== "PASSENGER_NUMBER" && (
                <>
                  {step.previousStepSequenceNumber ? (
                    <Button
                      colorScheme={expired ? "red" : "gray"}
                      isLoading={loading === "pending"}
                      loadingText="Loading"
                      onClick={() => handleBackCheckoutFlow()}
                      variant="outline"
                      w="100%"
                    >
                      Back
                    </Button>
                  ) : null}
                  {step.previousStepSequenceNumber || step.stepName === "FARE_SELECTION" ? <Button
                    colorScheme="green"
                    isLoading={loading === "pending"}
                    loadingText="Posting"
                    onClick={() => handlePreviewPrice()}
                    w="100%"
                  >
                    Preview price
                  </Button> : null}
                </>
              )}
              {nextStepSequenceNumber ? (
                <Button
                  onClick={() => handleCopyQuestionsJSON()}
                  variant="outline"
                  w="100%"
                >
                  Copy request payload
                </Button>
              ) : null}
              {nextStepSequenceNumber ? (
                <Button
                  colorScheme={expired ? "red" : "green"}
                  isLoading={loading === "pending"}
                  loadingText="Posting"
                  onClick={() => handlePostCheckoutFlow()}
                  w="100%"
                >
                  Next
                </Button>
              ) : null}
            </SimpleGrid>
          </Box>
        </TabPanel>
        <TabPanel>
          <QuestionsJSON
            checkoutFlow={checkoutFlow}
            fareSelections={fareSelections}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default QuestionsFull;
