import React, { memo } from "react";

import { InfoOutlineIcon } from "@chakra-ui/icons";
import { FormControl, FormLabel, Tooltip } from "@chakra-ui/react";
import DatePicker from "react-datepicker";

export const SearchDatePicker = memo(
  ({
    id,
    tooltipLabel,
    selected,
    handleChange,
    maxDate,
    showTimeSelect,
    dateFormat,
  }) => {
    const handleChangeWrapper = (date) =>
      handleChange({
        target: { name: id, type: "date", value: date },
      });
    return (
      <FormControl id={id}>
        <FormLabel>
          {id}
          <Tooltip label={tooltipLabel}>
            <InfoOutlineIcon color="gray.800" ml={2} />
          </Tooltip>
        </FormLabel>
        <DatePicker
          dateFormat={dateFormat}
          isClearable
          onChange={handleChangeWrapper}
          selected={selected}
          maxDate={maxDate}
          showTimeSelect={showTimeSelect}
          timeFormat="HH:mm"
          timeCaption="Time"
        />
      </FormControl>
    );
  }
);
