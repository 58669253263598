import React from "react";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import { clearLogs } from "../../features/LogConsole/logConsoleSlice";
import { KibanaLink } from "../KibanaLink/KibanaLink";
import { ChevronRightIcon, CloseIcon, RepeatIcon } from '@chakra-ui/icons'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerContent,
    DrawerOverlay,
    DrawerCloseButton,
    Button,
    useDisclosure,
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption
} from "@chakra-ui/react";


function LogConsole() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const logs = useSelector(({ logConsole }) => logConsole);
    const userIp = useSelector(({ login }) => login.ip);
    const btnRef = React.useRef();
    const dispatch = useDispatch();
    const livnUserIp = ["52.65.151.116", "54.79.14.50"];

    const handleClear = () => {
        dispatch(clearLogs());
    };

    return (
        <>
            <Button size={'xs'} ref={btnRef} onClick={onOpen} colorScheme='whiteAlpha' rightIcon={<ChevronRightIcon w={6} h={6} />}>
                Open Log Console
            </Button>
            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                finalFocusRef={btnRef}
                blockScrollOnMount={false}
                size={'xl'}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Log Console</DrawerHeader>
                    <DrawerBody>
                        <Box
                            bg="gray.100"
                            borderWidth="1px"
                            borderRadius="lg"
                            color="gray.800"
                            my={4}
                            p={6}
                        >
                            <Table variant={'stripped'} size={'sm'}>
                                <TableCaption>Last 100 request are displayed</TableCaption>
                                <Thead>
                                    <Tr>
                                        <Th width={'5%'}>Timestamp</Th>
                                        <Th width={livnUserIp.includes(userIp) ? '40%' : '45%'}>Description</Th>
                                        <Th width={'20%'}>URL</Th>
                                        <Th width={'30%'}>Process Id</Th>
                                        <Th width={'5%'}></Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {logs.map((item, i) => {
                                        return [
                                            <Tr key={i}>
                                                <Td>{DateTime.fromISO(item.timestamp).toLocaleString(DateTime.TIME_SIMPLE)}</Td>
                                                <Td>{item.description}</Td>
                                                <Td>{item.url}</Td>
                                                <Td>{item.processId}</Td>
                                                {livnUserIp.includes(userIp) ? <Td><KibanaLink processId={item.processId} timestamp={item.timestamp} /></Td> : null}
                                            </Tr>
                                        ];
                                    })}
                                </Tbody>
                            </Table>

                        </Box>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button leftIcon={<RepeatIcon />} variant='outline' mr={3} onClick={handleClear}>
                            Clear Console
                        </Button>
                        <Button leftIcon={<CloseIcon />} variant='outline' mr={3} onClick={onClose}>
                            Close Console
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    );
}
export default LogConsole;