import React from "react";

import { Box, GridItem, Spinner, Text } from "@chakra-ui/react";

function Loading({ colSpan = 12, text }) {
  return (
    <GridItem colSpan={colSpan} w="100%">
      <Box
        align="center"
        bg="blue.500"
        borderRadius="md"
        color="white"
        p={12}
        w="100%"
      >
        <Spinner size="xl" speed="0.5s" thickness="4px" />
        <Text>{text ? text : "Loading..."}</Text>
      </Box>
    </GridItem>
  );
}

export default Loading;
