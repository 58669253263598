import React from "react";

import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, Link, Text } from "@chakra-ui/react";

function ExternalResources({ externalResources }) {
  return (
    <Box
      bg="gray.100"
      borderWidth="1px"
      borderRadius="lg"
      color="gray.800"
      my={4}
      p={6}
    >
      <Heading as="h5" mb={4} size="md">
        External Resources
      </Heading>
      {externalResources.map((resource, index) => (
        <Box key={index}>
          {resource.url ? (
            <Flex align="flex-start" justify="space-between">
              <Text>url</Text>
              <Link color="blue.500" href={resource.url} isExternal>
                {resource.url} <ExternalLinkIcon mx="2px" />
              </Link>
            </Flex>
          ) : null}
          <Flex align="flex-start" justify="space-between">
            <Text>required</Text>
            <Text>{resource.required ? "true" : "false"}</Text>
          </Flex>
          <Flex align="flex-start" justify="space-between">
            <Text>printRequired</Text>
            <Text>{resource.printRequired ? "true" : "false"}</Text>
          </Flex>
        </Box>
      ))}
    </Box>
  );
}

export default ExternalResources;
