import React from "react";

import { Box, Heading } from "@chakra-ui/react";
import DetailsLayout from "./DetailsLayout";

function PickupDetails({ pickupDetails }) {
  const { notes } = pickupDetails;
  return (
    <Box mt={4}>
      <Heading as="h5" mb={4} size="md">
        Pick-up & Drop-off Details
      </Heading>
      <DetailsLayout details={{ ...pickupDetails, notes }} />
    </Box>
  );
}

export default PickupDetails;
