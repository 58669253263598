import React, { useState } from "react";

import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  FormControl,
  GridItem,
  IconButton,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";

import AddOn from "./Components/Fare/AddOn";
import BaseVariant from "./Components/Fare/BaseVariant";
import Fare from "./Components/Fare/Fare";
import TimeSlot from "./Components/Fare/TimeSlot";

function FareSelection({
  addOnSelections,
  checkoutFlow: { loading, step },
  fareSelections,
  handleAddOnChange,
  handleCopyFareJSON,
  handleFareChange,
  handleBackCheckoutFlow,
  handleFareSubmission,
  handlePreviewPrice,
}) {
  const [showUnavailable, setShowUnavailable] = useState(false);
  const handleInputChange = (event) => setShowUnavailable(event.target.checked);

  return !_.isEmpty(step.fareDetails) ? (
    <Box>
      <SimpleGrid alignItems="center" columns={5}>
        <GridItem colSpan={4}>
          <Alert mb={4} status="info">
            <AlertIcon />
            <AlertDescription>
              Fields such as fare or add-on without values are omitted by
              default. The same applies to unitsAvailable with a value of 0.
              <br />
              There is a basic check in place to hide unavailable items. Click
              the "show unavailable" checkbox to see all returned items.
            </AlertDescription>
          </Alert>
        </GridItem>
        <GridItem align="center">
          <FormControl id="showUnavailable">
            <Checkbox
              name="showUnavailable"
              mb={4}
              onChange={handleInputChange}
              value={showUnavailable}
            >
              Show unavailable
            </Checkbox>
          </FormControl>
        </GridItem>
      </SimpleGrid>

      <SimpleGrid columns={1} mb={4} spacing={2}>
        {!_.isEmpty(step.fareDetails.baseVariants) &&
          step.fareDetails.baseVariants.map((base, i) => {
            if (!base.available && !showUnavailable) {
              return null;
            }
            return (
              <Box columns={2} key={i} spacing={2}>
                <Box
                  bg="blue.500"
                  borderWidth="1px"
                  borderRadius="lg"
                  color="white"
                  p={6}
                  w="100%"
                >
                  {/* AddOns at the fare details level. */}
                  {!_.isEmpty(step.fareDetails.addOns) ? (
                    <Box maxH="500px" overflowY="auto" mt={2}>
                      {step.fareDetails.addOns.map((addOn, j) => {
                        const selected = _.find(addOnSelections, {
                          uuid: addOn.uuid,
                        });
                        return (
                          <Box
                            bg="gray.400"
                            borderWidth="1px"
                            borderRadius="lg"
                            color="gray.800"
                            key={addOn.uuid}
                            my={2}
                            p={2}
                          >
                            <AddOn addOn={addOn} />
                            <ButtonGroup
                              isAttached
                              mt={2}
                              variant="outline"
                              w="100%"
                            >
                              <IconButton
                                icon={<MinusIcon />}
                                isDisabled={
                                  !selected ||
                                  (selected && selected.quantity < 1)
                                }
                                onClick={() =>
                                  handleAddOnChange(addOn.uuid, -1)
                                }
                                size="sm"
                              />
                              <Button mr="-px" size="sm" w="100%">
                                {selected ? selected.quantity : "0"} selected
                              </Button>
                              <IconButton
                                icon={<AddIcon />}
                                onClick={() =>
                                  handleAddOnChange(addOn.uuid, +1)
                                }
                                size="sm"
                              />
                            </ButtonGroup>
                          </Box>
                        );
                      })}
                    </Box>
                  ) : null}

                  <BaseVariant base={base} />

                  <SimpleGrid
                    columns={
                      !_.isEmpty(base.timeSlots) && base.timeSlots.length > 1
                        ? 2
                        : 1
                    }
                    spacing={4}
                  >
                    {!_.isEmpty(base.timeSlots) &&
                      base.timeSlots.map(
                        (timeSlot, j) => {
                          if (!timeSlot.available && !showUnavailable) {
                            return null;
                          }
                          return (
                            <Box
                              bg="gray.100"
                              borderWidth="1px"
                              borderRadius="lg"
                              color="gray.800"
                              key={j}
                              my={4}
                              p={6}
                            >
                              <TimeSlot timeSlot={timeSlot} />
                              {/* AddOns at the timeslot level. */}
                              {!_.isEmpty(timeSlot.addOns) ? (
                                <SimpleGrid
                                  columns={2}
                                  spacingX={16}
                                  spacingY={4}
                                >
                                  {timeSlot.addOns.map((addOn) => {
                                    const selected = _.find(addOnSelections, {
                                      uuid: addOn.uuid,
                                    });
                                    return (
                                      <Box
                                        bg="gray.300"
                                        borderWidth="1px"
                                        borderRadius="lg"
                                        color="gray.800"
                                        key={addOn.uuid}
                                        my={2}
                                        p={2}
                                      >
                                        <AddOn addOn={addOn} />
                                        <ButtonGroup
                                          isAttached
                                          mt={2}
                                          variant="outline"
                                          w="100%"
                                        >
                                          <IconButton
                                            icon={<MinusIcon />}
                                            isDisabled={
                                              !selected ||
                                              (selected &&
                                                selected.quantity < 1)
                                            }
                                            onClick={() =>
                                              handleAddOnChange(addOn.uuid, -1)
                                            }
                                            size="sm"
                                          />
                                          <Button mr="-px" size="sm" w="100%">
                                            {selected ? selected.quantity : "0"}{" "}
                                            selected
                                          </Button>
                                          <IconButton
                                            icon={<AddIcon />}
                                            onClick={() =>
                                              handleAddOnChange(addOn.uuid, +1)
                                            }
                                            size="sm"
                                          />
                                        </ButtonGroup>
                                      </Box>
                                    );
                                  })}
                                </SimpleGrid>
                              ) : null}
                              <Divider my={2} />
                              <SimpleGrid
                                columns={2}
                                spacingX={16}
                                spacingY={4}
                              >
                                {!_.isEmpty(timeSlot.fares) ? (
                                  timeSlot.fares.map((fare) => {
                                    const selected = _.find(fareSelections, {
                                      uuid: fare.uuid,
                                    });
                                    const hasQuantity =
                                      selected && selected.quantity > 0;
                                    return (
                                      <Box key={fare.uuid} my={1}>
                                        <Box>
                                          <Fare fare={fare} />
                                        </Box>
                                        <ButtonGroup
                                          isAttached
                                          mt={2}
                                          variant="outline"
                                          w="100%"
                                        >
                                          <IconButton
                                            bg={
                                              hasQuantity ? "blue.500" : "white"
                                            }
                                            borderColor={
                                              hasQuantity ? "white" : "gray.600"
                                            }
                                            color={
                                              hasQuantity ? "white" : "gray"
                                            }
                                            icon={<MinusIcon />}
                                            isDisabled={
                                              !selected ||
                                              (selected &&
                                                selected.quantity < 1)
                                            }
                                            mr="-px"
                                            onClick={() =>
                                              handleFareChange(fare.uuid, -1)
                                            }
                                          />
                                          <Button
                                            bg={
                                              hasQuantity ? "blue.500" : "white"
                                            }
                                            borderColor={
                                              hasQuantity ? "white" : "gray.600"
                                            }
                                            color={
                                              hasQuantity ? "white" : "gray"
                                            }
                                            mr="-px"
                                            w="100%"
                                          >
                                            {selected ? selected.quantity : "0"}{" "}
                                            selected
                                          </Button>
                                          <IconButton
                                            bg={
                                              hasQuantity ? "blue.500" : "white"
                                            }
                                            borderColor={
                                              hasQuantity ? "white" : "gray.600"
                                            }
                                            color={
                                              hasQuantity ? "white" : "gray"
                                            }
                                            icon={<AddIcon />}
                                            onClick={() =>
                                              handleFareChange(fare.uuid, +1)
                                            }
                                          />
                                        </ButtonGroup>

                                        {/* AddOns at the fare level. */}
                                        {hasQuantity &&
                                          !_.isEmpty(fare.addOns) ? (
                                          <Box
                                            maxH="500px"
                                            overflowY="auto"
                                            mt={2}
                                          >
                                            {fare.addOns.map((addOn, j) => {
                                              const selected = _.find(
                                                addOnSelections,
                                                { uuid: addOn.uuid }
                                              );
                                              return (
                                                <Box
                                                  bg="gray.400"
                                                  borderWidth="1px"
                                                  borderRadius="lg"
                                                  color="gray.800"
                                                  key={addOn.uuid}
                                                  my={2}
                                                  p={2}
                                                >
                                                  <AddOn addOn={addOn} />
                                                  <ButtonGroup
                                                    isAttached
                                                    mt={2}
                                                    variant="outline"
                                                    w="100%"
                                                  >
                                                    <IconButton
                                                      icon={<MinusIcon />}
                                                      isDisabled={
                                                        !selected ||
                                                        (selected &&
                                                          selected.quantity < 1)
                                                      }
                                                      onClick={() =>
                                                        handleAddOnChange(
                                                          addOn.uuid,
                                                          -1
                                                        )
                                                      }
                                                      size="sm"
                                                    />
                                                    <Button
                                                      mr="-px"
                                                      size="sm"
                                                      w="100%"
                                                    >
                                                      {selected
                                                        ? selected.quantity
                                                        : "0"}{" "}
                                                      selected
                                                    </Button>
                                                    <IconButton
                                                      icon={<AddIcon />}
                                                      onClick={() =>
                                                        handleAddOnChange(
                                                          addOn.uuid,
                                                          +1
                                                        )
                                                      }
                                                      size="sm"
                                                    />
                                                  </ButtonGroup>
                                                </Box>
                                              );
                                            })}
                                          </Box>
                                        ) : null}
                                      </Box>
                                    );
                                  })
                                ) : (
                                  <Text>The fares array is empty.</Text>
                                )}
                              </SimpleGrid>
                            </Box>
                          );
                        }
                      )}
                  </SimpleGrid>

                  {/* AddOns at the base variant level. */}
                  {!_.isEmpty(base.addOns) ? (
                    <SimpleGrid columns={2} spacing={4}>
                      {base.addOns.map((addOn, j) => {
                        const selected = _.find(addOnSelections, {
                          uuid: addOn.uuid,
                        });
                        return (
                          <Box
                            bg="gray.200"
                            borderWidth="1px"
                            borderRadius="lg"
                            color="gray.800"
                            key={addOn.uuid}
                            my={4}
                            p={6}
                          >
                            <AddOn addOn={addOn} />
                            <ButtonGroup
                              isAttached
                              mt={2}
                              variant="outline"
                              w="100%"
                            >
                              <IconButton
                                icon={<MinusIcon />}
                                isDisabled={
                                  !selected ||
                                  (selected && selected.quantity < 1)
                                }
                                onClick={() =>
                                  handleAddOnChange(addOn.uuid, -1)
                                }
                              />
                              <Button mr="-px" w="100%">
                                {selected ? selected.quantity : "0"} selected
                              </Button>
                              <IconButton
                                icon={<AddIcon />}
                                onClick={() =>
                                  handleAddOnChange(addOn.uuid, +1)
                                }
                              />
                            </ButtonGroup>
                          </Box>
                        );
                      })}
                    </SimpleGrid>
                  ) : null}
                </Box>
              </Box>
            );
          })}
      </SimpleGrid>

      {_.isEmpty(step.questions) ? (
        <SimpleGrid columns={step.previousStepSequenceNumber ? 4 : 3} spacing={2}>
          {step.previousStepSequenceNumber ? (
            <Button
              isLoading={loading === "pending"}
              loadingText="Going back"
              onClick={() => handleBackCheckoutFlow()}
              variant="outline"
              w="100%"
            >
              Back
            </Button>
          ) : null}
          <Button
            colorScheme="green"
            isLoading={loading === "pending"}
            loadingText="Posting"
            onClick={() => handlePreviewPrice()}
            w="100%"
          >
            Preview price
          </Button>
          <Button
            onClick={() => handleCopyFareJSON()}
            variant="outline"
            w="100%"
          >
            Copy request payload
          </Button>
          <Button
            colorScheme="green"
            isLoading={loading === "pending"}
            loadingText="Posting"
            onClick={() => handleFareSubmission()}
            w="100%"
          >
            Submit fare selections
          </Button>
        </SimpleGrid>
      ) : null}
    </Box>
  ) : null;
}

export default FareSelection;
