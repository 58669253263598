import { useState, useRef } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  Button,
  Center,
  Heading,
  Input,
  FormLabel,
  FormControl,
  useToast,
  VStack,
  Textarea,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";

import { postLogin } from "./loginSlice";

import { transition } from "../../configs/pageMotions";
export const divVariants = {
  exit: { y: "50%", opacity: 0, transition },
  enter: { y: "0%", opacity: 1, transition },
};

function Login({ login }) {
  const toast = useToast();
  const formElementRef = useRef();
  const [isFormValid, setIsFormValid] = useState(false);
  const [form, setForm] = useState({
    username: "",
    password: "",
    clientId: "",
    url: "",
    customHeaders: "",
  });
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    name === "url" &&
    !value.trim().includes("https://") &&
    !value.trim().includes("http://")
      ? event.target.setCustomValidity(
          "Please include https:// or http:// in the Satellite URL.",
        )
      : event.target.setCustomValidity("");

    setForm({
      ...form,
      [name]: value,
    });
    setIsFormValid(formElementRef.current.checkValidity());
  };

  const dispatch = useDispatch();
  const handleLogin = () => {
    if (formElementRef.current.reportValidity())
      dispatch(postLogin(form, toast));
  };

  return (
    <AnimatePresence exitBeforeEnter>
      <Center bg="blue.700" h="100vh">
        <motion.div initial="exit" animate="enter" exit="exit">
          <motion.div variants={divVariants}>
            <Box
              bg="white"
              boxShadow="dark-lg"
              p={16}
              rounded="lg"
              w="lg"
              overflow="hidden"
            >
              <Heading
                align="center"
                as="h1"
                color="blue.500"
                mb={8}
                size="3xl"
              >
                Alice
              </Heading>
              <form ref={formElementRef}>
                <VStack align="stretch" spacing={4} w="100%">
                  <FormControl id="username" isRequired>
                    <FormLabel>API Username</FormLabel>
                    <Input
                      name="username"
                      onChange={handleInputChange}
                      value={form.username}
                      w="100%"
                      required
                    />
                  </FormControl>
                  <FormControl id="password" isRequired>
                    <FormLabel>API Password</FormLabel>
                    <Input
                      name="password"
                      onChange={handleInputChange}
                      type="password"
                      value={form.password}
                      w="100%"
                      required
                    />
                  </FormControl>
                  <FormControl id="clientId">
                    <FormLabel>Client Id</FormLabel>
                    <Input
                      name="clientId"
                      onChange={handleInputChange}
                      value={form.clientId}
                      w="100%"
                    />
                  </FormControl>
                  <Accordion allowToggle>
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            Custom HTTP headers
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <FormControl id="headers">
                          <Textarea
                            name="customHeaders"
                            onChange={handleInputChange}
                            value={form.customHeaders}
                            w="100%"
                            placeholder="header1Name:header1Value&#10;header2Name:header2Value"
                          />
                        </FormControl>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                  <FormControl id="url" isRequired>
                    <FormLabel>Satellite URL</FormLabel>
                    <Input
                      name="url"
                      onChange={handleInputChange}
                      value={form.url}
                      w="100%"
                      placeholder="https://server.com or http://server.com"
                      required
                    />
                  </FormControl>
                  <Button
                    colorScheme="green"
                    isLoading={login.loading === "pending"}
                    loadingText="Logging in"
                    onClick={() => handleLogin()}
                    w="100%"
                    style={{ opacity: isFormValid ? 1 : 0.5 }}
                  >
                    Login
                  </Button>
                </VStack>
              </form>
            </Box>
          </motion.div>
        </motion.div>
      </Center>
    </AnimatePresence>
  );
}

export default Login;
