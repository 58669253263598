import { memo } from "react";
import { Link as RouterLink } from "react-router-dom";
import imagePlaceholder from "../../../../resources/no-image-search.png";

import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Link,
  SimpleGrid,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";

import Duration from "../../Components/Duration";
import SourceDetails from "../../Components/SourceDetails";

const ProductDetails = memo(({ product }) => {
  return (
    <Box border="1px" borderColor="gray.400" borderRadius="md" mb={4} p={6}>
      <Grid alignItems="top" gap={6} templateColumns="repeat(12, 1fr)">
        <GridItem colSpan={4}>
          {!_.isEmpty(product.images) ? (
            <Box
              backgroundImage={`url(${product.images[0].url})`}
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
              borderRadius="md"
              h="100%"
              w="100%"
            />
          ) : <Box
            backgroundImage={imagePlaceholder}
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            
            borderRadius="md"
            h="100%"
            w="100%"
          />
          }
        </GridItem>
        <GridItem colSpan={6}>
          <VStack
            alignItems="inherit"
            h="100%"
            justifyContent="space-between"
            w="100%"
          >
            <Box>
              <Flex alignItems="flex-end">
                <Tooltip label={product.id ? product.id : ""}>
                  <Heading as="h4" fontWeight="semibold" mr={4} size="md">
                    {product.name}
                  </Heading>
                </Tooltip>
              </Flex>
              <Text color="gray.800" fontSize="md" mt={4} noOfLines={7}>
                {product.description}
              </Text>
            </Box>
            <Flex alignItems="flex-end" justify="space-between">
              {product.duration ? (
                <Duration
                  duration={product.duration}
                  durationStr={product.durationStr}
                  durationRangeMax={product.durationRangeMax}
                />
              ) : null}
              <Box>
                <Link
                  _hover={{
                    textDecoration: "none",
                  }}
                  as={RouterLink}
                  to={`/product/details/${product.id}`}
                >
                  <Box
                    _hover={{
                      bg: "blue.600",
                    }}
                    bg="blue.500"
                    borderRadius="md"
                    color="white"
                    fontSize="sm"
                    px={4}
                    py={2}
                  >
                    More details
                  </Box>
                </Link>
              </Box>
            </Flex>
          </VStack>
        </GridItem>
        <GridItem colSpan={2}>
          <SimpleGrid gap={2}>
            <SourceDetails details={product} />
          </SimpleGrid>
        </GridItem>
      </Grid>
    </Box>
  );
});

export default ProductDetails;
