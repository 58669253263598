import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  GridItem,
  Grid,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

import { supplierSearchClear } from "../supplierSlice";

import { divVariants } from "../../../configs/pageMotions";

import Loading from "../../../components/Loading/Loading";
import SearchForm from "./SearchForm";
import SearchResults from "./SearchResults";
import { useSelector } from "../../../app/store";
import SearchJSON from "./Components/SearchJSON";

const initialFormState: {
  resultsPerPage: number;
  currentPage: number;
  countries: string[];
  countriesOfOperation: string[];
  supplierIds: string;
  includeDisabled: boolean;

} = {
  resultsPerPage: 100,
  currentPage: 1,
  countries: [],
  supplierIds: "",
  countriesOfOperation: [],
  includeDisabled: false
};

function Search() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(supplierSearchClear());
  }, [dispatch]);

  const supplier = useSelector(({ supplier }) => supplier);

  const [searchForm, setSearchForm] = useState(initialFormState);

  const handleSearchInputChange = useCallback((event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setSearchForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleClearSearchForm = () => setSearchForm(initialFormState);

  return (
    <motion.div initial="exit" animate="enter" exit="exit">
      <Grid
        templateColumns="repeat(6, 1fr)"
        gap={12}
        minW={{ xl: "1200px" }}
        w="100%"
      >
        <GridItem colSpan={6}>
          <motion.div variants={divVariants}>
            <Heading as="h3" mb={4} size="lg">
              Supplier Search
            </Heading>
            <Tabs isFitted variant="enclosed-colored">
              <TabList mb="1em">
                <Tab>Search Form</Tab>
                <Tab>JSON</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <SearchForm
                    form={searchForm}
                    handleClearSearchForm={handleClearSearchForm}
                    handleInputChange={handleSearchInputChange}
                    loading={supplier.loading}
                  />
                </TabPanel>
                <TabPanel>
                  <SearchJSON form={searchForm} loading={supplier.loading} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </motion.div>
        </GridItem>

        {supplier.loading === "searching" ? (
          <Loading colSpan={6} text="Searching..." />
        ) : null}

        {supplier.searchResults && supplier.loading !== "searching" && (
          <GridItem colSpan={6} w="100%">
            <motion.div variants={divVariants}>
              <SearchResults
                loading={supplier.loading}
                searchResults={supplier.searchResults}
              />
            </motion.div>
          </GridItem>
        )}
      </Grid>
    </motion.div>
  );
}

export default Search;
