import React from "react";

import {
  Box,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import _ from "lodash";

import JSONDisplay from "../JSONDisplay";
import Booking from "./Booking";

function BookingList({ bookings: { list, title } }) {
  return (
    <Box>
      {title ? (
        <Heading as="h3" mb={4} size="lg">
          {title}
        </Heading>
      ) : null}
      <Tabs isFitted variant="enclosed-colored">
        <TabList mb="1em">
          <Tab>Bookings</Tab>
          <Tab>JSON</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {!_.isEmpty(list)
              ? list.map((result) => (
                <Booking booking={result} key={result.id} />
              ))
              : null}
          </TabPanel>
          <TabPanel>
            <JSONDisplay jsonToDisplay={list} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default BookingList;
